<template src="./activitySettingsAndTemplates.html"></template>

<script>
import showStatus from "../../../NetworkManager/showStatus";
import NewFeedbackFormRepository from "../../../Repository/Feedbackform";
import MailFormat from "../../../Repository/MailFormat";
import activityQuestionTemplate from "../activityQuestionTemplate/activityQuestionTemplate.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "activitySettingsAndTemplates",
  components: {
    activityQuestionTemplate,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "|",
            "undo",
            "redo",
          ],
          shouldNotGroupWhenFull: true,
        },
      },
      editorData: "",
      isAvailable: false,
      headers: [
        {
          text: "Sr",
          align: "start",
          sortable: false,
          value: "srNo",
        },
        { text: "Template Type", value: "typeOfFeedback" },
        { text: "Stakeholder", value: "stakeHolders" },
        { text: "Date of Creation", value: "createdAt" },
        { text: "Questions added", value: "questionsAdded" },
        { text: "Actions", value: "actions" },
      ],
      goToTemplate: false,
      allTemplates: [],
      feedbackDeleteDialog: false,
      mailDialog: false,
      deletionObject: {},
      createdTemplates: [],
    };
  },
  async created() {
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.$store.commit("liveData/set_templateTabDependencies", {});
    this.NewFeedbackFormRepositoryInstance = new NewFeedbackFormRepository(
      this
    );
    this.MailFormatInstance = new MailFormat(this);
    this.getTemplates();
    this.getMailFormat();
  },
  methods: {
    goToQuestionTab() {
      this.$router.push({
        name: "activityQuestionTemplate",
        params: { createdTemplates: this.createdTemplates },
      });
    },
    goToAddQuestions() {
      this.$router.push({
        name: "activityQuestionTemplate",
        params: { createdTemplates: this.createdTemplates },
      });
    },
    goToTemplateCreation(item, currentTab) {
      if (currentTab === 2) {
        item.moveToquestionTab = true;
      } else {
        item.moveToquestionTab = false;
      }
      this.$store.commit("liveData/set_templateTabDependencies", item);
      this.goToTemplate = true;
      this.$router.push({
        name: "activityQuestionTemplate",
        params: {
          createdTemplates: [],
        },
      });
    },
    goToFeedbackDialog(item) {
      this.deletionObject = item;
      this.feedbackDeleteDialog = true;
    },
    async getTemplates() {
      try {
        this.createdTemplates = [];
        const objToPush = {
          instituteId: this.selectedInstitute,
        };
        this.allTemplates =
          await this.NewFeedbackFormRepositoryInstance.getTemplates(objToPush);
        this.allTemplates?.forEach((item1) => {
          let quesLength = 0;
          item1?.allQuestions?.map((item2) => {
            item2.question.map((item3) => {
              quesLength = item3.question.length;
            });
          });
          item1.questionsAdded =
            quesLength > 0 ? `${quesLength}Qns` : "Add Qns";
          this.createdTemplates.push(item1.typeOfFeedback);
        });
      } catch (e) {
        console.error(e);
      }
    },
    async deleteTemplate() {
      try {
        const objToPush = {
          templateId: this.deletionObject.templateId,
        };
        let deleteTemplate =
          await this.NewFeedbackFormRepositoryInstance.deleteTemplate(
            objToPush
          );
        if (deleteTemplate.status === 200) {
          await this.getTemplates();
          this.feedbackDeleteDialog = false;
          showStatus("Successfully Deleted.", 2000, "success", this);
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async goToAddMailTemplate() {
      this.mailDialog = false;
      const isIncluded = this.editorData.includes("&lt;FEEDBACK-URL&gt;");
      if (isIncluded) {
        if (this.isAvailable) {
          const data = await this.MailFormatInstance.updateFormat({
            instituteId: this.selectedInstitute,
            feedbackFormat: this.editorData,
          });
        } else {
          const data = await this.MailFormatInstance.createFormat({
            instituteId: this.selectedInstitute,
            feedbackFormat: this.editorData,
          });
        }
      }
    },
    async getMailFormat() {
      this.isAvailable = false;
      try {
        const data = await this.MailFormatInstance.getFormat({
          instituteId: this.selectedInstitute,
        });
        if (data?.length > 0) {
          this.editorData = data[0].feedbackFormat;
          this.isAvailable = true;
        } else {
          this.editorData = ` <html>
                    <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
                      <div style="max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 8px; background-color: #f9f9f9;">
                        <h3 style="color: #555;">Dear &lt;STUDENT-NAME&gt;,</h3>
                        <p>We would appreciate it if you could take a moment to share your feedback using the link below:</p>
                        <span style="font-weight:700">&lt;FEEDBACK-URL&gt;</span>
                     
                        <p>Your input is important to us and will help improve your learning experience. Please complete the form by 
                          <strong> &lt;END-DATE&gt;</strong>.</p>
                        <p>Thank you for your time and valuable insights!</p>
                        <p style="margin-top: 20px;">Best regards,</p>
                        <p><strong>Studium Tech Pvt. Ltd</strong></p>
                      </div>
                    </body>
                  </html>`;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style src="./activitySettingsAndTemplates.css"></style>
