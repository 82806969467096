<template>
  <div :style="parentStyle" class="icon-wrapper">
    <v-icon
      :color="color"
      :disabled="disabled"
      :ripple="ripple"
      :style="iconStyle"
      @click="onClick"
      class="base-icon"
    >
      {{ validIcon }}
    </v-icon>
  </div>
</template>
  
  <script>
import { theme } from "../../../../Constants/Utils/Theme";
import { icons, validateIcon } from "../../../../Constants/Utils/Icons";

export default {
  name: "BaseIcon",
  props: {
    icon: {
      type: String,
      required: true,
      default: icons.borderNoneVariant,
    },
    color: {
      type: String,
      default: theme.primary, // Default color
    },
    ripple: {
      type: Boolean,
      default: false, // Enable ripple effect by default
    },
    iconStyle: {
      type: Object,
      default: () => ({}), // Custom styles for v-icon
    },
    parentStyle: {
      type: Object,
      default: () => ({}), // Custom styles for the parent div
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validIcon: validateIcon(this.icon),
    };
  },
  watch: {
    icon(newIcon) {
      this.validIcon = validateIcon(newIcon);
    },
  },
  methods: {
    onClick(event) {
      this.$emit("click", event); // Emit click event to parent
    },
  },
};
</script>
  
  <style scoped>
.icon-wrapper {
  display: inline-block;
  /* Allow the wrapper to behave like inline element */
}

.base-icon {
  cursor: pointer;
}
</style>
  