<template src="./activityCreateFeedbackform.html"></template>

<script>
import basicDetails from "../activityBasicDetails/activityBasicDetails.vue";
import questionDetails from "../activityQuestions/activityQuestions.vue";
import scaleAndOptionsDetails from "../activityScaleAndOptions/activityScaleAndOptions.vue";
import activityGenerateLink from "../activityGenerateLink/activityGenerateLink.vue";
import activityResponses from "../activityResponses/activityResponses.vue";
import activityAnalysis from "../activityAnalysis/activityAnalysis.vue";
import activityQuestionScale from "../activityQuestionScale/activityQuestionScale.vue";
import SoloIcon from "../../../Components/styleGuide/Buttons/Icons/SoloIcon.vue";

export default {
  name: "activityCreateFeedback",
  components: {
    basicDetails,
    questionDetails,
    scaleAndOptionsDetails,
    activityGenerateLink,
    activityResponses,
    activityAnalysis,
    activityQuestionScale,
    SoloIcon,
  },
  props: ["props_selections", "props_selectedFeedback"],
  data() {
    return {
      currentTab: 0,
      mainTabNames: [
        "Basic Details",
        "Question & Scale",
        // "Scale",
        "Generate",
      ],
      visitedTabs: [true, false, false, false, false],
      feedbackName: null,
      feedbackFormat: null,
      conductionType: "",
      isFeedbackSaved: "",
      isTab: false,
      isNextClick: 0,
      courseList: [],
      courses: [],
      conduction: "",
      courseDialog: false,
      isNew: null,
      props_isScaleChangeForBasic: undefined,
      props_isScaleChange: undefined,
    };
  },
  async created() {
    this.feedbackSelections =
      this.$store.getters["liveData/get_feedbackTopSelections"];
    const typeOfFeedback =
      this.props_selections.selectedTypeOfFeedback ??
      this.props_selectedFeedback.formType;

    if (Object.keys(this.props_selectedFeedback).length > 0) {
      this.visitedTabs = [true, true, true, true, true, true];
    }
    if (typeOfFeedback === "Course Feedback") {
      this.courses = this.props_selectedFeedback.courses
        ? [...this.props_selectedFeedback.courses]
        : [...this.props_selections.course];

      this.courses.map((obj) => {
        this.courseList.push({
          subjectName: obj.subjectName,
          subjectId: obj.subjectId,
        });
      });
      this.isNew = this.props_selections.course !== undefined ? true : false;
    }

    this.conduction =
      this.props_selections?.conduction === "Finished" ||
      this.props_selections?.conduction === "Ongoing";
  },
  methods: {
    goToFeedbacks() {
      this.$router.push("/activityFeedbackFormRevamped");
    },
    handleSelectedFeedbackUpdate(value) {
      this.props_isScaleChange = value;
    },
    handleScaleChangeForBasic(value) {
      this.props_isScaleChangeForBasic = value;
    },
    updateFeedbackFormat(newFeedbackFormat) {
      this.feedbackFormat = newFeedbackFormat;
    },
    updateFeedbackName(newFeedbackName) {
      this.feedbackName = newFeedbackName;
    },
    handleParentUpdate(data) {
      this.conductionType = data.conductionType;
      this.isFeedbackSaved = data.isFeedbackSaved;
      if (data.isFeedbackSaved && data.status === "Published") {
        this.mainTabNames.push("Responses", "Analysis");
      }
    },
    async handleTabClick(index) {
      this.visitedTabs[index] = true; // Mark tab as visited
      this.currentTab = index;
      this.isTab = false;

      try {
        if (this.props_selectedFeedback.formType === "Course Feedback") {
          this.props_selectedFeedback["coursesWiseQuestions"] =
            this.props_selectedFeedback.courses;
          this.$store.commit(
            "liveData/set_createdFeedback",
            this.props_selectedFeedback
          );
          if (this.$refs.tab1) {
            await this.$refs.tab1.goToNextPage();
          }
          if (this.$refs.tab2) {
            await this.$refs.tab2.createQuestionArray();
            this.isTab = true;
          }
          this.isTab = true;
        } else {
          if (this.$refs.tab1) {
            await this.$refs.tab1.goToNextPage();
          }
          if (this.$refs.tab2) {
            await this.$refs.tab2.createQuestionArray();
            this.isTab = true;
            this.isNew = false;
          } else {
            this.isTab = false;
            this.isNew = false;
          }
        }
      } catch (error) {
        console.error("Error calling tab functions:", error);
      }
    },
    handleNextPage() {
      // Emit an event to the child component when "Next" is clicked
      this.$emit("nextPage");
    },
    deleteCourse(index) {
      this.courses.splice(index, 1);
      this.courseList = [];
      this.courses.map((obj) => {
        this.courseList.push({
          subjectName: obj.subjectName,
          subjectId: obj.subjectId,
        });
      });
    },
    handleNextButtonClick(currentTab) {
      this.currentTab = undefined;
      this.visitedTabs[currentTab + 1] = true; // Enable the next tab

      if (this.conductionType === "Backdated" && currentTab === 2) {
        currentTab += 2;
      } else {
        currentTab += 1;
      }
      this.currentTab = currentTab;
      this.isNextClick++;
    },
  },
};
</script>

<style src="./activityCreateFeedbackform.css" scoped></style>
