import { render, staticRenderFns } from "./activityQuestionScale.html?vue&type=template&id=046e3b97&scoped=true&external"
import script from "./activityQuestionScale.vue?vue&type=script&lang=js"
export * from "./activityQuestionScale.vue?vue&type=script&lang=js"
import style0 from "./activityQuestionScale.css?vue&type=style&index=0&id=046e3b97&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "046e3b97",
  null
  
)

export default component.exports