export const icons = {
  delete: "mdi-delete",
  deleteOutline: "mdi-delete-outline",
  plus: "mdi-plus",
  copy: "mdi-content-copy",
  check: "mdi-check",
  refresh: "mdi-refresh",
  bell: "mdi-bell",
  bellOutline: "mdi-bell-outline",
  bellOff: "mdi-bell-off",
  bellRingOutline: "mdi-bell-ring-outline",
  close: "mdi-close",
  account: "mdi-account",
  accountOutline: "mdi-account-outline",
  download: "mdi-download",
  chevronDown: "mdi-chevron-down",
  chevronRight: "mdi-chevron-right",
  chevronLeft: "mdi-chevron-left",
  chevronUp: "mdi-chevron-up",
  lockOpenOutline: "mdi-lock-open-outline",
  finance: "mdi-finance",
  magnify: "mdi-magnify",
  calendarMonthOutline: "mdi-calendar-month-outline",
  arrowRightThin: "mdi-arrow-right-thin",
  arrowRight: "mdi-arrow-right",
  arrowLeft: "arrow-left",
  arrowLeftThin: "arrow-left-thin",
  pencil: "mdi-pencil",
  pencilOutline: "mdi-pencil-outline",
  attachment: "mdi-paperclip",
  save: "mdi-content-save",
  fileOutline: "mdi-file-outline",
  file: "mdi-file",
  eye: "mdi-eye",
  eyeOutline: "mdi-eye-outline",
  openInNew: "mdi-open-in-new",
  borderNoneVariant: "mdi-border-none-variant", //Default for SoloIcon component
};

export const validateIcon = (icon) => {
  const path = "/Constants/Utils/Icons";
  const error = `${icon} is not a registered icon. Please register it here: ${path}.`;

  if(icon === ""){
    return
  }
  if (!icon) {
    console.error(error);
    return icons.borderNoneVariant;
  }

  // Determine if the icon is a material design icon (mdi) or a custom icon
  const iconValues = Object.values(icons);
  const iconKeys = Object.keys(icons);

  // Check if the icon is a valid "mdi" icon or a custom key from the icons object
  const isMdiIcon = icon.includes("mdi");
  const isValidMdiIcon = isMdiIcon && iconValues.includes(icon);
  const isValidCustomIcon = !isMdiIcon && iconKeys.includes(icon);

  if (isValidMdiIcon) {
    return icon;
  } else if (isValidCustomIcon) {
    return icons[icon];
  } else {
    console.error(error);
    return icons.borderNoneVariant; // Default fallback icon
  }
};
