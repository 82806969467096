import firebase from "firebase";
import { store } from "../../Store/index";
import Salutations from "../../Constants/Utils/Salutations";

var config = {
  apiKey: "AIzaSyBEv8iqVOBCyAEOUzqNYNPF6CfAf5GPXBg",
  authDomain: "finalinprospect.firebaseapp.com",
  databaseURL: "https://finalinprospect.firebaseio.com",
  projectId: "finalinprospect",
  storageBucket: "finalinprospect.appspot.com",
  messagingSenderId: "787468078530",
};

const auth = {
  context: null,
  messaging: null,

  init(context) {
    // let self = this
    this.context = context;

    firebase.initializeApp(config);

    firebase.auth().onAuthStateChanged((user) => {
      // const self = this
      if (user) {
        user.getIdToken().then((authToken) => {
          this.context.$store.dispatch("user/set_user", user);
          this.context.$store.dispatch("user/set_authToken", authToken);
          this.context.$router.push("/activitySetup");
        });
      }
    });
  },
  getFirebaseObject() {
    return firebase;
  },
  getPercentageOfAddedUsers(data) {
    firebase.database().ref("reportGenerationStatus/" + data.instituteId);
    const commentsRef = firebase
      .database()
      .ref("reportGenerationStatus/" + data.instituteId);
    commentsRef.on("child_changed", (data) => {
      const value = data.val();
      store.commit("liveData/set_percentage", {
        percentage: value.toFixed(0),
      });
    });
  },
  login() {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("Profile");
    provider.addScope("email");
    return firebase
      .auth()
      .signInWithPopup(provider)
      .catch(function (err) {
        console.log(err);
        alert(
          "Something went wrong while logging in using Gmail. Please try again."
        );
      });
  },
  loginWithMicrosoft() {
    var provider = new firebase.auth.OAuthProvider("microsoft.com");
    provider.addScope("profile");
    provider.addScope("email");
    return firebase
      .auth()
      .signInWithPopup(provider)
      .catch(function (err) {
        console.log(err);
        alert(
          "Something went wrong while logging in using microsoft. Please try again."
        );
      });
  },
  user() {
    return this.context ? firebase.auth().currentUser : null;
  },
  logout(context) {
    firebase
      .auth()
      .signOut()
      .then(function () {
        context.$router.push("/activityWelcome");
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  getAttendance(obj) {
    // status = 1   attendance marked,
    // status = 2   attendance is already marked
    // status = 3   not belong to that lecture
    // status = 4   token mismatched
    // status = 5   Look like proxy attempt (deviceId already added)
    const deviceIdArr = [];
    var commentsRef = firebase
      .database()
      .ref("qrCodeAttendance/" + obj.lectureId);
    commentsRef.on("child_added", (data) => {
      const tokenId = store.getters["liveData/get_tokenForQr"];
      if (tokenId === data.val().token) {
        const liveData = store.getters["liveData/get_studentAttendance"];
        const isAlreadyPresent = liveData.findIndex(
          (item) => item.uId === data.val().uId
        );
        if (isAlreadyPresent === -1) {
          const index1 = obj.applicableStudent.findIndex(
            (item) => item.uId === data.val().uId
          );
          const student = obj.applicableStudent.filter(
            (e) => e.uId === data.val().uId
          );
          if (student.length) {
            store.commit("liveData/set_studentAttendance", [
              ...liveData,
              obj.applicableStudent[index1],
            ]);
            const updatedObj = data.val();
            updatedObj["status"] = 1;
            updatedObj["fullName"] =
              student[0]?.firstName +
              " " +
              student[0]?.middleName +
              " " +
              student[0]?.lastName;
            const updates = {};
            updates["/qrCodeAttendance/" + obj.lectureId + "/" + data.key] =
              updatedObj;
            firebase.database().ref().update(updates);
          } else {
            // send mes your not belong to that lecture
            const updatedObj = data.val();
            updatedObj["status"] = 3;
            const updates = {};
            updates["/qrCodeAttendance/" + obj.lectureId + "/" + data.key] =
              updatedObj;
            firebase.database().ref().update(updates);
          }
        } else {
          // send mes your attendance is already marked
          const updatedObj = data.val();
          updatedObj["status"] = 2;
          const updates = {};
          updates["/qrCodeAttendance/" + obj.lectureId + "/" + data.key] =
            updatedObj;
          firebase.database().ref().update(updates);
        }
      } else {
        // token mismatched
        const updatedObj = data.val();
        updatedObj["status"] = 4;
        const updates = {};
        updates["/qrCodeAttendance/" + obj.lectureId + "/" + data.key] =
          updatedObj;
        firebase.database().ref().update(updates);
      }
    });
  },
  deleteDocumentFromFirebase(lectureId) {
    firebase
      .database()
      .ref("/qrCodeEventAttendance/" + lectureId)
      .remove();
  },
  async scriptForBackCollection() {
    const dbRef = firebase.database().ref("/qrCodeEventAttendance/");
    const obj = {};

    await dbRef
      .once("value")
      .then((snapshot) => {
        let count = 0;
        snapshot.forEach((childSnapshot) => {
          count++;
          const childKey = childSnapshot.key;
          // console.log('childSnapshot.val()', childSnapshot);

          const childData = childSnapshot.val();
          console.log("childKey", childKey, childData);
          obj[childKey] = Object.values(childData);
          // Do something with each document (childData) in the collection
        });
        console.log("count", count);
        // console.log('obj', obj);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
    return obj;
  },
  async createBackupDocumentForLec(objToPush) {
    let docRef = await firebase
      .database()
      .ref("EventAttendanceBackup/" + objToPush.eventId);
    await docRef.once("value", function (snapshot) {
      if (snapshot.exists()) {
        // Document exists, do nothing
        let existingData = snapshot.val() || []; // Initialize as an empty array if no data exists
        // Merge existing data with new data
        let mergedData = [...existingData, ...objToPush.allScanData];
        let newOne = [...new Set(mergedData)];
        // Set the merged data back to the same location
        docRef.set(newOne);
      } else {
        // Document doesn't exist, create it with the provided data
        docRef.set(objToPush.allScanData);
      }
    });
    firebase
      .database()
      .ref("/qrCodeEventAttendance/" + objToPush.eventId)
      .remove();
  },
  async portalNavigationSecurity(objToPush) {
    let docRef = await firebase
      .database()
      .ref("portalNavigationBackup/" + objToPush.adminUId);
    await docRef.once("value", function (snapshot) {
      if (snapshot.exists()) {
        // Document exists, do nothing
        let existingData = snapshot.val() || []; // Initialize as an empty array if no data exists
        // Merge existing data with new data
        let mergedData = { ...existingData, ...objToPush };
        // Set the merged data back to the same location
        docRef.set(mergedData);
      } else {
        // Document doesn't exist, create it with the provided data
        docRef.set(objToPush);
      }
    });
  },
  async portalNavigationSecurity(objToPush) {
    let docRef = await firebase
      .database()
      .ref("portalNavigationBackup/" + objToPush.adminUId);
    await docRef.once("value", function (snapshot) {
      if (snapshot.exists()) {
        // Document exists, do nothing
        let existingData = snapshot.val() || []; // Initialize as an empty array if no data exists
        // Merge existing data with new data
        let mergedData = { ...existingData, ...objToPush };
        // Set the merged data back to the same location
        docRef.set(mergedData);
      } else {
        // Document doesn't exist, create it with the provided data
        docRef.set(objToPush);
      }
    });
  },
  async addDataToBackupCollection(id) {
    let collectionRef = firebase.database().ref("qrCodeEventAttendance");
    collectionRef
      .orderByChild("eventId")
      .equalTo(id)
      .once("value", function (snapshot) {
        snapshot.forEach(function (childSnapshot) {
          // Get the key of the document
          // let key = childSnapshot.key;

          // Get the data of the document
          let data = childSnapshot.val();
          // Update the scanData array by adding the desired data
          let newData = {
            // Add your new data here
          };

          // Push the new data to scanData array
          data.scanData = data.scanData || []; // Ensure scanData is initialized
          data.scanData.push(newData);

          // Update the document with the new scanData
          collectionRef.child(key).update({ scanData: data.scanData });
        });
      });
  },
  async getEventAttendance(obj) {
    // status = 1   attendance marked,
    // status = 2   attendance is already marked
    // status = 3   not belong to that lecture
    // status = 4   token mismatched
    // status = 5   Look like proxy attempt (deviceId already added)
    const deviceIdArr = [];
    var commentsRef = firebase
      .database()
      .ref("qrCodeEventAttendance/" + obj.eventId);
    let flagForData = true;
    let countOfPrevDocument = [];
    await commentsRef.once(
      "value",
      function (snapshot) {
        if (snapshot.exists()) {
          // Data exists
          const data = snapshot.val();
          countOfPrevDocument = Object.values(data);

          flagForData = false;
        } else {
          // Data does not exist
          flagForData = true;
        }
      },
      function (error) {
        console.error("Error fetching data:", error);
      }
    );
    let count = 0;
    let uniqueArr = [];

    commentsRef.on("child_added", (data) => {
      // console.log('uniqueArr', uniqueArr);
      if (!uniqueArr.includes(data.val().token + "_" + data.val().uId)) {
        uniqueArr.push(data.val().token + "_" + data.val().uId);
        const tokenId = store.getters["liveData/get_tokenForQr"];
        count++;
        if (flagForData) {
          if (
            !deviceIdArr.includes(data.val().deviceId) ||
            deviceIdArr.length === 0
          ) {
            if (
              obj.eventId === data.val().eventId &&
              tokenId === data.val().token
            ) {
              const liveData = store.getters["liveData/get_studentAttendance"];
              let liveData1 = store.getters["liveData/get_presentStudentArr"];
              if (!liveData1.includes(data.val().uId)) {
                if (obj.applicableInstitutes.includes(data.val().instituteId)) {
                  //attendance Mark
                  store.commit("liveData/set_studentAttendance", [
                    ...liveData,
                    {
                      uId: data.val().uId,
                      fullName: data.val().fullName,
                      instituteId: data.val().instituteId,
                      instituteName: data.val().instituteName,
                      semName: data.val().semName,
                      department: data.val().department,
                      courseYear: data.val().courseYear,
                      division: data.val().division,
                      collegePRNNo: data.val().collegePRNNo,
                    },
                  ]);
                  const updatedObj = data.val();
                  updatedObj["status"] = 1;
                  deviceIdArr.push(data.val().deviceId);
                  const updates = {};
                  updates[
                    "/qrCodeEventAttendance/" + obj.eventId + "/" + data.key
                  ] = updatedObj;
                  firebase.database().ref().update(updates);
                  liveData1.push(data.val().uId);
                  store.commit("liveData/set_presentStudentArr", liveData1);
                } else {
                  // send mes your not belong to that lecture
                  const updatedObj = data.val();
                  updatedObj["status"] = 3;
                  const updates = {};
                  updates[
                    "/qrCodeEventAttendance/" + obj.eventId + "/" + data.key
                  ] = updatedObj;
                  firebase.database().ref().update(updates);
                }
              } else {
                // send mes your attendance is already marked
                const updatedObj = data.val();

                updatedObj["status"] = 2;
                deviceIdArr.push(data.val().deviceId);
                const updates = {};
                updates[
                  "/qrCodeEventAttendance/" + obj.eventId + "/" + data.key
                ] = updatedObj;
                firebase.database().ref().update(updates);
              }
            } else {
              // token mismatched
              const updatedObj = data.val();
              updatedObj["status"] = 4;
              const updates = {};
              updates[
                "/qrCodeEventAttendance/" + obj.eventId + "/" + data.key
              ] = updatedObj;
              firebase.database().ref().update(updates);
            }
          } else {
            const updatedObj = data.val();
            updatedObj["status"] = 5;
            const updates = {};
            const liveData1 = store.getters["liveData/get_presentStudentArr"];
            if (!liveData1.includes(data.val().uId)) {
              updatedObj["status"] = 5;
            } else {
              updatedObj["status"] = 2;
            }
            updates["/qrCodeEventAttendance/" + obj.eventId + "/" + data.key] =
              updatedObj;
            firebase.database().ref().update(updates);
          }
        } else if (count > countOfPrevDocument.length) {
          if (
            !deviceIdArr.includes(data.val().deviceId) ||
            deviceIdArr.length === 0
          ) {
            const liveData2 = store.getters["liveData/get_presentStudentArr"];
            if (
              obj.eventId === data.val().eventId &&
              tokenId === data.val().token
            ) {
              const liveData = store.getters["liveData/get_studentAttendance"];
              if (
                !liveData2.includes(data.val().uId) ||
                liveData2.length === 0
              ) {
                if (obj.applicableInstitutes.includes(data.val().instituteId)) {
                  //attendance Mark
                  store.commit("liveData/set_studentAttendance", [
                    ...liveData,
                    {
                      uId: data.val().uId,
                      fullName: data.val().fullName,
                      instituteId: data.val().instituteId,
                      instituteName: data.val().instituteName,
                      semName: data.val().semName,
                      department: data.val().department,
                      courseYear: data.val().courseYear,
                      division: data.val().division,
                      collegePRNNo: data.val().collegePRNNo,
                    },
                  ]);
                  liveData2.push(data.val().uId);
                  store.commit("liveData/set_presentStudentArr", liveData2);
                  const updatedObj = data.val();
                  updatedObj["status"] = 1;
                  deviceIdArr.push(data.val().deviceId);
                  const updates = {};
                  updates[
                    "/qrCodeEventAttendance/" + obj.eventId + "/" + data.key
                  ] = updatedObj;
                  firebase.database().ref().update(updates);
                } else {
                  // send mes your not belong to that lecture
                  const updatedObj = data.val();
                  updatedObj["status"] = 3;
                  const updates = {};
                  updates[
                    "/qrCodeEventAttendance/" + obj.eventId + "/" + data.key
                  ] = updatedObj;
                  firebase.database().ref().update(updates);
                }
              } else {
                // send mes your attendance is already marked
                const updatedObj = data.val();

                updatedObj["status"] = 2;
                // deviceIdArr.push(data.val().deviceId)

                const updates = {};
                updates[
                  "/qrCodeEventAttendance/" + obj.eventId + "/" + data.key
                ] = updatedObj;
                firebase.database().ref().update(updates);
              }
            } else {
              // token mismatched
              const updatedObj = data.val();
              updatedObj["status"] = 4;
              const updates = {};
              updates[
                "/qrCodeEventAttendance/" + obj.eventId + "/" + data.key
              ] = updatedObj;
              firebase.database().ref().update(updates);
            }
          } else {
            const updatedObj = data.val();
            updatedObj["status"] = 5;
            const updates = {};
            const liveData = store.getters["liveData/get_studentAttendance"];
            // const isAlreadyPresent = liveData.findIndex(item => item.uId === data.val().uId);
            if (!liveData.includes(data.val().uId)) {
              updatedObj["status"] = 5;
            } else {
              updatedObj["status"] = 2;
            }
            updates["/qrCodeEventAttendance/" + obj.eventId + "/" + data.key] =
              updatedObj;
            firebase.database().ref().update(updates);
          }
        }
      }

      // find proxy
      // const proxyLiveData = store.getters['liveData/get_proxyData']
      // const obj1 = {
      //   deviceId: data.val().deviceId,
      //   uIds: [data.val().uId]
      // }
      // if (proxyLiveData.length === 0) {
      //   proxyLiveData.push(obj1)
      // } else {
      //   let ifFound = false
      //   proxyLiveData.map((info) => {
      //     if (info.deviceId === data.val().deviceId) {
      //       ifFound = true
      //       if (!info.uIds.includes(data.val().uId)) {
      //         info.uIds.push(data.val().uId)
      //       }
      //     }
      //   })
      //   if (ifFound === false) {
      //     proxyLiveData.push(obj1)
      //   }
      // }
      // store.commit("liveData/set_proxyData", proxyLiveData)
    });
  },
  async getEmailBodyFromFirebase(emailIdNumber) {
    let emailBody = [];

    const emailRef = firebase
      .database()
      .ref("/customEmailBody/" + emailIdNumber);
    await emailRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          emailBody = snapshot.val();
        } else {
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
    return emailBody;
  },
  async getEventAttendanceFirebase(collectionName, eventId) {
    let infoData = [];
    // Specify the ID you want to check

    // Reference the collection and the specific document
    const docRef = firebase.database().ref(`/${collectionName}/` + eventId);
    // Get the data for the specific document
    await docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          // The document with the specified ID exists
          infoData = snapshot.val();
        } else {
          // The document with the specified ID does not exist
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
    return infoData;
  },
  setLastUpdatedTime(obj) {
    var currentTimestamp = new Date().getTime();

    return new Promise((resolve, reject) => {
      var updatedTimeRef = firebase
        .database()
        .ref("instituteData/" + obj.instituteId);

      updatedTimeRef.once("value", function (snapshot) {
        var instituteData = snapshot.val() || {};

        // Create or get the array of timeSlots
        var timeSlotsArray = instituteData["timeSlots"] || [];

        // Check if there's an object with the same data as 'obj' in the timeSlotsArray
        var existingObjIndex = timeSlotsArray.findIndex(function (existingObj) {
          return (
            existingObj.semId === obj.semId &&
            existingObj.department === obj.department &&
            existingObj.courseYear === obj.courseYear &&
            existingObj.division === obj.division &&
            existingObj.isGroupedLecture === obj.isGroupedLecture
          );
        });

        if (existingObjIndex !== -1) {
          // Update the timestamp of the existing object
          timeSlotsArray[existingObjIndex].timestamp = currentTimestamp;
        } else {
          // Create a new object with timestamp
          var newObj = {
            timestamp: currentTimestamp,
            semId: obj.semId,
            department: obj.department,
            courseYear: obj.courseYear,
            division: obj.division,
            isGroupedLecture: obj.isGroupedLecture,
          };
          // Push the new object into the timeSlotsArray
          timeSlotsArray.push(newObj);
        }

        // Update the 'timeSlots' array in the instituteData
        instituteData["timeSlots"] = timeSlotsArray;

        updatedTimeRef.set(instituteData, function (error) {
          if (error) {
            console.error("Error updating data:", error);
            reject(error); // Reject the Promise in case of an error
          } else {
            var date = new Date(currentTimestamp);
            var hours = date.getHours();
            var minutes = ("0" + date.getMinutes()).slice(-2);
            var seconds = ("0" + date.getSeconds()).slice(-2);
            var ampm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12;
            hours = hours ? hours : 12; // Handle midnight (0:00) as 12 AM
            var formattedDate = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()} (${hours}:${minutes}:${seconds} ${ampm})`;
            resolve(formattedDate); // Resolve the Promise with the formatted date and time string
          }
        });
      });
    });
  },

  getLastUpdatedTime(obj) {
    return new Promise((resolve, reject) => {
      var updatedTimeRef = firebase
        .database()
        .ref("instituteData/" + obj.instituteId);

      updatedTimeRef
        .once("value")
        .then((snapshot) => {
          var instituteData = snapshot.val() || {};

          // Get the 'timeSlots' array from instituteData
          var timeSlotsArray = instituteData["timeSlots"] || [];

          var latestTimestamp = 0; // Initialize with a timestamp in the past

          // Iterate through timeSlotsArray to find the most recent timestamp
          timeSlotsArray.forEach(function (existingObj) {
            if (
              existingObj.semId === obj.semId &&
              existingObj.department === obj.department &&
              existingObj.courseYear === obj.courseYear &&
              existingObj.division === obj.division &&
              existingObj.isGroupedLecture === obj.isGroupedLecture &&
              existingObj.timestamp > latestTimestamp
            ) {
              latestTimestamp = existingObj.timestamp;
            }
          });

          if (latestTimestamp > 0) {
            var date = new Date(latestTimestamp);
            var hours = date.getHours();
            var minutes = ("0" + date.getMinutes()).slice(-2);
            var seconds = ("0" + date.getSeconds()).slice(-2);
            var ampm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12;
            hours = hours ? hours : 12; // Handle midnight (0:00) as 12 AM
            var formattedDate = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()} (${hours}:${minutes}:${seconds} ${ampm})`;
            resolve(formattedDate);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.error("Error fetching timestamp:", error);
          reject(error);
        });
    });
  },
  deleteDocumentFromFirebase(lectureId) {
    firebase
      .database()
      .ref("/qrCodeAttendance/" + lectureId)
      .remove();
  },
  // async findDocumentForQrAttendance(arr) {
  //   let infoData = []
  //   // Specify the ID you want to check
  //   let lectureIdToCheck = arr;

  //   // Reference the collection and the specific document
  //   let docRef = firebase.database().ref('/qrCodeAttendance/' + lectureIdToCheck);
  //   // Get the data for the specific document
  //   await docRef.once('value')
  //     .then(function (snapshot) {
  //       if (snapshot.exists()) {
  //         // The document with the specified ID exists
  //         infoData = snapshot.val();
  //       } else {
  //         // The document with the specified ID does not exist
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log("Error fetching document data:", error);
  //     });
  //   return infoData
  // },
  async findDocumentForQrAttendance(documentKey, lectureId) {
    let infoData = null;
    // Reference the specific document using the provided key
    let docRef = firebase.database().ref("/qrAttendanceBackup/" + documentKey);
    // Get the data for the specific document
    await docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          // The document with the specified key exists
          const documentData = snapshot.val();
          // Check if the provided lectureId exists in the array
          if (documentData.length > 0) {
            documentData.map((value) => {
              if (value.lectureId === lectureId) {
                infoData = value.allScanDetails;
              }
            });
          }
        } else {
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
    return infoData;
  },

  async getTempThresholdFromFirebase(instituteId) {
    let infoData = [];
    // Specify the ID you want to check

    // Reference the collection and the specific document
    let docRef = firebase.database().ref("/tempThreshold/" + instituteId);
    // Get the data for the specific document
    await docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          // The document with the specified ID exists
          infoData = snapshot.val();
        } else {
          // The document with the specified ID does not exist
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
    return infoData;
  },

  async getInstituteCustomizationFromFirebase(instituteId) {
    let infoData = [];
    // Specify the ID you want to check

    // Reference the collection and the specific document
    let docRef = firebase
      .database()
      .ref("/instituteCustomization/" + instituteId);
    // Get the data for the specific document
    await docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          // The document with the specified ID exists
          infoData = snapshot.val();
        } else {
          // The document with the specified ID does not exist
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
    return infoData;
  },

  async getNameTitlesFromFirebase() {
    let infoData = [];

    try {
      // Set a timeout for the Firebase request
      const fetchPromise = firebase.database().ref("/nameTitles").once("value");

      // Reject if it takes more than 5 seconds
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Firebase request timed out")), 5000)
      );

      const snapshot = await Promise.race([fetchPromise, timeoutPromise]);

      if (snapshot.exists()) {
        infoData = snapshot.val();
      } else {
        console.log("Document does not exist!");
      }
    } catch (error) {
      console.log("Error fetching document data:", error);
      // Use dummy data as fallback
      infoData = Salutations;
    }

    return infoData;
  },

  getSubjectTypeFromFirebase() {
    let infoData = [];
    let docRef = firebase.database().ref("/subjectTypes");
    docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          infoData = snapshot.val();
          console.log("snapshot.val called once", infoData);
          store.commit("liveData/set_subjectTypes", infoData);
        } else {
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
    docRef.on("child_changed", (snapshot) => {
      const updatedValue = snapshot.val();
      console.log("snapshot.val", updatedValue);
      store.commit("liveData/set_subjectTypes", updatedValue);
    });
  },
  setSubjectTypeToFirebase(data) {
    let dbRef = firebase.database().ref("/subjectTypes");
    dbRef
      .set(data)
      .then(() => {
        console.log("Data written successfully!");
      })
      .catch((error) => {
        console.error("Error writing data:", error);
      });
  },

  setFullNames(instituteId, data) {
    let dbRef = firebase
      .database()
      .ref(`/instituteCustomization/${instituteId}/studentFullNames`);
    dbRef
      .set(data)
      .then(() => {
        console.log("Data written successfully!");
      })
      .catch((error) => {
        console.error("Error writing data:", error);
      });
  },
  async getFullNames(instituteId) {
    let infoData = [];
    // Specify the ID you want to check

    // Reference the collection and the specific document
    let docRef = firebase
      .database()
      .ref(`/instituteCustomization/${instituteId}/studentFullNames`);
    // Get the data for the specific document
    await docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          // The document with the specified ID exists
          infoData = snapshot.val();
        } else {
          // The document with the specified ID does not exist
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.error("Error fetching document data:", error);
      });
    return infoData;
  },
};

export default auth;
