<template src="./activityTransferUsers.html"></template>

<script>
import inputContainer from "../../Components/inputContainer";
import SemesterRepository from "../../Repository/Semester";
import UserRepository from "../../Repository/User";
import InstituteUserRepository from "../../Repository/InstituteUser";
import SemesterUserRepository from "../../Repository/SemesterUser";
import arrayOps from "../../Services/Utils/arrayOps";
import ActivityLoggerRepository from "../../Repository/ActivityLogger";
import showStatus from "../../NetworkManager/showStatus";
import UserAuthenticationRepository from "../../Repository/UserAuthentication";

export default {
  name: "activityTransferUsers",
  components: {
    inputContainer,
  },
  props: [
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "prop_selectedDivision",
    "tree",
  ],
  data() {
    return {
      selectedToCourseYearForNew: [],
      studentsToTransfer: {},
      askForTransfer: false,
      loading: false,
      selectedSemesterName: "",
      transferring: true,
      stepOneCompleted: false,
      modes: [
        "Add New Users in Selected Semester",
        "Semester to semester Transfer",
        "Transfer Students Within the Same Semester",
      ],
      userTypes: ["Faculties", "Students"],
      selectedMode: "Add New Users in Selected Semester",
      selectedUserType: "",
      selectedFromSemester: {},
      selectedSemester: {},
      selectedInstitute: "",
      selectedFromSemesterName: "",
      fromDepartments: [],
      selectedFromDepartment: "",
      fromCourseYears: [],
      selectedFromCourseYear: "",
      fromDivisions: [],
      selectedFromDivision: "",
      toDepartments: [],
      selectedToDepartment: "",
      toCourseYears: [],
      selectedToCourseYear: "",
      toDivisions: [],
      selectedToDivision: "",
      semestersObjectsList: [],
      semestersNamesList: [],
      selectedSemUser: [],
      existingSemUser: [],
      selected: [],
      semesterNamesToObjectsMapping: {},
      academicDataTree1: {},
      academicDataTree2: {},
      selectedSemesterFrom: {},
      fromUsers: [],
      toUsers: [],
      showTableData: false,
      confirmAddition: false,
      askForRollNoPrnExamSeatNo: false,
      search: "",
      search2: "",
      usersToTransferred: [],
      ifStudentSelected: false,
      singleSelect: false,
      semestersObjectsFilteredList: [],
      checkCopy: true,
      flag: false,
      // allSelected: false,
      // someSelected: false,
      headers: [
        // {
        //   text: "Select",
        //   align: 'start',
        //   value: "checkbox",
        // },
        {
          text: "Srno",
          value: "srNo",
        },
        {
          text: "PRN",
          value: "collegePRNNo",
        },
        {
          text: "Roll NO",
          value: "rollNumber",
        },
        {
          text: "Name",
          value: "fullName",
        },
      ],
      headers2: [
        {
          text: "Srno",
          value: "srNo",
        },
        {
          text: "PRN",
          value: "collegePRNNo",
        },
        {
          text: "Roll NO",
          value: "rollNumber",
        },
        {
          text: "Name",
          value: "fullName",
        },
      ],
      headersForFaculties: [
        // {
        //   text: "Select",
        //   align: 'start',
        //   value: "checkbox",
        // },
        {
          text: "Srno",
          value: "srNo",
        },
        {
          text: "Faculty Id",
          value: "facultyId",
        },
        {
          text: "Name",
          value: "fullName",
        }
      ], 
      headersForFaculties2: [
        {
          text: "Srno",
          value: "srNo",
        },
        {
          text: "Faculty Id",
          value: "facultyId",
        },
        {
          text: "Name",
          value: "fullName",
        }
      ], 
    };
  },
  created() {
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester = this.$store.getters["liveData/get_editingSemester"];
    this.userData = this.$store.getters["user/get_userData"];
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.activityLoggerInstance = new ActivityLoggerRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.UserAuthenticationRepositoryInstance =
      new UserAuthenticationRepository(this);
    this.fetchData();
    this.fetchDataTo();
  },
  watch: {
    usersToTransferred: {
      handler(newValue) {
        this.fromUsers.forEach(user => {
          if(!user.isDisabled) {
            user.isSelected = newValue.some(selectedUser => selectedUser.uId === user.uId);
          }
        })
      },
      deep: true,
    },
  },
  computed: {
    computedHeaders1() {
      return this.selectedUserType === "Faculties"
        ? this.headersForFaculties2
        : this.headers2;
    },

    computedHeaders2() {
      return this.selectedUserType === "Faculties"
        ? this.headersForFaculties
        : this.headers;
    },

    someSelected() {
      return (
        this.fromUsers.some((user) =>
          this.usersToTransferred.some((selectedUser) => selectedUser.uId === user.uId)
        ) && !this.allSelected
      );
    },
  },
  methods: {
    copyOrNot(value) {
      if (value === 'No') {
        this.usersToTransferred = this.usersToTransferred.map(user => ({
          ...user,
          collegePRNNo: '',
          examSeatNumber: '',
          rollNumber: ''
        }));
        this.checkCopy = false
      } else this.checkCopy = true
      this.askForRollNoPrnExamSeatNo = false
      this.confirmAddition = true
    },

    addUsersInArr() {
      this.usersToTransferred = this.fromUsers.filter(user => user.isSelected)
    },

    filteredToDepartments() {
      return this.toDepartments.filter(
        (department) => department !== this.selectedFromDepartment
      );
    },

    filteredToCourseYears() {
      return this.toCourseYears.filter(
        (courseYear) => courseYear !== this.selectedFromCourseYear
      );
    },

    filteredToDivision() {
      return this.toDivisions.filter(
        (division) => division !== this.selectedFromDivision
      );
    },

    isUserSelected(item) {
      return this.usersToTransferred.some(user => user.uId === item.uId);
    },
    async checkSelectedFor() {
      this.clearAllData();
      this.clearTable();
      try {
        if (
          this.selectedMode === "Transfer Students Within the Same Semester"
        ) {
          this.selectedUserType = "Students";
          await this.transferStudentsWithin();
          this.ifStudentSelected = true;
        } else {
          this.ifStudentSelected = false;
        }
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },

    checkUser(item) {;
      if(this.usersToTransferred.includes(item.uId)) return true;
      else return false;
    },

    clearTable() {
      this.showTableData = false;
    },

    clearData() {
      this.clearAllData();
      this.clearTable();
      this.selectedFromDepartment = "";
      this.selectedToDepartment = "";
    },

    clearAllData() {
      this.selectedSemesterName = "";
      this.search = "";
      this.search2 = "";
      this.selectedSemesterFrom = {};
      this.selectedFromDepartment = "";
      this.fromDepartments = []
      this.selectedFromCourseYear = "";
      this.selectedFromDivision = "";
      this.selectedToCourseYear = "";
      this.selectedToDepartment = "";
      this.selectedToDivision = "";
      this.clearTable();
    },

    checkMandatoryFieldsSelected(){
      const toUserIds = new Set(this.toUsers.map(user => user.uId));
      this.fromUsers.forEach(user => {
        user.isDisabled = toUserIds.has(user.uId);
      });
      if (this.selectedUserType === 'Faculties') {
        if (this.selectedToDepartment) return false;
        else return true;
      } else if (this.selectedUserType === 'Students') {
        if (this.selectedToDivision) return false;
        else return true;
      }
    },

    toggleSelectAll() {
      if (this.allSelected) {
        this.usersToTransferred = this.usersToTransferred.filter(
          (selectedUser) =>
            !this.fromUsers.some((user) => user.uId === selectedUser.uId)
        );
      } else {
        const newSelections = this.fromUsers.filter(
          (user) =>
            !this.usersToTransferred.some((selectedUser) => selectedUser.uId === user.uId)
        );
        this.usersToTransferred = [...this.usersToTransferred, ...newSelections];
      }
    },

    handleUserSelection(item) {
      const index = this.usersToTransferred.findIndex(user => user.uId === item.uId);
      if (index === -1) {
        this.usersToTransferred = [...this.usersToTransferred, item];
      } else {
        this.usersToTransferred = this.usersToTransferred.filter(user => user.uId !== item.uId);
      }
    },

    // filterSemesters() {
    //   this.semestersObjectsList = this.semestersObjectsList.filter(
    //     semester => semester.semId !== this.selectedSemester.semId
    //   );
    // },

    async transferStudentsWithin() {
      try {
        this.loading = true;
        this.selectedFromDepartment = "";
        this.selectedFromCourseYear = "";
        this.fromCourseYears = [];
        this.selectedFromDivision = "";
        this.fromDivisions = [];
        const semObject = this.semestersObjectsList.find(
          (element) => element.semName === this.selectedSemester.semName
        );
        const obj = await this.getAcademicTreeForSemester(semObject?.semId);
        this.academicDataTree1 = obj?.academicDataTree;
        this.fromDepartments = obj?.departments;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },

    async fetchDataTo() {
      try {
        if (this.selectedUserType === "Faculties") {
          this.loading = true;
          this.selectedToDepartment = "";
          this.selectedToCourseYear = "";
          this.toCourseYears = [];
          this.selectedToDivision = "";
          this.toDivisions = [];
          const semObject = this.semestersObjectsList.find(
            (element) => element.semName === this.selectedSemester.semName
          );
          const obj = await this.getAcademicTreeForSemester(semObject?.semId);
          this.academicDataTree1 = obj?.academicDataTree;
          this.toDepartments = obj?.departments;
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },

    async fetchData() {
      this.selectedFromSemesterName = this.selectedSemester.semName;
      this.loading = true;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester?.semId,
        };
        this.semestersObjectsList =
          await this.semesterRepositoryInstance.getSemesterNames(objToPush);
        this.semestersObjectsList.reverse();
        this.semestersObjectsFilteredList = this.semestersObjectsList.filter(
          semester => semester.semId !== this.selectedSemester.semId
        );
        this.semestersNamesList = this.semestersObjectsList.map(
          (semester) => semester.semName
        );
        // this.semestersNamesList = arrayOps.removeFromArray(this.semestersNamesList, this.semestersNamesList.indexOf(this.selectedSemester.semName))
        this.selectedToDepartment = "";
        this.selectedToCourseYear = "";
        this.toCourseYears = [];
        this.selectedToDivision = "";
        this.toDivisions = [];
        const obj = await this.getAcademicTreeForSemester(
          this.selectedSemester?.semId
        );
        this.academicDataTree2 = obj?.academicDataTree;
        this.toDepartments = obj?.departments;
        this.getDepartments1();
        this.selectedSemUser = [];
        let data = await this.semesterUserRepositoryInstance.getSemesterUsers(
          objToPush
        );
        if (data?.length > 0) {
          this.selectedSemUser = data.map((value) => value.uId);
        }
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },

    async showTable() {
      try {
        this.loading = true;
        this.showTableData = false;
        if (this.selectedMode === "Add New Users in Selected Semester") {
          const uIds =
            await this.semesterUserRepositoryInstance.getUsersNotInAnySemester({
              instituteId: this.selectedInstitute,
            });
          const response = await this.userRepositoryInstance.getFullNameOfUsers(
            {
              uIds: uIds,
            }
          );

          const resForAuth = await this.UserAuthenticationRepositoryInstance.getUserAuthenticationsByUIds({
            uIds,
          });

          const responseWithRoles = response.map((responseObj) => {
            const authObj = resForAuth.find((authItem) => authItem.uId === responseObj.uId);
            return {
              ...responseObj,
              roles: authObj ? authObj.roles : [],
            };
          });

          this.fromUsers =
            this.selectedUserType === 'Faculties'
              ? responseWithRoles.filter((responseObj) => responseObj.roles.includes(1))
              : responseWithRoles.filter((responseObj) => responseObj.roles.includes(0));

          this.fromUsers = this.fromUsers.filter(
            (user) =>
              !this.selectedSemUser.some(
                (selectedUser) => selectedUser.uId === user.uId
              )
          );
        } 
        this.loading = false;
        this.showTableData = true;
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },

    getSemesterObjectOfSelectedSemester(semName) {
      const index = this.semestersNamesList.findIndex(
        (item) => item.semName === semName
      );
      return this.semestersObjectsList[index];
    },

    async getDepartments1() {
      try {
        this.clearTable();
        this.loading = true;
        this.selectedFromDepartment = "";
        this.selectedFromCourseYear = "";
        this.fromCourseYears = [];
        this.selectedFromDivision = "";
        this.fromDivisions = [];
        const semObject = this.semestersObjectsList.find(
          (element) => element.semName === this.selectedSemesterFrom.semName
        );
        const obj = await this.getAcademicTreeForSemester(semObject?.semId);
        this.academicDataTree1 = obj?.academicDataTree;
        this.fromDepartments = obj?.departments;
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async getCourseYears1() {
      this.clearTable();
      this.loading = true;
      this.selectedFromCourseYear = "";
      this.selectedFromDivision = "";
      this.selectedToDepartment = "";
      this.selectedToCourseYear = "";
      this.selectedToDivision = "";
      this.fromDivisions = [];
      this.fromCourseYears = Object.keys(
        this.academicDataTree1[this.selectedFromDepartment]
      );
      this.loading = false;
    },

    async getDivisions1() {
      this.clearTable();
      this.loading = true;
      this.selectedToDepartment = "";
      this.selectedToCourseYear = "";
      this.selectedToDivision = "";
      this.selectedFromDivision = "";
      this.fromDivisions =
        this.academicDataTree1[this.selectedFromDepartment][
          this.selectedFromCourseYear
        ];
      this.loading = false;
    },

    async getCourseYears2() {
      this.clearTable();
      this.loading = true;
      this.selectedToCourseYear = "";
      this.selectedToDivision = "";
      this.toDivisions = [];
      this.toCourseYears = Object.keys(
        this.academicDataTree2[this.selectedToDepartment]
      );
      this.loading = false;
    },

    async getDivisions2() {
      this.clearTable();
      this.loading = true;
      this.selectedToDivision = "";

      if (this.selectedToCourseYearForNew?.length > 0) {
        this.toDivisions =
          this.academicDataTree2[this.selectedToDepartment][
            this.selectedToCourseYearForNew[0]
          ];
      } else {
        this.toDivisions =
          this.academicDataTree2[this.selectedToDepartment][
            this.selectedToCourseYear
          ];
      }
      this.loading = false;
    },

    async fetchFaculties() {
      this.fromUsers = []
      try {
        this.clearTable();
        this.loading = true;
        const semObject = this.semestersObjectsList.find(
          (element) => element.semName === this.selectedSemesterFrom.semName
        );
        const facultyIds =
          await this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
            instituteId: this.selectedInstitute,
            semId: semObject?.semId,
            department: this.selectedFromDepartment,
          });
        const uIds = facultyIds.map((faculty) => {
          return faculty.uId;
        });
        const response = await this.userRepositoryInstance.getFullNameOfUsers({
          uIds: uIds,
        });
        this.fromUsers = response;
        this.fromUsers = this.fromUsers.filter(
          (user) =>
            !this.selectedSemUser.some(
              (selectedUser) => selectedUser.uId === user.uId
            )
        );
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error("Error fetching faculties", e);
      }
    },

    async fetchFaculties2() {
      this.toUsers = []
      try {
        this.clearTable();
        this.loading = true;
        const semObject = this.semestersObjectsList.find(
          (element) => element.semName === this.selectedSemester.semName
        );
        const facultyIds =
          await this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
            instituteId: this.selectedInstitute,
            semId: semObject?.semId,
            department: this.selectedToDepartment,
          });
        const uIds = facultyIds.map((faculty) => {
          return faculty.uId;
        });
        const response = await this.userRepositoryInstance.getFullNameOfUsers({
          uIds: uIds,
        });
        this.toUsers = response;
        this.toUsers = this.toUsers.filter(
          (user) =>
            !this.selectedSemUser.some(
              (selectedUser) => selectedUser.uId === user.uId
            )
        );
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error("Error fetching faculties", e);
      }
    },

    async validateSelections() {
      this.flag = false
      if (
        this.selectedFromDepartment === this.selectedToDepartment &&
        this.selectedFromCourseYear === this.selectedToCourseYear &&
        this.selectedFromDivision === this.selectedToDivision
      ) {
        this.flag = true;
        showStatus(`Cannot add student to the same department: ${this.selectedFromDepartment}, course year: ${this.selectedFromCourseYear}, division: ${this.selectedFromDivision}`, 2000, "error", this);
      } else {
        this.flag = false;
        await this.fetchStudents2(this.selectedSemester)
      }
    },

    async fetchStudents() {
      this.fromUsers = []
      try {
        this.clearTable();
        this.loading = true;
        let semObject = null;
        if (this.selectedMode !== "Transfer Students Within the Same Semester") {
          semObject = this.semestersObjectsList.find(
            (element) => element.semName === this.selectedSemesterFrom.semName
           );
        } else {
          semObject = this.semestersObjectsList.find(
            (element) => element.semName === this.selectedSemester.semName
          );
        }
        // Fetch student IDs and details
        const studentIds =
          await this.semesterUserRepositoryInstance.getStudentsOfADivision({
            instituteId: this.selectedInstitute,
            semId: semObject?.semId,
            department: this.selectedFromDepartment,
            courseYear: this.selectedFromCourseYear,
            division: this.selectedFromDivision,
          });
        const uIds = studentIds.map(student => student.uId);

        const response = await this.userRepositoryInstance.getFullNameOfUsers({
          uIds: uIds,
        });

        const enrichedResponse = response.map(user => {
          const studentDetails = studentIds.find(student => student.uId === user.uId);
          return {
            ...user,
            rollNumber: studentDetails?.rollNumber || null,
            examSeatNumber: studentDetails?.examSeatNumber || null,
          };
        });

        this.fromUsers = enrichedResponse;
        this.fromUsers = this.fromUsers.filter(
          (user) =>
            !this.selectedSemUser.some(
              (selectedUser) => selectedUser.uId === user.uId
            )
        );
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error("Error fetching students", e);
      }
    },

    async fetchStudents2(semester) {
      this.toUsers = []
      try {
        this.clearTable();
        this.loading = true;
        const semObject = this.semestersObjectsList.find(
          (element) => element.semName === semester.semName
        );
        // Fetch student IDs and details
        const studentIds =
          await this.semesterUserRepositoryInstance.getStudentsOfADivision({
            instituteId: this.selectedInstitute,
            semId: semObject?.semId,
            department: this.selectedToDepartment,
            courseYear: this.selectedToCourseYear,
            division: this.selectedToDivision,
          });
        const uIds = studentIds.map(student => student.uId);

        const response = await this.userRepositoryInstance.getFullNameOfUsers({
          uIds: uIds,
        });

        const enrichedResponse = response.map(user => {
          const studentDetails = studentIds.find(student => student.uId === user.uId);
          return {
            ...user,
            rollNumber: studentDetails?.rollNumber || null,
            examSeatNumber: studentDetails?.examSeatNumber || null,
          };
        });


        this.toUsers = enrichedResponse;
        this.toUsers = this.toUsers.filter(
          (user) =>
            !this.selectedSemUser.some(
              (selectedUser) => selectedUser.uId === user.uId
            )
        );
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error("Error fetching students", e);
      }
    },

    async getFullNameOfUser(userObject) {
      try {
        if (!userObject.name) {
          userObject.name = await this.userRepositoryInstance.getFullNameOfUser(
            { uId: userObject.uId }
          );
        }
      } catch (err) {
        console.log(err);
        userObject.name = "";
      }
      return userObject;
    },

    async getAcademicTreeForSemester(semId) {
      this.loading = true;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
        };
        if(semId) {
          objToPush.semId = semId;
          const semestersList =
            await this.semesterRepositoryInstance.getDivisionsOfASemester(
              objToPush
            );
          const academicDataTree = {};
          semestersList.forEach((sem) => {
            if (!academicDataTree[sem.department]) {
              academicDataTree[sem.department] = {};
            }
            if (!academicDataTree[sem.department][sem.courseYear]) {
              academicDataTree[sem.department][sem.courseYear] = [];
            }
            academicDataTree[sem.department][sem.courseYear].push(sem.division);
          });
          const departments = Object.keys(academicDataTree);
          return {
            academicDataTree: academicDataTree,
            departments: departments,
          };
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },

    async transferStudents(transferRollNoAndExamNo) {
      this.askForTransfer = false;
      if (transferRollNoAndExamNo) {
        // const index = this.semestersNamesList.indexOf(this.selectedFromSemesterName)
        // const semObject = this.semestersObjectsList[index]
        const semObject = this.semestersObjectsList.find(
          (element) => element.semName === this.selectedSemester.semName
        );
        this.studentsToTransfer.previousSemId = this.selectedSemesterFrom?.semId;
        this.studentsToTransfer.maintainRollNumbers = transferRollNoAndExamNo;
      }
      try {
        this.loading = true;
        let multipleUsers = [];

        await this.semesterUserRepositoryInstance.importStudentsOfADivision(
          this.studentsToTransfer
        );
        //action tracker
        let objToPush = {
          instituteId: this.selectedInstitute,
          from: `${this.selectedSemester.semName} | ${this.selectedFromDepartment} | ${this.selectedFromCourseYear} | ${this.selectedFromDivision}`,
          to: `${this.selectedSemester.semName} | ${this.selectedToDepartment} | ${this.selectedToCourseYear} | ${this.selectedToDivision}`,
          semId: this.selectedSemester?.semId,
          department: this.selectedToDepartment,
          selectMode: this.selectedMode,
          selectedUserType: this.selectedUserType,
          savedBy: this.userData.firstName,
          type: "semesterUser",
          semesterLogType: "save",
          actionType: "create",
        };
        multipleUsers = this.toUsers.map((user) => ({
          ...objToPush,
          userId: user.uId,
          userName: user.name,
        }));
        // await this.activityLoggerInstance.createLogsForMultipleUsers({
        //   multipleUsers,
        // });
        //end of action tracker
        this.transferring = false;
        this.loading = false;
        showStatus("Transfer successful", 1000, "success", this);
        if(this.selectedUserType === 'Faculties') {
          await this.fetchFaculties()
          await this.fetchFaculties2()
        } else if(this.selectedUserType === 'Students'){
          await this.fetchStudents()
          await this.fetchStudents2(this.selectedSemester)
        }
        this.showTable();
        // this.completeStepOne()
      } catch (err) {
        console.log(err);
        showStatus("Duplication Found while Transfering", 2000, "error", this);
        this.loading = false;
      }
    },

    async transferUsersIntoSemester() {
      this.transferring = true;
      if (this.selectedMode !== "Add New Users in Selected Semester") {
        if (this.selectedUserType === "Students") {
          if (
            this.selectedMode === "Transfer Students Within the Same Semester"
          ) {
            let fromData =
              this.selectedFromDepartment +
              this.selectedFromCourseYear +
              this.selectedFromDivision;
            let toData =
              this.selectedToDepartment +
              this.selectedToCourseYear +
              this.selectedToDivision;
            if (fromData !== toData) {
              let objToPush = {
                instituteId: this.selectedInstitute,
                semId: this.selectedSemester?.semId,
                uIds: this.usersToTransferred.map((user) => user.uId),
                department: this.selectedToDepartment,
              };
              if (this.selectedToDivision) {
                objToPush.division = this.selectedToDivision;
              }
              if (this.selectedToCourseYear) {
                objToPush.courseYear = this.selectedToCourseYear;
              }
              if (this.selectedFromDepartment) {
                objToPush.fromDepartment = this.selectedFromDepartment;
              }
              if (this.selectedFromCourseYear) {
                objToPush.fromCourseYear = this.selectedFromCourseYear;
              }
              if (this.selectedFromDivision) {
                objToPush.fromDivision = this.selectedFromDivision;
              }
              objToPush.rollNos = [];
              objToPush.examSeatNos = [];

              objToPush.uIds.forEach((id, index) => {
                const matchingUser = this.fromUsers.find(user => user.uId === id);
                if (matchingUser && this.checkCopy) {
                  objToPush.rollNos[index] = matchingUser.rollNumber || null;
                  objToPush.examSeatNos[index] =
                  matchingUser.examSeatNumber || null;
                } else {
                  objToPush.rollNos[index] = '';
                  objToPush.examSeatNos[index] = '';
                }
              });
              try {
                let res =
                  await this.semesterUserRepositoryInstance.createMultipleSemesterUsers(
                    objToPush
                  );
                this.transferring = false;
                showStatus("Transfer successful", 1000, "success", this);
                this.loading = true;
                if(this.selectedUserType === 'Faculties') {
                  await this.fetchFaculties()
                  await this.fetchFaculties2()
                } else if(this.selectedUserType === 'Students'){
                  await this.fetchStudents()
                  await this.fetchStudents2(this.selectedSemester)
                }
                this.showTable();
              } catch (error) {
                console.log("error", error);
              }
            }
          } else {
            const objToPush = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester?.semId,
              uIdsArray: this.usersToTransferred.map((user) => user.uId),
              department: this.selectedToDepartment,
            };
            if (this.selectedToDivision) {
              objToPush.division = this.selectedToDivision;
            }
            if (this.selectedToCourseYear) {
              objToPush.courseYear = this.selectedToCourseYear;
            }
            this.studentsToTransfer = objToPush;
            await this.transferStudents(this.checkCopy);
          }
        } else {
          const objToPush = {
            instituteId: this.selectedInstitute,
            fromSemId: this.selectedSemesterFrom?.semId,
            toSemId: this.selectedSemester?.semId,
            uIdsArray: this.usersToTransferred.map((user) => user.uId),
            fromDept: this.selectedFromDepartment,
            toDept: this.selectedToDepartment,
          };
          try {
            let multipleUsers = [];
            const importUser =
              await this.semesterUserRepositoryInstance.importFaculties(
                objToPush
              );
            //action tracker
            let objOfMultipleUsers = {
              instituteId: this.selectedInstitute,
              from: `New User`,
              to: `${this.selectedSemester.semName} | ${this.selectedToDepartment}`,
              semId: this.selectedSemester?.semId,
              department: this.selectedToDepartment,
              selectMode: this.selectedMode,
              selectedUserType: this.selectedUserType,
              savedBy: this.userData.firstName,
              type: "semesterUser",
              semesterLogType: "save",
              actionType: "create",
            };

            multipleUsers = this.usersToTransferred.map((user) => ({
              ...objOfMultipleUsers,
              userId: user.uId,
              userName: user.name,
            }));
            // await this.activityLoggerInstance.createLogsForMultipleUsers({
            //   multipleUsers,
            // });
            //end of action tracker
            this.transferring = false;
            showStatus("Transfer successful", 1000, "success", this);
            this.loading = true;
            if(this.selectedUserType === 'Faculties') {
              await this.fetchFaculties()
              await this.fetchFaculties2()
            } else if(this.selectedUserType === 'Students'){
              await this.fetchStudents()
              await this.fetchStudents2(this.selectedSemester)
            }
            this.showTable();
          } catch (err) {
            this.loading = false;
            showStatus("Duplication Found while Transfering", 2000, "error", this);
            // this.showTable();
            console.log(err);
          }
        }
        this.confirmAddition = false;
        this.usersToTransferred = [];
      } else {
        try {
          const userIds = this.usersToTransferred.map((user) => user.uId);

          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester?.semId,
            department: this.selectedToDepartment,
            uIds: userIds
          };

          if (
            this.selectedUserType === "Students"
          ) {
            // Add specific details for students
            objToPush.courseYear = this.selectedToCourseYear;
            objToPush.division = this.selectedToDivision;
            objToPush.subjects = [];

            await this.semesterUserRepositoryInstance.createMultipleSemesterUsers(
              objToPush
            );
          } else {
            await this.semesterUserRepositoryInstance.createMultipleSemesterUsers(
              objToPush
            );
          }
        } catch (err) {
          console.error("Error during user transfer:", err);
        }
        this.transferring = false;
        this.confirmAddition = false;
        this.usersToTransferred = [];
        showStatus("Transfer successful", 1000, "success", this);
        this.loading = true;
        if(this.selectedUserType === 'Faculties') {
              await this.fetchFaculties()
              await this.fetchFaculties2()
            } else if(this.selectedUserType === 'Students'){
              await this.fetchStudents()
              await this.fetchStudents2(this.selectedSemester)
            }
        this.showTable();
      }
    },
    gotoactivityEditSemesterUsers() {
      this.$router.push("/activitySemestersManagment");
    },
  },
};
</script>

<style src="./activityTransferUsers.css"></style>
