<template>
  <div>
    <!-- Render child components with the disabled state passed down -->
    <slot :disabled="computedDisabled" />
  </div>
</template>

<script>
export default {
  name: "DisableWrapper",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDisabled: this.disabled,
    };
  },
  computed: {
    computedDisabled() {
      return this.localDisabled;
    },
  },
  watch: {
    disabled(newVal) {
      this.localDisabled = newVal;
    },
  },
};
</script>
