<template src='./activityEditFacultyAllocation.html'></template>
<script>
import inputContainer from "../../Components/inputContainer";
import smallButton from "../../Components/smallButton";
import { subjectTypes } from "../../Constants/Utils/Statics";
import arrayOps from "../../Services/Utils/arrayOps";
import UserRepository from "../../Repository/User";
import SubjectRepository from "../../Repository/Subject";
import SemesterUserRepository from "../../Repository/SemesterUser";
import LecturesRepository from "../../Repository/Lecture";
import TimetableRepository from "../../Repository/Timetable";
import SemesterRepository from "../../Repository/Semester";
import ActivityLoggerRepository from "../../Repository/ActivityLogger";
import showStatus from "../../NetworkManager/showStatus";

export default {
  name: "activityEditFacultyAllocation",
  props: [
    "PropsSelectedSemester",
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "prop_selectedDivision",
    "tree",
  ],
  components: {
    inputContainer,
    smallButton,
  },
  data() {
    return {
      deleteFacultiesArray:[],
      flattenData: [],
      flattenData2: [],
      finalFlattenData: [],
      loading: false,
      finalDataToShow2: [],
      facultiesDummy2: [],
      searchAllocation: "",
      searchFaculty: "",
      addFacultyDialogOpen: false,
      selectedDepartment: "",
      selectedCourseYear: "",
      arrForDivisionAndBatchDetails: [],
      allFacultiesOfASem: [],
      departments: [],
      batchesName: [],
      courseYears: [],
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      faculties: [],
      finalObjectToPush: [],
      selectedSubjectDetails: {},
      selectedFacultyForDelete: {},
      finalDataToShow: [],
      divisionList: [],
      divisionListForSelection: [],
      selectedDivisions: [],
      facultyAllocation: [],
      selectedDivisions: [],
      dropdownArr: [],
      search: "",
      headers2: [
        {
          text: "Subject",
          value: "groupName",
        },
        {
          text: "Variant Name",
          value: "subjectName",
        },
        {
          text: "Subject Type",
          value: "subjectType",
        },
        {
          text: "Faculties Assigned",
          value: "faculties",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      facultiesDummy: [],
      finalObjectToPushForEdit: [],
      selectedFacultyUid: "",
      facultyDeleteFinalData: [],
      subjectTypes: subjectTypes,
      tempSubjectIndex: -1,
      tempFaculty: {},
      selectedFacultyForEdit: {},
      editFacultyObject: {},
      showFacultyListDialog: false,
      deleteAllocationDialog: false,
      informationShow: false,
      editFacultyDialog: false,
      selectedFacultyIndex: null,
      selectedFacultyIndexToEdit: null,
      showFacultyAllocationLogs: false,
      displayLogsArray: [],
      logHeaders: [
        { text: "Lecture Name", value: "subjectName" },
        { text: "Faculty Name", value: "facultyName" },
        { text: "Selected Batches", value: "selectedBatches" },
        { text: "Selected Divisions", value: "selectedDivision" },
        { text: "Action Type", value: "actionType" },
        { text: "Saved At", value: "savedAt" },
        { text: "Saved By", value: "savedBy" },
      ],
      subHeaders: [
        { text: "Subject Name", value: "subjectName" },
        { text: "Subject Code", value: "subjectCode" },
        { text: "Subject Type", value: "subjectType" },
        { text: "Name", value: "name" },
        { text: "Division", value: "division" },
        { text: "Batches", value: "batch" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      numPreviousLogs: 5,
      searchTable: "",
    };
  },
  created() {
    this.$store.commit(
      "liveData/set_selectedActivityName",
      "Faculty Allocation"
    );
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.userData = this.$store.getters["user/get_userData"];

    this.selectedSemester = this.PropsSelectedSemester;
    this.lecturesRepositoryInstance = new LecturesRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.timetableRepositoryInstance = new TimetableRepository(this);
    this.activityLoggerInstance = new ActivityLoggerRepository(this);

    if (this.tree) {
      this.departments = this.tree.map((child) => child.name);
    } else {
      this.departments = [];
    }

    if (
      this.prop_selectedDepartment &&
      this.prop_selectedCourseYear &&
      this.prop_selectedDivision
    ) {
      this.selectedDepartment = this.prop_selectedDepartment;
      this.selectDepartment();
      this.selectedCourseYear = this.prop_selectedCourseYear;
      // this.selectDivision()
      this.selectCourseYear();
    }
    this.fetchFaculties();
  },
  computed: {
    processedData() {
        return this.finalFlattenData.map(item => ({
            ...item,
            groupKey: `${item.subjectName} - ${item.subjectType}`
        }));
    }
  },
  methods: {
    searchFacultyAdd(i) {
      if (i !== "") {
        const searchTerm = i;
        const regex = new RegExp(searchTerm, "i"); // "i" for case-insensitive search
        let newArrAfterSearch = [];
        this.facultiesDummy2.map((fac) => {
          if (regex.test(fac.fullName)) {
            newArrAfterSearch.push(fac);
          }
        });
        this.facultiesDummy = [];

        this.facultiesDummy = newArrAfterSearch;
      } else {
        this.facultiesDummy = this.facultiesDummy2;
      }
      // return this.labels.filter(label => regex.test(label.name));
    },
    async getFacultyAllocationLogs() {
      if (this.selectedDepartment === "" || this.selectedCourseYear === "") {
        showStatus(
          "Please select Department and Course Year to continue",
          3000,
          "error",
          this
        );
        return;
      }
      const { logs } =
        await this.activityLoggerInstance.getFacultyAllocationLogs({
          instituteId: this.selectedInstitute,
          semId: this.PropsSelectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          limit: this.numPreviousLogs,
        });
      const tempArr = [];
      for (let i = 0; i < logs.length; i++) {
        tempArr.push({
          selectedBatches: logs[i].selectedBatches.join(", "),
          selectedDivision: logs[i].selectedDivision.join(", "),
          subjectName: logs[i].subjectName,
          facultyName: logs[i].facultyName,
          actionType: logs[i].actionType,
          savedAt: this.formatISODate(logs[i].savedAt),
          savedBy: logs[i].savedBy,
        });
      }
      this.displayLogsArray = tempArr;
      this.showFacultyAllocationLogs = true;
    },
    formatISODate(isoString) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const date = new Date(isoString);
      if (date == "Invalid Date") return "-";
      const formattedDate = date.toLocaleString("en-US", options);

      return formattedDate;
    },
    searchAllocationFaculty(i) {
      if (i !== "") {
        const searchTerm = i;
        const regex = new RegExp(searchTerm, "i"); // "i" for case-insensitive search
        let newArrAfterSearch = [];
        let newArrAfterSearch2 = [];
        if (this.finalDataToShow2.length > 0) {
          this.finalDataToShow2.map((fac) => {
            fac.isAddForSearch = false;
          });

          this.finalDataToShow2.map((fac) => {
            if (fac.facultiesDetails.length > 0) {
              fac.facultiesDetails.map((name) => {
                if (
                  (regex.test(name.fullName) || regex.test(fac.subjectName)) &&
                  fac.isAddForSearch === false
                ) {
                  newArrAfterSearch.push(fac);
                  fac.isAddForSearch = true;
                }
              });
            }
          });
        }
        this.finalDataToShow = [];
        this.flattenData.map((itm) => {
          if (
            regex.test(itm.name) ||
            regex.test(itm.subjectName) ||
            regex.test(itm.subjectType)
          ) {
            newArrAfterSearch2.push(itm);
          }
        });
        this.finalDataToShow = newArrAfterSearch;
        this.finalFlattenData = newArrAfterSearch2;
      } else {
        this.finalDataToShow = this.finalDataToShow2;
      }
      // return this.labels.filter(label => regex.test(label.name));
    },
    convertSubjectTypeToName(num) {
      let type;
      switch (num) {
        case 0: {
          type = "Theory & Compulsory";
          break;
        }
        case 1: {
          type = "Practical & Compulsory";
          break;
        }
        case 2: {
          type = "Tutorial & Compulsory";
          break;
        }
        case 3: {
          type = "Audit Course & Compulsory";
          break;
        }
        case 4: {
          type = "Theory & Elective";
          break;
        }
        case 5: {
          type = "Practical & Elective";
          break;
        }
        case 6: {
          type = "Tutorial & Elective";
          break;
        }
        case 7: {
          type = "Audit Course & Elective";
          break;
        }
        case 10: {
          type = "Theory";
          break;
        }
        case 11: {
          type = "Practical";
          break;
        }
        case 12: {
          type = "Tutorial";
          break;
        }
        case 13: {
          type = "Audit";
          break;
        }

        default:
          type = "Theory";

        // code block
      }
      return type;
    },
    async editFacultyAllocationChanges() {
      try {
        this.editFacultyDialog = false;
        this.loading = true;
        this.finalObjectToPushForEdit = [];
        let facultyUid =
          this.selectedFacultyForEdit.facultiesDetails[
            this.selectedFacultyIndexToEdit
          ].uId;
        let objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: facultyUid,
          subjectId: this.selectedFacultyForEdit.subjectId,
        };
        let allTimeSlots =
          await this.timetableRepositoryInstance.getTimetableSlotsOfAFacultyOfASubject(
            objToPush
          );
        this.editFacultyObject.selectedDivision.map((div, j) => {
          let flag = false;

          let arrDummyName = [];
          let arrDummyId = [];
          this.divisionListOfSem.map((info) => {
            if (
              info.department === this.selectedDepartment &&
              info.courseYear === this.selectedCourseYear &&
              info.division === div
            ) {
              if (info.batchesOfDivision.length > 0) {
                info.batchesOfDivision.map((batch) => {
                  arrDummyName.push(div + "_" + batch.batchName);
                  arrDummyId.push({
                    id: batch.batchId,
                    name: batch.batchName,
                  });
                });
              }
            }
          });
          if (this.editFacultyObject.selectedBatchName.length > 0) {
            this.editFacultyObject.selectedBatchName.map((batch) => {
              let [divName, BatchName] = batch.split("_");
              if (arrDummyName.includes(batch)) {
                flag = true;
                let obj = {};
                obj["division"] = div;
                obj["subjectId"] = this.selectedFacultyForEdit.subjectId;
                obj["uId"] =
                  this.selectedFacultyForEdit.facultiesDetails[
                    this.selectedFacultyIndexToEdit
                  ].uId;
                obj["batchName"] = "";
                obj["batchId"] = "";

                obj["batchName"] = BatchName;
                arrDummyId.map((id) => {
                  if (id.name === BatchName) {
                    obj["batchId"] = id.id;
                  }
                });

                this.finalObjectToPushForEdit.push(obj);
              }
            });
            if (flag === false) {
              let obj = {};
              obj["division"] = div;
              obj["subjectId"] = this.selectedFacultyForEdit.subjectId;
              obj["uId"] = facultyUid;
              obj["batchName"] = "";
              obj["batchId"] = "";
              this.finalObjectToPushForEdit.push(obj);
            }
          } else {
            let obj = {};
            obj["division"] = div;
            obj["subjectId"] = this.selectedFacultyForEdit.subjectId;
            obj["uId"] = facultyUid;
            obj["batchName"] = "";
            obj["batchId"] = "";
            this.finalObjectToPushForEdit.push(obj);
          }
        });

        const activityLoggerPayloadArr = [];

        // Activity Logger Logs
        activityLoggerPayloadArr.push({
          instituteId: this.selectedInstitute,
          semId: this.PropsSelectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          subjectId: this.selectedFacultyForEdit.subjectId,
          subjectName: this.selectedFacultyForEdit.subjectName,
          facultyUid:
            this.selectedFacultyForEdit.facultiesDetails[
              this.selectedFacultyIndexToEdit
            ].uId,
          facultyName:
            this.selectedFacultyForEdit.facultiesDetails[
              this.selectedFacultyIndexToEdit
            ].fullName,
          type: "facultyAllocation",
          selectedBatches: this.editFacultyObject.selectedBatchName,
          selectedDivision: this.editFacultyObject.selectedDivision,
          savedAt: new Date().toISOString(),
          savedBy: this.userData.firstName,
          actionType: "Update",
        });

        await this.activityLoggerInstance.createMultipleLogs({
          logsArray: activityLoggerPayloadArr,
        });

        let facultySemUserInfo = {};
        this.facultyAllocation.map((fac) => {
          if (this.selectedFacultyForEdit.subjectId === fac.subjectId) {
            if (fac.semesteruserData.length > 0) {
              fac.semesteruserData.map((semDetails) => {
                if (semDetails.uId === facultyUid) {
                  facultySemUserInfo = semDetails;
                }
              });
            }
          }
        });
        let filteredSubjectArray = [];
        let timeSlotToDelete = [];
        let timeSlotToCreate = [];
        if (facultySemUserInfo.subjects.length > 0) {
          filteredSubjectArray = facultySemUserInfo.subjects.filter(
            (sub) => sub.subjectId !== this.selectedFacultyForEdit.subjectId
          );
        }
        let dummyArrForSub = [];
        if (this.finalObjectToPushForEdit.length > 0) {
          this.finalObjectToPushForEdit.map((data) => {
            const objForTimetableSlot = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedDepartment,
              courseYear: this.selectedCourseYear,
              division: data.division,
              subjectId: data.subjectId,
              uId: facultyUid,
              day: "Monday",
              roomId: "101",
              startTime: "10_00",
              endTime: "11_00",
            };
            if (data.batchName && data.batchName !== "") {
              objForTimetableSlot.batch = data.batchName;
            }
            timeSlotToCreate.push(objForTimetableSlot);
            if (allTimeSlots.length > 0) {
              allTimeSlots.map((timeSlot) => {
                if (
                  timeSlot.courseYear === this.selectedCourseYear &&
                  timeSlot.day === "Monday" &&
                  timeSlot.roomId === "101" &&
                  timeSlot.startTime === "10_00"
                ) {
                  timeSlotToDelete.push(timeSlot);
                }
              });
            }
            dummyArrForSub.push({
              subjectId: data.subjectId,
              division: data.division,
              batchName: data.batchName,
              batchId: data.batchId,
            });
          });
        }
        let ArrForSub = [];
        ArrForSub = [...dummyArrForSub, ...filteredSubjectArray];
        let dataToSend = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          uId: facultyUid,
          subjects: ArrForSub,
        };

        let res = await this.semesterUserRepositoryInstance.updateSemesterUser({
          bulkDataArray: [dataToSend],
        });
        if (timeSlotToDelete.length > 0) {
          await this.timetableRepositoryInstance.deleteTimetableSlotsInBulk({
            timeTableSlotsArray: timeSlotToDelete,
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });
        }
        if (timeSlotToCreate.length > 0) {
          const createdSlots =
            await this.timetableRepositoryInstance.createSlotsInBulk({
              slotsToAdd: timeSlotToCreate,
            });
        }
        this.editFacultyDialog = false;
        await this.selectCourseYear();
        await this.fetchFaculties();
      } catch (error) {
        console.log("error", error);
        this.editFacultyDialog = false;
        await this.selectCourseYear();
      }
    },
    editBatchChange() {
      this.editFacultyObject.isChanged = true;
      let dummy = this.editFacultyObject;
      this.editFacultyObject = {};
      this.editFacultyObject = dummy;
    },
    divisionChangeForEditFaculty() {
      this.editFacultyObject.batchesNameArr = [];
      this.editFacultyObject.divisionNameForShow = [];

      if (this.editFacultyObject.selectedDivision.length > 0) {
        this.editFacultyObject.isChanged = true;

        // this.arrForDivisionAndBatchDetails = []
        let allBatchesName = [];
        this.divisionListOfSem.map((data) => {
          this.editFacultyObject.selectedDivision.map((div) => {
            if (
              data.department === this.selectedDepartment &&
              data.courseYear === this.selectedCourseYear &&
              data.division === div
            ) {
              this.editFacultyObject.divisionNameForShow.push(div);

              if (data.batchesOfDivision.length > 0) {
                let arrDummy = [];
                data.batchesOfDivision.map((batch) => {
                  arrDummy.push(div + "_" + batch.batchName);
                });
                allBatchesName = [...allBatchesName, ...arrDummy];
                this.editFacultyObject.batchesNameArr.push(arrDummy);
              }
            }
          });
        });
        this.editFacultyObject.selectedBatchName =
          this.editFacultyObject.selectedBatchName.filter((item) =>
            allBatchesName.includes(item)
          );
      } else {
        this.editFacultyObject.isChanged = false;

        this.editFacultyObject.selectedBatchName = [];
      }
      let dummy = this.editFacultyObject;
      this.editFacultyObject = {};
      this.editFacultyObject = dummy;
    },
    editFacultyAllocationDialog(data, j) {
      this.editFacultyDialog = true;
      this.selectedFacultyForEdit = data;
      this.selectedFacultyIndexToEdit = j;
      this.editFacultyObject = {};
      this.editFacultyObject.isChanged = false;
      this.editFacultyObject.selectedDivision = [];
      this.editFacultyObject.divisionNameForShow = [];
      this.editFacultyObject.selectedBatchName = [];
      this.editFacultyObject.batchesNameArr = [];
      this.selectedFacultyForEdit.facultiesDetails[j].divDetails.map((info) => {
        if (!this.editFacultyObject.selectedDivision.includes(info.division)) {
          this.editFacultyObject.selectedDivision.push(info.division);
          this.editFacultyObject.divisionNameForShow.push(info.division);
          this.divisionListOfSem.map((data) => {
            if (
              data.department === this.selectedDepartment &&
              data.courseYear === this.selectedCourseYear &&
              data.division === info.division
            ) {
              if (data.batchesOfDivision.length > 0) {
                let arrDummy = [];
                data.batchesOfDivision.map((batch) => {
                  arrDummy.push(data.division + "_" + batch.batchName);
                });

                this.editFacultyObject.batchesNameArr.push(arrDummy);
              }
            }
          });
        }
        let batchNameChange = [];
        let divNameForAdd = info.division;
        if (info.batch.length > 0) {
          info.batch.map((x) => {
            let name = divNameForAdd + "_" + x;
            batchNameChange.push(name);
          });
        }
        this.editFacultyObject.selectedBatchName = [
          ...this.editFacultyObject.selectedBatchName,
          ...batchNameChange,
        ];
      });
    },
    editFacultyAllocationDialog2(data) {
      let j = -1;
      let arr = this.finalDataToShow
        .map((itr, i) => {
          if (itr.subjectName == data.subjectName) {
            itr.facultiesDetails.forEach((it2, i) => {
              if (it2.fullName == data.name) {
                j = i;
              }
            });
            return itr;
          }
        })
        .filter((it) => it !== undefined);
      this.editFacultyDialog = true;
      this.selectedFacultyForEdit = arr[0];
      this.selectedFacultyIndexToEdit = j;
      this.editFacultyObject = {};
      this.editFacultyObject.isChanged = false;
      this.editFacultyObject.selectedDivision = [];
      this.editFacultyObject.divisionNameForShow = [];
      this.editFacultyObject.selectedBatchName = [];
      this.editFacultyObject.batchesNameArr = [];
      this.selectedFacultyForEdit.facultiesDetails[j].divDetails.map((info) => {
        if (!this.editFacultyObject.selectedDivision.includes(info.division)) {
          this.editFacultyObject.selectedDivision.push(info.division);
          this.editFacultyObject.divisionNameForShow.push(info.division);
          this.divisionListOfSem.map((data) => {
            if (
              data.department === this.selectedDepartment &&
              data.courseYear === this.selectedCourseYear &&
              data.division === info.division
            ) {
              if (data.batchesOfDivision.length > 0) {
                let arrDummy = [];
                data.batchesOfDivision.map((batch) => {
                  arrDummy.push(data.division + "_" + batch.batchName);
                });

                this.editFacultyObject.batchesNameArr.push(arrDummy);
              }
            }
          });
        }
        let batchNameChange = [];
        let divNameForAdd = info.division;
        if (info.batch.length > 0) {
          info.batch.map((x) => {
            let name = divNameForAdd + "_" + x;
            batchNameChange.push(name);
          });
        }
        this.editFacultyObject.selectedBatchName = [
          ...this.editFacultyObject.selectedBatchName,
          ...batchNameChange,
        ];
      });
    },
    async deleteSubjectAllocation() {
      this.loading = true;
      try {
        let objToPushForTimeTable = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.selectedFacultyUid,
          subjectId: this.selectedFacultyForDelete.subjectId,
        };
        let allTimeSlots =
          await this.timetableRepositoryInstance.getTimetableSlotsOfAFacultyOfASubject(
            objToPushForTimeTable
          );
        let timeSlotToDelete = [];
        if (allTimeSlots.length > 0) {
          allTimeSlots.map((timeSlot) => {
            if (
              timeSlot.courseYear === this.selectedCourseYear &&
              timeSlot.day === "Monday" &&
              timeSlot.roomId === "101" &&
              timeSlot.startTime === "10_00"
            ) {
              timeSlotToDelete.push(timeSlot);
            }
          });
        }
        if (timeSlotToDelete.length > 0) {
          await this.timetableRepositoryInstance.deleteTimetableSlotsInBulk({
            timeTableSlotsArray: timeSlotToDelete,
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });
        }
        let facultySemUserInfo = {};
        this.facultyAllocation.map((fac) => {
          if (this.selectedFacultyForDelete.subjectId === fac.subjectId) {
            if (fac.semesteruserData.length > 0) {
              fac.semesteruserData.map((semDetails) => {
                if (semDetails.uId === this.selectedFacultyUid) {
                  facultySemUserInfo = semDetails;
                }
              });
            }
          }
        });
        let filteredSubjectArray = [];
        if (facultySemUserInfo.subjects.length > 0) {
          filteredSubjectArray = facultySemUserInfo.subjects.filter(
            (sub) => sub.subjectId !== this.selectedFacultyForDelete.subjectId
          );
        }
        let dataToSend = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          uId: this.selectedFacultyUid,
          subjects: filteredSubjectArray,
        };

        let res = await this.semesterUserRepositoryInstance.updateSemesterUser({
          bulkDataArray: [dataToSend],
        });
        let faculties = this.selectedFacultyForDelete.faculties.filter(
          (data) => data !== this.selectedFacultyUid
        );
        let selectedFacultyForDeleteDetails =
          this.selectedFacultyForDelete.facultiesDetails.filter(
            (data) => data.uId === this.selectedFacultyUid
          );

        const activityLoggerPayloadArr = [];
        activityLoggerPayloadArr.push({
          instituteId: this.selectedInstitute,
          semId: this.PropsSelectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          subjectId: this.selectedFacultyForDelete.subjectId,
          subjectName: this.selectedFacultyForDelete.subjectName,
          facultyUid: this.selectedFacultyUid,
          facultyName: selectedFacultyForDeleteDetails[0].fullName,
          type: "facultyAllocation",
          selectedBatches: [],
          selectedDivision: [],
          savedAt: new Date().toISOString(),
          savedBy: this.userData.firstName,
          actionType: "Delete",
        });

        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          subjectId: this.selectedFacultyForDelete.subjectId,
          subjectCode: this.selectedFacultyForDelete.subjectCode,
          subjectType: this.selectedFacultyForDelete.subjectType,
          faculties: faculties,
        };
        await this.subjectRepositoryInstance.updateFacultiesTeachingASubject(
          objToPush
        );
        await this.selectCourseYear();

        await this.activityLoggerInstance.createMultipleLogs({
          logsArray: activityLoggerPayloadArr,
        });

        this.deleteAllocationDialog = false;
      } catch (error) {
        console.log(" error", error);
        await this.selectCourseYear();

        this.deleteAllocationDialog = false;
      }
    },
    deleteSubjectDialogOpen2(data) {
      let facultyIndex = -1;
      let selectedFaculty = null;

      // Find the matching subject and faculty
      this.finalDataToShow.forEach((subject) => {
          if (subject.subjectId === data.subjectId) {
              subject.facultiesDetails.forEach((faculty, index) => {
                  if (faculty.fullName === data.name) {
                      facultyIndex = index;
                  }
              });
              selectedFaculty = subject;
          }
      });
      if (!selectedFaculty || facultyIndex === -1) {
          console.error("Faculty not found!");
          return;
      }

      // Assign values
      this.selectedFacultyForDelete = selectedFaculty;
      this.selectedFacultyUid = selectedFaculty.facultiesDetails[facultyIndex]?.uId || null;
      this.facultyDeleteFinalData = [];
      this.deleteAllocationDialog = true;

    },
    async addSubjectDivisionAndBatch() {
      this.addFacultyDialogOpen = false;
      this.loading = true;
      let facultiesToPush = [];
      this.finalObjectToPush = [];
      const activityLoggerPayloadArr = [];

      this.facultiesDummy.map((data, i) => {
        let arrForTest = [];
        if (
          data.isChanged &&
          data.isChanged === true &&
          !this.selectedSubjectDetails.faculties.includes(data.uId)
        ) {
          data.finalDetailsInfo = [];
          facultiesToPush.push(data.uId);
          let arrForDivObject = [];

          // Activity Logger Logs
          activityLoggerPayloadArr.push({
            instituteId: this.selectedInstitute,
            semId: this.PropsSelectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.selectedSubjectDetails.subjectId,
            subjectName: this.selectedSubjectDetails.subjectName,
            facultyUid: data.uId,
            facultyName: data.fullName,
            type: "facultyAllocation",
            selectedBatches: data.selectedBatchName,
            selectedDivision: data.selectedDivision,
            savedAt: new Date().toISOString(),
            savedBy: this.userData.firstName,
            actionType: "Create",
          });

          data.selectedDivision.map((div, j) => {
            let obj = {};
            obj["division"] = div;
            obj["subjectId"] = this.selectedSubjectDetails.subjectId;
            obj["uId"] = data.uId;
            obj["batchNameForDivision"] = "";
            obj["batchId"] = "";

            let arrDummyName = [];
            let arrDummyId = [];
            this.divisionListOfSem.map((info) => {
              if (
                info.department === this.selectedDepartment &&
                info.courseYear === this.selectedCourseYear &&
                info.division === div
              ) {
                if (info.batchesOfDivision.length > 0) {
                  info.batchesOfDivision.map((batch) => {
                    arrDummyName.push(div + "_" + batch.batchName);
                    arrDummyId.push({
                      id: batch.batchId,
                      name: batch.batchName,
                    });
                  });
                }
              }
            });
            if (data.selectedBatchName.length > 0) {
              data.selectedBatchName.map((batch) => {
                let [divNameDummy, batchNameDummy] = batch.split("_");
                if (arrDummyName.includes(batch)) {
                  let obj = {};
                  obj["division"] = div;
                  obj["subjectId"] = this.selectedSubjectDetails.subjectId;
                  obj["uId"] = data.uId;
                  obj["batchNameForDivision"] = "";
                  obj["batchId"] = "";

                  obj["batchNameForDivision"] = batchNameDummy;
                  arrDummyId.map((id) => {
                    if (id.name === batchNameDummy) {
                      obj["batchId"] = id.id;
                    }
                  });

                  this.finalObjectToPush.push(obj);
                }
              });
            } else {
              let obj = {};
              obj["division"] = div;
              obj["subjectId"] = this.selectedSubjectDetails.subjectId;
              obj["uId"] = data.uId;
              obj["batchNameForDivision"] = "";
              obj["batchId"] = "";
              this.finalObjectToPush.push(obj);
            }
          });
        }
      });
      try {
        if (facultiesToPush.length > 0) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.selectedSubjectDetails.subjectId,
            subjectCode: this.selectedSubjectDetails.subjectCode,
            subjectType: this.selectedSubjectDetails.subjectType,
            faculties: [
              ...facultiesToPush,
              ...this.selectedSubjectDetails.faculties,
            ],
          };
          let res =
            await this.subjectRepositoryInstance.updateFacultiesTeachingASubject(
              objToPush
            );
        }

        //collect all timetable slot to bulk create
        let allTimetablesSlotToCreate = [];
        if (this.finalObjectToPush.length > 0) {
          this.finalObjectToPush.map(async (allInfo) => {
            const timeSlot = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedDepartment,
              courseYear: this.selectedCourseYear,
              division: allInfo.division,
              subjectId: allInfo.subjectId,
              batch: allInfo.batchNameForDivision,
              uId: allInfo.uId,
              day: "Monday",
              roomId: "101",
              startTime: "10_00",
              endTime: "11_00",
            };
            allTimetablesSlotToCreate.push(timeSlot);
          });
        }
        //array of object creation for semester users subject array update (bulk update)
        let finalSemUserData = [];
        this.facultiesDummy.map((data, i) => {
          let arrForTest = [];
          if (
            data.isChanged &&
            data.isChanged === true &&
            !this.selectedSubjectDetails.faculties.includes(data.uId)
          ) {
            let facultySemUserInfo = {};
            let count = 0;
            this.allFacultiesOfASem.map((fac) => {
              if (fac.uId === data.uId) {
                count++;
                facultySemUserInfo = fac;
              }
            });
            let objToPush = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedDepartment,
              uId: data.uId,
              subjects: [],
            };
            let dummyArrForSubjects = [];
            if (this.finalObjectToPush.length > 0) {
              this.finalObjectToPush.map((fac) => {
                if (fac.uId === data.uId) {
                  dummyArrForSubjects.push({
                    subjectId: fac.subjectId,
                    division: fac.division,
                    batchName: fac.batchNameForDivision,
                    batchId: fac.batchId,
                  });
                }
              });
            }
            let dummyArr = [];
            let filteredSubjectArray = [];
            if (facultySemUserInfo.subjects.length > 0) {
              filteredSubjectArray = facultySemUserInfo.subjects.filter(
                (sub) => sub.subjectId !== this.selectedSubjectDetails.subjectId
              );
            }
            dummyArr = [...filteredSubjectArray, ...dummyArrForSubjects];
            objToPush.subjects = dummyArr;
            finalSemUserData.push(objToPush);
          }
        });
        if (finalSemUserData.length > 0) {
          let res =
            await this.semesterUserRepositoryInstance.updateSemesterUser({
              bulkDataArray: finalSemUserData,
            });
        }

        if (allTimetablesSlotToCreate.length > 0) {
          const createdSlots =
            await this.timetableRepositoryInstance.createSlotsInBulk({
              slotsToAdd: allTimetablesSlotToCreate,
            });
        }
        await this.activityLoggerInstance.createMultipleLogs({
          logsArray: activityLoggerPayloadArr,
        });
        this.addFacultyDialogOpen = false;
        await this.fetchFaculties();
        await this.selectCourseYear();
      } catch (error) {
        console.log("error", error);
        this.addFacultyDialogOpen = false;
        await this.selectCourseYear();
      }
    },
    batchChangesForFaculty() {
      let dummy = this.facultiesDummy;
      this.facultiesDummy = [];
      this.facultiesDummy = dummy;
    },
    divisionChangeForFaculty(arr, i) {
      this.facultiesDummy[i].batchesNameArr = [];
      this.facultiesDummy[i].divisionNameForShow = [];

      if (arr.length > 0) {
        this.facultiesDummy[i].isChanged = true;

        this.arrForDivisionAndBatchDetails = [];
        let allBatchesName = [];
        this.divisionListOfSem.map((data) => {
          arr.map((div) => {
            if (
              data.department === this.selectedDepartment &&
              data.courseYear === this.selectedCourseYear &&
              data.division === div
            ) {
              this.facultiesDummy[i].divisionNameForShow.push(div);

              if (data.batchesOfDivision.length > 0) {
                let arrDummy = [];
                data.batchesOfDivision.map((batch) => {
                  arrDummy.push(div + "_" + batch.batchName);
                });
                allBatchesName = [...allBatchesName, ...arrDummy];
                this.facultiesDummy[i].batchesNameArr.push(arrDummy);
              }
            }
          });
        });
        this.facultiesDummy[i].selectedBatchName = this.facultiesDummy[
          i
        ].selectedBatchName.filter((item) => allBatchesName.includes(item));
      } else {
        this.facultiesDummy[i].isChanged = false;

        this.facultiesDummy[i].selectedBatchName = [];
      }
      let dummy = this.facultiesDummy;
      this.facultiesDummy = [];
      this.facultiesDummy = dummy;
    },
    divisionChange(i, j) {
      this.facultiesDummy[i].divisions[j].batch = [];
      this.facultiesDummy[i].divisions[j].batchesNames = [];
      if (this.divisionListOfSem.length > 0) {
        this.divisionListOfSem.map((data) => {
          if (
            data.department === this.selectedDepartment &&
            data.courseYear === this.selectedCourseYear &&
            data.division === this.facultiesDummy[i].divisions[i].division
          ) {
            if (data.batchesOfDivision.length > 0) {
              data.batchesOfDivision.map((batch) => {
                this.facultiesDummy[i].divisions[j].batchesNames.push(
                  batch.batchName
                );
              });
            }
          }
        });
      }
      let dummy = this.facultiesDummy;
      this.facultiesDummy = [];
      this.facultiesDummy = dummy;
    },
    addDivDropDown() {
      this.dropdownArr.push({
        division: "",
        batch: [],
      });
    },
    divisionSelected(i) {
      this.facultiesDummy[i].selectedBatchName = [];
      this.batchesName = [];
      if (this.divisionListOfSem.length > 0) {
        this.divisionListOfSem.map((data) => {
          if (
            data.department === this.selectedDepartment &&
            data.courseYear === this.selectedCourseYear &&
            data.division === this.facultiesDummy[i].selectedDivision[0]
          ) {
            if (data.batchesOfDivision.length > 0) {
              data.batchesOfDivision.map((batch) => {
                this.batchesName.push(batch.batchName);
              });
            }
          }
        });
      }
      let dummy = this.facultiesDummy;
      this.facultiesDummy = [];
      this.facultiesDummy = dummy;
    },
    batchSelected(i) {
      if (!this.facultiesDummy[i].divisions) {
        this.facultiesDummy[i].divisions = [];
      }
      this.facultiesDummy[i].divisions.push({
        division: this.facultiesDummy[i].selectedDivision,
        batch: this.facultiesDummy[i].selectedBatchName,
        batchesNames: this.batchesName,
      });
      this.facultiesDummy[i].selectedDivision = "";
      this.facultiesDummy[i].selectedBatchName = "";
      let dummy = this.facultiesDummy;
      this.facultiesDummy = [];
      this.facultiesDummy = dummy;
    },
    facultyCheckboxValueChange(data, value, i) {
      data.isChanged = false;
      data.selectedDivision = [];
      data.selectedBatchName = [];
      data.batchesNameArr = [];
      this.facultiesDummy[i].isAdded = value;
      let dummy = this.facultiesDummy;
      this.facultiesDummy = [];
      this.facultiesDummy = dummy;
    },
    facultySelection(data, i) {
      let dummyArr = [...this.facultiesDummy];

      this.facultiesDummy = [...dummyArr];
    },
    addFacultyToSubjectDialog(item) {
      this.searchFaculty = "";
      this.selectedSubjectDetails = item;
      this.addFacultyDialogOpen = true;
      this.facultiesDummy = [];
      this.faculties.map((data) => {
        data.isAdded = false;
        data.isChanged = false;
        data.isChecked = false;
        data.selectedDivision = [];
        data.selectedBatchName = [];
        data.batchesNameArr = [];
        if (this.selectedSubjectDetails.faculties) {
          if (!this.selectedSubjectDetails.faculties.includes(data.uId)) {
            this.facultiesDummy.push(data);
          }
        }
      });
      this.facultiesDummy2 = this.facultiesDummy;
    },
    addFacultyToSubjectDialog2(item) {
      let j = -1;
      let arr = this.finalDataToShow
        .map((itr, i) => {
          if (itr.subjectName == item.subjectName) {
            return itr;
          }
        })
        .filter((it) => it !== undefined);

      this.searchFaculty = "";
      arr.forEach(itr => {
          if(itr.subjectId === item.subjectId) {
            this.selectedSubjectDetails = itr
          }
        })
      // this.selectedSubjectDetails = arr[0];
      this.addFacultyDialogOpen = true;
      this.facultiesDummy = [];
      this.faculties.map((data) => {
        data.isAdded = false;
        data.isChanged = false;
        data.isChecked = false;
        data.selectedDivision = [];
        data.selectedBatchName = [];
        data.batchesNameArr = [];
        if (this.selectedSubjectDetails.faculties) {
          if (!this.selectedSubjectDetails.faculties.includes(data.uId)) {
            this.facultiesDummy.push(data);
          }
        }
      });
      this.facultiesDummy2 = this.facultiesDummy;
    },
    async gotoInfoPage(facultyId, subject) {
      this.tempFaculty.name = this.getNameOfFaculty(facultyId);
      this.tempFaculty.uId = facultyId;
      this.tempFaculty.subjectId = subject.subjectId;
      const objToPush = {
        instituteId: subject.instituteId,
        semId: subject.semId,
        uId: facultyId,
        subjectId: subject.subjectId,
      };
      await this.getDivisionsofFaculty(objToPush);
    },
    async getDivisionsofFaculty(objToPush) {
      try {
        this.tempFaculty.divisions = [];
        const slots =
          await this.timetableRepositoryInstance.getTimetableSlotsOfAFacultyOfASubject(
            objToPush
          );
        if (slots.length > 0) {
          slots.map((slot) => {
            this.divisionList.map((div) => {
              if (div.division === slot.division) {
                div.isAlreadyAlloted = true;
              }
            });
          });
        }
        this.informationShow = true;
      } catch (e) {
        this.tempFaculty.divisions = [];
        this.informationShow = true;
      }
    },
    divOnchange(index, value) {
      this.divisionList[index].isChanged = true;
      this.divisionList[index].isAlreadyAlloted = value;
    },
    async addOrDeletedTimeSlot() {
      let toInsert = [];
      let toDelete = [];
      this.divisionList.map((div) => {
        if (div.isChanged === true) {
          if (div.isAlreadyAlloted === true) {
            toInsert.push(div);
          } else {
            toDelete.push(div);
          }
        }
      });
      if (toDelete.length > 0) {
        toDelete.forEach(async (div) => {
          const timeSlot = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: div.division,
            subjectId: this.tempFaculty.subjectId,
            uId: this.tempFaculty.uId,
            day: "Monday",
            roomId: "101",
            startTime: "10_00",
            endTime: "11_00",
          };
          try {
            await this.timetableRepositoryInstance.deletedummyTimetableSlot(
              timeSlot
            );
          } catch (e) {
            console.log("e", e);
          }
          try {
            let objForSemUser = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              uId: this.tempFaculty.uId,
              subjectId: this.tempFaculty.subjectId,
              division: div.division,
            };
            await this.semesterUserRepositoryInstance.removeSubjectObjectFromSubjectsArray(
              objForSemUser
            );
          } catch (error) {
            console.log("error", error);
          }
        });
      }
      //slots to be created for assign faculty to subjects
      if (toInsert.length > 0) {
        toInsert.forEach(async (div) => {
          const timeSlot = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: div.division,
            subjectId: this.tempFaculty.subjectId,
            uId: this.tempFaculty.uId,
            day: "Monday",
            roomId: "101",
            startTime: "10_00",
            endTime: "11_00",
          };
          try {
            const res =
              await this.timetableRepositoryInstance.createDummyTimetableSlot(
                timeSlot
              );
          } catch (e) {
            console.log("e", e);
          }
          try {
            let objForSemUser = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              uId: this.tempFaculty.uId,
              object: {
                subjectId: this.tempFaculty.subjectId,
                division: div.division,
              },
            };
            let info =
              await this.semesterUserRepositoryInstance.pushSubjectObjectToSubjectsArray(
                objForSemUser
              );
          } catch (error) {
            console.log("error", error);
          }
        });
      }

      setTimeout(() => {
        this.divisionList.map((div) => {
          div.isAlreadyAlloted = false;
          div.isChanged = false;
        }, 500);
      });

      this.informationShow = false;
    },
    selectedDiv() {
      let timeSlot;
      let toInsert = [];
      let toDelete = [];
      this.divisionList.map((div) => {
        if (this.tempFaculty.divisions.includes(div)) {
          toInsert.push(div);
        } else {
          toDelete.push(div);
        }
      });
      //slots to be deleted for unassign faculty from subjects
      if (toDelete.length > 0) {
        this.divisionList.forEach(async (div) => {
          timeSlot = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: div,
            subjectId: this.tempFaculty.subjectId,
            uId: this.tempFaculty.uId,
            day: "Monday",
            roomId: "101",
            startTime: "10_00",
            endTime: "11_00",
          };
          try {
            await this.timetableRepositoryInstance.deletedummyTimetableSlot(
              timeSlot
            );
          } catch (e) {
            console.log("e", e);
          }
          try {
            let objForSemUser = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              uId: this.tempFaculty.uId,
              subjectId: this.tempFaculty.subjectId,
              division: div,
            };
            await this.semesterUserRepositoryInstance.removeSubjectObjectFromSubjectsArray(
              objForSemUser
            );
          } catch (error) {
            console.log("error", error);
          }
        });
      }
      //slots to be created for assign faculty to subjects
      if (toInsert.length > 0) {
        toInsert.forEach(async (div) => {
          timeSlot = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: div,
            subjectId: this.tempFaculty.subjectId,
            uId: this.tempFaculty.uId,
            day: "Monday",
            roomId: "101",
            startTime: "10_00",
            endTime: "11_00",
          };
          try {
            const res =
              await this.timetableRepositoryInstance.createDummyTimetableSlot(
                timeSlot
              );
          } catch (e) {
            console.log("e", e);
          }
          try {
            let objForSemUser = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              uId: this.tempFaculty.uId,
              object: {
                subjectId: this.tempFaculty.subjectId,
                division: div,
              },
            };
            let info =
              await this.semesterUserRepositoryInstance.pushSubjectObjectToSubjectsArray(
                objForSemUser
              );
          } catch (error) {
            console.log("error", error);
          }
        });
      }
      // this.informationShow = false
      this.loading = false;
    },
    selectDepartment() {
      this.courseYears = [];
      // this.divisions = []
      this.selectedCourseYear = "";
      // this.selectedDivision = ''
      const indexOfDepartment = this.departments.indexOf(
        this.selectedDepartment
      );
      this.courseYears = this.tree[indexOfDepartment].children.map(
        (child) => child.name
      );
    },
    async selectCourseYear() {
      this.loading = true;

      this.facultyAllocation = [];

      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
      };

      try {
        this.facultyAllocation =
          await this.subjectRepositoryInstance.getSubjectDetailsOfACourseYearWithFacultyData(
            objToPush
          );
        this.divisionListOfSem =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        this.divisionListOfSem.map((data) => {
          if (
            data.department === this.selectedDepartment &&
            data.courseYear === this.selectedCourseYear
          ) {
            this.divisionListForSelection.push(data.division);
          }
        });
        this.finalDataToShow = [];
        this.facultyAllocation.map((data) => {
          let fullName;
          let obj = {
            subjectName: data.subjectName,
            subjectCode: data.subjectCode,
            // subjectType: this.convertSubjectTypeToName(data.subjectType),
            subjectType: data.subjectType,
            faculties: data.faculties,
            subjectId: data.subjectId,
            facultiesDetails: [],
            selectedDivision: [],
          };
          if (data.semesteruserData.length > 0) {
            data.semesteruserData.map((user, i) => {
              if(data?.userDetailsData[i]) {
                let objForFaculty = {};
                let name = data.userDetailsData[i].firstName;
                let middle = data.userDetailsData[i].middleName;
                let lastName = data.userDetailsData[i].lastName;
                if (middle !== "") {
                  fullName = name + " " + middle + " " + lastName;
                } else {
                  fullName = name + " " + lastName;
                }
                objForFaculty.fullName = fullName;
                objForFaculty.uId = data.userDetailsData[i].uId;
                objForFaculty.divDetails = [];
                if (user.subjects.length > 0) {
                  user.subjects.map((sub, k) => {
                    let divBatchData = {
                      division: "",
                      batch: [],
                    };
                    if (data.subjectId === sub.subjectId) {
                      if (sub.division) {
                        divBatchData.division = sub.division;
                      }
                      if (sub.batchName) {
                        divBatchData.batch.push(sub.batchName);
                      }
                      objForFaculty.divDetails.push(divBatchData);
                    }
                  });
                  obj.facultiesDetails.push(objForFaculty);
                  // let temp = [];
                  // temp.push(this.objForFaculty);
                }
              }
            });
          }
          this.finalDataToShow.push(obj);
        });

        this.finalDataToShow.sort((a, b) => {
          const subjectNameA = a.subjectName.toUpperCase(); // Convert to uppercase for case-insensitive sorting
          const subjectNameB = b.subjectName.toUpperCase();

          if (subjectNameA < subjectNameB) {
            return -1;
          } else if (subjectNameA > subjectNameB) {
            return 1; // a should come after b
          } else {
            return 0; // a and b are equal
          }
        });
        this.finalDataToShow2 = this.finalDataToShow;

        // Now, this.finalDataToShow is sorted based on subjectName
        setTimeout(() => {
          this.loading = false;
        }, 600);
      } catch (err) {
        console.log(err);
        this.finalDataToShow = [];
        this.finalDataToShow2 = [];
        this.loading = false;
      }
      let flattenedData = [];
      this.finalDataToShow2.forEach((itr1, i) => {
        if (itr1.facultiesDetails.length > 0) {
          itr1.facultiesDetails.forEach((itr2) => {
            let arr = [];
            let arr2 = [];

            itr2.divDetails.forEach((itr3) => {
              if (itr3.division.length > 0) {
                if (arr.includes(itr3.division) == false) {
                  arr.push(" " + itr3.division + " ");
                }
              } else {
                arr.push("-");
              }
              if (itr3.batch && itr3.batch.length > 0) {
                arr2.push(" " + itr3.division + "-" + itr3.batch[0] + " ");
              } else if (arr2.length == 0) {
                arr2.push("-");
              }
            });
            let obj = {
              subjectName: itr1.subjectName,
              subjectCode: itr1.subjectCode,
              subjectType: itr1.subjectType,
              subjectType: this.convertSubjectTypeToName(itr1.subjectType),
              subjectId: itr1.subjectId,
              name: itr2.fullName,
              division: arr || "-",
              batch: arr2 || "-",
            };
            flattenedData.push(obj);
          });
        } else {
          let obj = {
            subjectName: itr1.subjectName,
            subjectCode: itr1.subjectCode,
            // subjectType:itr1.subjectType,
            subjectType: this.convertSubjectTypeToName(itr1.subjectType),
            subjectId: itr1.subjectId,
            name: "-",
            division: "-",
            batch: "-",
          };
          flattenedData.push(obj);
        }
      });
      this.flattenData = flattenedData;
      this.flattenData2 = this.flattenData;
      this.finalFlattenData = this.flattenData2;
      this.finalFlattenData.sort((a, b) => {
        if (a.subjectCode < b.subjectCode) {
          return -1;
        }
        if (a.subjectCode > b.subjectCode) {
          return 1;
        }
        return 0;
      });

      this.finalFlattenData = this.finalFlattenData.map((item, index) => {
    return { ...item, id: index };
});
    },
    async fetchDivision() {},

    async removeFaculty(facultyId, subject) {
      const indexOfFaculty = subject.faculties.indexOf(facultyId);
      if (indexOfFaculty > -1) {
        subject.faculties = arrayOps.removeFromArray(
          subject.faculties,
          indexOfFaculty
        );

        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: subject.subjectId,
            subjectCode: subject.subjectCode,
            subjectType: subject.subjectType,
            faculties: subject.faculties,
          };
          await this.subjectRepositoryInstance.updateFacultiesTeachingASubject(
            objToPush
          );
        } catch (err) {
          console.log(err);
          subject.faculties.push(facultyId);
          subject.faculties = [...new Set(subject.faculties)];
        }
      }
    },

    startAddingFaculties(item) {
      this.tempSubjectIndex = this.facultyAllocation.indexOf(item);
      this.showFacultyListDialog = true;
    },

    async addSubjectTeacher(item) {
      if (
        this.facultyAllocation[this.tempSubjectIndex].faculties.indexOf(
          item.uId
        ) === -1
      ) {
        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.facultyAllocation[this.tempSubjectIndex].subjectId,
            subjectCode:
              this.facultyAllocation[this.tempSubjectIndex].subjectCode,
            subjectType:
              this.facultyAllocation[this.tempSubjectIndex].subjectType,
            faculties: this.facultyAllocation[
              this.tempSubjectIndex
            ].faculties.concat(item.uId),
          };

          let res =
            await this.subjectRepositoryInstance.updateFacultiesTeachingASubject(
              objToPush
            );

          alert("Faculty allocated successfully");
          this.facultyAllocation[this.tempSubjectIndex].faculties.push(
            item.uId
          );
        } catch (err) {
          console.log(err);
        }
      } else {
        alert("Faculty already allocated");
      }
    },
    getDateTimeOfLecture(dateObject, startTimeOfLecture) {
      const timeArray = startTimeOfLecture
        .split("_")
        .map((time) => parseInt(time));

      timeArray[0] += 5;
      timeArray[1] += 30;
      return new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      );
    },
    getNameOfFaculty(facultyId) {
      const indexOfFaculty = this.faculties.findIndex(
        (faculty) => faculty.uId === facultyId
      );
      if (indexOfFaculty > -1) {
        return this.faculties[indexOfFaculty].name;
      } else {
        return "";
      }
    },

    async fetchFaculties() {
      this.loading = true;
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
      };

      this.faculties = [];
      try {
        this.allFacultiesOfASem = [];
        const facultyObjects =
          (await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute(
            objToPush
          )) || [];
        this.allFacultiesOfASem = facultyObjects;
        let uIds = [];
        for (let i = 0; i < facultyObjects.length; i++) {
          uIds.push(facultyObjects[i].uId);
        }
        let nameOfFaculty =
          await this.userRepositoryInstance.getFullNameOfUsers({ uIds: uIds });
        this.faculties = nameOfFaculty;
        this.loading = false;
      } catch (error) {
        console.log("error", error);
        this.loading = false;
      }
    },
    gotoSemestersManagment() {
      this.$router.push({
        name: "activitySemestersManagment",
        params: {
          prop_semName: this.selectedSemester.semName,
        },
      });
    },
  },
};
</script>
<style src='./activityEditFacultyAllocation.css' scoped></style>