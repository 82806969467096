const salutations = [
  { abbreviation: "Dr.", full_form: "Doctor" },
  { abbreviation: "Prof.", full_form: "Professor" },
  { abbreviation: "Mr.", full_form: "Mister" },
  { abbreviation: "Mrs.", full_form: "Missus" },
  { abbreviation: "Ms.", full_form: "Neutral form for women" },
  { abbreviation: "Miss", full_form: "Unmarried woman" },
  { abbreviation: "Mx.", full_form: "Gender-neutral title" },
  { abbreviation: "Pres.", full_form: "President" },
  { abbreviation: "Chancellor", full_form: "Chancellor" },
  { abbreviation: "Dean", full_form: "Dean" },
  { abbreviation: "Principal", full_form: "Principal" },
  { abbreviation: "Vice Principal", full_form: "Vice Principal" },
  { abbreviation: "Director", full_form: "Director" },
  { abbreviation: "Fr.", full_form: "Father" },
  { abbreviation: "Sr.", full_form: "Sister" },
  { abbreviation: "Br.", full_form: "Brother" },
  { abbreviation: "Rev.", full_form: "Reverend" },
];

export default salutations;
