import { emailRegex } from "../../Constants/Utils/Statics";

const stringOps = {
  /**
   * Capitalizes the first letter of a string and ensures the rest is in lowercase.
   * @param {string} str - The input string to be capitalized.
   * @returns {string} - The capitalized string.
   */
  capitalize(str) {
    try {
      // Validate input
      if (typeof str !== "string") {
        throw new TypeError(`Expected a string, but received ${typeof str}`);
      }

      if (str.trim() === "") {
        return ""; // Return an empty string for blank inputs
      }

      // Capitalize the first letter and lowercase the rest
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } catch (error) {
      console.error(`Error in capitalize function: ${error.message}`);
      // Return a default value or rethrow the error based on your use case
      return "";
    }
  },
  capitalizeWords(str) {
    try {
      // Validate input
      if (typeof str !== "string") {
        throw new TypeError(`Expected a string, but received ${typeof str}`);
      }

      if (str.trim() === "") {
        return ""; // Return an empty string for blank inputs
      }

      // Capitalize the first letter of each word
      return str
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    } catch (error) {
      console.error(`Error in capitalizeWords function: ${error.message}`);
      return "";
    }
  },

  isValidAlphabeticalString(string) {
    if (string === null) return false;

    if (!isNaN(string)) return false;

    let trimmedString = string.trim();

    if (string) {
      let lettersPattern = /^[A-Za-z]+$/;
      return lettersPattern.test(trimmedString);
    }
  },
  isValidAlphabeticalStringWithSpaces(string) {
    if (string === null) return false;

    let trimmedString = string.trim();

    let lettersPattern = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;
    return lettersPattern.test(trimmedString);
  },

  isValidEmail(string) {
    if (string === null) return false;

    if (!isNaN(string)) return false;

    let trimmedString = string.trim();

    if (trimmedString) {
      let emailPattern = emailRegex;
      return emailPattern.test(trimmedString);
    }

    return false;
  },
  doesPatternMatch(regex, value) {
    try {
      // Validate that regex is a RegExp instance
      if (!(regex instanceof RegExp)) {
        throw new TypeError(
          "The first parameter must be a valid RegExp instance."
        );
      }

      // Validate that value is a string
      if (typeof value !== "string") {
        throw new TypeError("The second parameter must be a string.");
      }

      // Test the regex against the value
      return regex.test(value);
    } catch (error) {
      console.error("Error:", error.message);
      return false; // Return false if any error occurs
    }
  },
  isValidPhoneNumber(string) {
    if (string === null) return false;

    if (!isNaN(string)) {
      let trimmedString = string.trim();
      let phonePattern = /^\d{10}$/;
      return phonePattern.test(trimmedString);
    }

    return false;
  },
};

export default stringOps;
