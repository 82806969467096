import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/InstituteUser'

class InstituteUser {
  constructor(context) {
    this.context = context
  }

  async getUsersOfAnInstitute(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getUsersOfAnInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getInstituteUser(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getInstituteUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteUser
  }

  async getAllDetailsOfTheUsers(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllDetailsOfTheUsers, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteUsers
  }

  async getInstituteProvidedId(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getInstituteProvidedId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteProvidedId
  }

  async updateInstituteProvidedId(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateInstituteProvidedId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteProvidedId
  }

  async getMultiInstituteUsers(data) {
    console.log('datasdfgsdfg', data);
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getMultiInstituteUsers, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteUsers
  }
}

export default InstituteUser
