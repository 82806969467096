<template src="./hallTicket.html"></template>

<script>
import SubjectRepository from "../../../Repository/Subject";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import UserAuthenticationRepository from "../../../Repository/UserAuthentication";
import ExamFormAndHallTicketRepository from "../../../Repository/ExamFormAndHallTicket";
import InstituteRepository from "../../../Repository/Institute";
import showStatus from "../../../NetworkManager/showStatus";
import apiV3 from "../../../NetworkManager/apiV3";
import auth from "../../../Services/Firebase/auth";
import arrOps from "../../../Services/Utils/arrayOps";
import moment from 'moment'
import {
  courseYearNumbersAbbr
} from "../../../Constants/Utils/Statics";
export default {
  name: "hallTicket",
  data() {
    return {
      primaryColor: "#050D92",
      secondaryColor: "#FF4F1F",
      progressIndicator: 12,
      examTabs: ["Student List", "Hall Ticket"],
      tabs: "",
      headers: [
        {
          text: "Publish Hall Ticket",
          value: "isHallTicketPublished",
          align: "center",
          sortable: false,
          width: "15%",
        },
        { text: "Sr. No", value: "srNo", align: "center" },
        { text: "Roll. No", value: "rollNumber", align: "center" },
        { text: "PRN", value: "collegePRNNo", align: "center" },
        { text: "Exam Seat Number", value: "examSeatNumber", align: "center" },
        { text: "Student Name", value: "studentName", align: "center" },
        {
          text: "Exam Form",
          value: "isExamFormAccepted",
          align: "center",
        },
        {
          text: "Remarks",
          value: "examFormRemark",
          align: "center",
          width: "20%",
        },
        {
          text: "Hall Ticket Status",
          value: "viewHallTicket",
          align: "center",
        },
      ],
      headersStudent: [
        { text: "Year/sem", value: "semester", align: "center" },
        { text: "Subject Code", value: "subjectCode", align: "center" },
        { text: "Subject Name", value: "subjectName", align: "center" },
        { text: "Exam Date", value: "examDate", align: "center" },
        { text: "Exam Time", value: "examTime", align: "center" },
      ],
      selectedDepartment: "",
      selectedCourseYear: "",
      selectedInstitute: "",
      selectedSemester: null,
      examId: null,
      courseYearStudents: [],
      search: "",
      hallTicketTableData: [],
      // remarksItem: ["Exam fee not paid", "college fee not paid", "others"],
      examFormDialog: false,
      selectedStudent: {},
      emailIdsOfStudents: [],
      exam: {},
      subjects: [],
      subjectsData: [],
      fetchedData: [],
      instituteInfo: {},
      examTabDependecies: {},
      filterMenu: false,
      filterOption: null,
      // examFormAndHallTicketInstance: false, //
      filteredHallTicketTableData: [],
      loading: false,
      publishAllHallTicketCheckbox: false,
      tabLoading: true,
      isSubjectsDataAvailable: true,
      loadingBulk :  false,
      isStmiras: false,
      isRiims: false,
      hallticketSubjectsSortOn : 'examDate'
    };
  },
  activated() {
    // Your logic here
    let newValue = this.$store.state.liveData.examTabDependencies;
    let flag = false;
    Object.entries(newValue).forEach(([key, value]) => {
      if (value["hallTicketTab"] === true) {
        flag = true;
        value["hallTicketTab"] = false;
      }
    });
    if (flag === true) {
      this.initialize();
    }
  },
  async created() {
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.examSubjectAndQuestionRepositoryInstance =
      new ExamSubjectAndQuestionRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.userAuthenticationInstance = new UserAuthenticationRepository(this);
    this.examFormAndHallTicketInstance = new ExamFormAndHallTicketRepository(
      this
    );
    this.InstituteRepositoryInstance = new InstituteRepository(this);
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.examId = this.$store.getters["liveData/get_examId"];
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.getStudentsOfACourseYear();
      await this.getUsersDetails();
      await this.getUsersEmailId();
      await this.getStudentsExamForm();
      await this.getExam();
      await this.fetchSubjects();
      await this.getSubjectData();
      await this.getInstituteCustomizationFromFirebase();
      if (this.selectedInstitute === "6604982c-cc08-4e00-81fb-a375a10b72e4") {
      this.isStmiras = true
      }
      if (this.selectedInstitute === "2939ed29-4649-4043-8498-f4eb3b0eac9e") {
      this.isRiims = true
      }
      if (this.selectedInstitute === "e9f11b1c-eef3-4f90-baae-054ddc4ffb9b") {
        this.isMima = true;
      }
      this.prepareHallTicketTable();
      this.calculateProgressIndicator();
      await this.temp();
      this.checkAllSelected();
      this.examTabDependecies =
        this.$store.getters["liveData/get_examTabDependencies"];
      // state['tab3'].isTriggered = true;
      this.prepareDataForHallTicket();
      this.tabLoading = false;
    },
    async getStudentsOfACourseYear() {
      try {
        this.courseYearStudents =
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
          });
      } catch (error) {
        this.courseYearStudents = [];
      }
    },
    async getUsersDetails() {
      let studentIds = this.courseYearStudents?.map((student) => student.uId);
      const data = await this.userRepositoryInstance.getUsers({
        uIds: studentIds,
      });
      this.userDetails = data;
    },
    async getUsersEmailId() {
      // emailIdsOfStudents
      let studentIds = this.courseYearStudents?.map((student) => student.uId);
      let allEmails =
        await this.userAuthenticationInstance.getUserAuthenticationsByUIds({
          uIds: studentIds,
        });
      let filteredEmails = [];
      allEmails.forEach((item, index) => {
        const { uId } = item;
        filteredEmails[uId] = item;
      });
      this.emailIdsOfStudents = Object.values(filteredEmails);
    },
    async getExam() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          examId: this.examId,
        };
        let res = await this.newExamRepositoryInstance.getExam(objToPush);
        this.exam = res.exam;
        // this.exam = res
      } catch (error) {
        console.error("error", error);
      }
    },
    async fetchSubjects() {
      if (this.exam.subjectIds.length > 0) {
        const res = await this.subjectRepositoryInstance.getSubjects({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: this.exam.subjectIds,
        });
        this.subjects = res;
      } else {
        this.isSubjectsDataAvailable = false;
      }
    },
    async getInstituteCustomizationFromFirebase() {
      const result = await auth.getInstituteCustomizationFromFirebase(
        this.selectedInstitute
      );
      if(result.hallticketSubjectsSortOn){
        this.hallticketSubjectsSortOn = result.hallticketSubjectsSortOn
      }
    },
    sortByAlphaNumericValue(array, fieldToSortBy) {
      const newArray = [...array];
      newArray.sort((a, b) => {
        if ( 
          a[fieldToSortBy] === null ||
          b[fieldToSortBy] === null ||
          a[fieldToSortBy] === undefined ||
          b[fieldToSortBy] === undefined
        ) {
          // If either fieldToSortBy is null, move the element with null fieldToSortBy to the end.
          if (a[fieldToSortBy] === null || a[fieldToSortBy] === undefined)
            return 1;
          if (b[fieldToSortBy] === null || b[fieldToSortBy] === undefined)
            return -1;
        }
        return a[fieldToSortBy].localeCompare(b[fieldToSortBy]); // Compare non-null fieldToSortBy strings
      });
      return newArray;
    },
    async getSubjectData() {
      let response =
        await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions(
          {
            instituteId: this.selectedInstitute,
            examId: this.examId,
            subjectIds: this.exam.subjectIds,
          }
        );
      if (response.result.length === 0) {
        this.isSubjectsDataAvailable = false;
        return;
      }
      // let subjectsData = response.result
      let temp = [];
      this.subjects.forEach((subject) => {
        let data = response.result.find(
          (item) => subject.subjectId == item.subjectId
        );
        temp.push({
          subjectCode: subject.subjectCode,
          subjectName: subject.subjectName,
          subjectId: subject.subjectId,
          subjectDescription: subject.subjectDescription,
          allocatedStudents: data.allocatedStudents,
          semester: this.selectedSemester.semName,
          credits: subject.credits,
          examDate: data.examDate,
          examTime: data.examTime,
          examDuration: data.examDuration,
        });
      });
      this.subjectsData = this.sortByAlphaNumericValue(temp, this.hallticketSubjectsSortOn);
    },
    prepareHallTicketTable() {
      let temp = [];
      this.courseYearStudents.forEach((student, index) => {
        let userDetails = this.userDetails.find(
          (user) => student.uId === user.uId
        );
        let userEmail =  this.emailIdsOfStudents.find((user) => student.uId === user.uId) ?  this.emailIdsOfStudents.find((user) => student.uId === user.uId).email : ''
        let examFormDataForStudent = this.fetchedData.find(
          (item) => item.uId === student.uId
        );
        let subData = this.subjectsData.filter((subject) =>
          subject.allocatedStudents?.includes(student.uId)
        );
        let isHallTicketPublished;
        if (examFormDataForStudent?.isHallTicketPublished !== undefined) {
          isHallTicketPublished = examFormDataForStudent.isHallTicketPublished;
        } else {
          isHallTicketPublished = true;
        }
        let modifiedSemesterName =  this.selectedSemester.semName
        let romanNumerals = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];
        if(this.isStmiras === true ){
          const [initial,] = this.selectedCourseYear?.split(' ')
          const [prefix, middle , postfix] = this.selectedSemester.semName?.split(' ')
          const tempSemNum = middle?.split('/')
          modifiedSemesterName = `${prefix} ${ tempSemNum[courseYearNumbersAbbr[initial]-1]} ${postfix}`
        }
        if(this.isRiims === true ){
          const [initial,] = this.selectedCourseYear?.split(' ')
          const [prefix, middle , postfix] = this.selectedSemester.semName?.split(' ')
          const tempSemNum = middle?.split('/')
          let convertedRomanNumber = romanNumerals[parseInt(tempSemNum[courseYearNumbersAbbr[initial]-1])-1] 
          modifiedSemesterName = `${prefix} ${convertedRomanNumber} ${postfix}`
        }
        temp.push({
          rollNumber: student.rollNumber,
          collegePRNNo: userDetails.collegePRNNo,
          studentName: userDetails.firstName + " " + userDetails.lastName,
          isExamFormAccepted: examFormDataForStudent
            ? examFormDataForStudent.isExamFormAccepted
            : false,
          examFormRemark: examFormDataForStudent
            ? examFormDataForStudent.examFormRemark
            : null,
          isHallTicketPublished: isHallTicketPublished,
          contactNumber: userDetails.mobile,
          userEmail: userEmail,
          uId: student.uId,
          gender: userDetails.gender,
          category: userDetails.category,
          isChanged: examFormDataForStudent?.isHallTicketPublished
            ? false
            : true,
          subjectsData: subData,
          subjects: subData,
          examSeatNumber: student.examSeatNumber,
          motherName: userDetails.motherName || "-",
          examDetails: [],
          abcIdNumber : userDetails.abcIdNumber || "-",
          grNumber : userDetails.grNumber ||  "-",
          semester : modifiedSemesterName
        });
      });
      this.hallTicketTableData = this.sortByAlphaNumericValue(temp, 'rollNumber');
      this.filteredHallTicketTableData = this.sortByAlphaNumericValue(this.hallTicketTableData, 'rollNumber');
    },
    handleHallTicketDialog(item) {
      this.examFormDialog = true;
      item.subjectsData = this.subjectsData.filter((subject) =>
        subject.allocatedStudents.includes(item.uId)
      );
      this.selectedStudent = item;
    },
    async saveAndPublishHallTicket() {
      let changedItems = this.hallTicketTableData
        .filter((item) => item.isChanged === true)
        ?.map(
          ({
            uId,
            isExamFormAccepted,
            examFormRemark,
            isHallTicketPublished,
          }) => ({
            uId,
            // isExamFormAccepted,
            // examFormRemark,
            isHallTicketPublished,
            examId: this.examId,
            semId: this.selectedSemester.semId,
            instituteId: this.selectedInstitute,
            courseYear: this.selectedCourseYear,
          })
        );
      try {
        if (changedItems.length > 0) {
          let res =
            await this.examFormAndHallTicketInstance.createExamFormAndHallTicket(
              changedItems
            );
          if (res.status.status === 200) {
            this.hallTicketTableData.forEach((item) => {
              let present = changedItems.find(
                (changedItem) => changedItem.uId === item.uId
              );
              if (present) {
                item.isChanged = false;
              }
            });
            showStatus("Successfully saved.", 2000, "success", this);
          }
        } else {
          showStatus(
            "Result for students is already published",
            2000,
            "warning",
            this
          );
        }
      } catch (error) {
        showStatus("Something went wrong", 2000, "error", this);
      }
      for(let key in  this.examTabDependecies['hallTicketTab']){
        this.examTabDependecies['hallTicketTab'][key] = true;
      }
    },
    async getStudentsExamForm() {
      let studentIds = this.courseYearStudents?.map((student) => student.uId);
      try{
        let res =
          await this.examFormAndHallTicketInstance.getExamFormAndHallTicket({
            instituteId: this.selectedInstitute,
            examId: this.examId,
            uIds: studentIds,
          });
        this.fetchedData = res.result;

      }catch (error) {
        console.error(error)
      }
    },
    calculateProgressIndicator() {
      this.progressIndicator =
        (this.hallTicketTableData.filter((item) => item.isHallTicketPublished)
          .length /
          this.hallTicketTableData.length) *
        100;
    },

    async temp() {
      //get insti info
      this.instituteInfo =
        await this.InstituteRepositoryInstance.getInstituteInformation({
          instituteId: this.selectedInstitute,
        }); 
    },
    async downloadBulkHallTicket() {
      let arrayToSend =  this.hallTicketTableData?.map((item) =>{
        return{ prnNo : item.collegePRNNo,
          examNo : item.examSeatNumber,
          rollNo : item.rollNumber,
          fullName: item.studentName,
          motherName : item.motherName,
          examDetails : item.examDetails,
          semester : item.semester,
          abcIdNumber : item.abcIdNumber,
          grNumber : item.grNumber
        }
      }).filter((item)=>item.examDetails.length > 0);
      this.loadingBulk = true;
      const objToPush = {
        instituteData: { ...this.instituteInfo, isRiims: this.isRiims, isMima: this.isMima, isStmiras: this.isStmiras },
        studentData: arrayToSend,
      };

      try {
        const result = await apiV3.postRequest(
          "/manageExam/generateStudentHallTicket",
          objToPush
        );
        if (result) {
          window.open(result.data.pdfUrl);
        }
        // this.downloadFileFromBuffer(result); // pass the buffer of the file
        showStatus(
          "Hall Ticket downloaded successfully.",
          2000,
          "success",
          this
        );
      } catch (error) {
        console.error("Error Downloading Hall Ticket", error);
      }
      this.loadingBulk = false;

    },
    async downloadHallTicket() {
      this.loading = true;
      let arrayToSend =  this.hallTicketTableData?.filter((item) =>item.uId == this.selectedStudent.uId)?.map((item)=>{
        return{ prnNo : item.collegePRNNo,
          examNo : item.examSeatNumber,
          rollNo : item.rollNumber,
          fullName: item.studentName,
          motherName : item.motherName,
          examDetails : item.examDetails,
          semester : item.semester,
          abcIdNumber : item.abcIdNumber,
          grNumber : item.grNumber
        }
      }).filter((item)=>item.examDetails.length > 0);
      if(arrayToSend.length > 0) {
        const objToPush = {
        instituteData: { ...this.instituteInfo, isRiims: this.isRiims, isMima: this.isMima, isStmiras: this.isStmiras },
        studentData: arrayToSend,
      };
      try {
        const result = await apiV3.postRequest(
          "/manageExam/generateStudentHallTicket",
          objToPush
        );
        if (result) {
          window.open(result.data.pdfUrl);
        }
        // this.downloadFileFromBuffer(result); // pass the buffer of the file
        showStatus(
          "Hall Ticket downloaded successfully.",
          2000,
          "success",
          this
        );
      } catch (error) {
        console.error("Error Downloading Hall Ticket", error);
      }
      this.loading = false;
      }else{
        showStatus("No subjects allocated", 2000, "error", this);
        this.loading = false;
      }

    },

    // downloadFileFromBuffer(result) {
    //   const blob = new Blob([result.data.pdfUrl], { type: 'application/pdf' });
    //   // Create a download link
    //   const link = document.createElement('a');
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = 'hall_ticket.pdf';
    //   // Append the link to the document and trigger a click
    //   document.body.appendChild(link);
    //   link.click();
    //   // Remove the link from the document
    //   document.body.removeChild(link);
    //   showStatus(
    //     "Error fetching link for online lecture .",
    //     1000,
    //     "success",
    //     this
    //   );
    // },
    calculateTimeFrame(startTime, durationMinutes) {
      // Splitting the startTime into hours and minutes
      let [startHour, startMinute] = startTime?.split(":").map(Number);

      // Calculating total minutes
      let totalMinutes = startHour * 60 + startMinute + durationMinutes;

      // Calculating end time hours and minutes
      let endHour = Math.floor(totalMinutes / 60);
      let endMinute = totalMinutes % 60;

      // Adjusting endHour and endMinute if they exceed 24 hours
      endHour = endHour % 24;

      // Determining AM or PM for start and end times
      let startPeriod = startHour < 12 ? "AM" : "PM";
      let endPeriod = endHour < 12 ? "AM" : "PM";

      // Converting startHour and endHour to 12-hour format
      if (startHour === 0) {
        startHour = 12; // 12 AM
      } else if (startHour > 12) {
        startHour -= 12;
      }

      if (endHour === 0) {
        endHour = 12; // 12 AM
      } else if (endHour > 12) {
        endHour -= 12;
      }

      // Formatting start and end times
      const formattedStartTime = `${startHour
        .toString()
        .padStart(2, "0")}:${startMinute
        .toString()
        .padStart(2, "0")} ${startPeriod}`;
      const formattedEndTime = `${endHour
        .toString()
        .padStart(2, "0")}:${endMinute
        .toString()
        .padStart(2, "0")} ${endPeriod}`;

      return `${formattedStartTime} - ${formattedEndTime}`;
    },
    prepareDataForHallTicket() {
      // this.hallTicketTableData
      if (this.instituteInfo.examFormFormat) {
        this.examFormFormatPresent = true;
      }
      if (this.instituteInfo.hallTicketFormat) {
        this.hallTicketFormatPresent = true;
        this.hallTicketFormat = this.instituteInfo.hallTicketFormat;
        if (this.hallTicketFormat.signaturePhoto?.length > 0) {
          this.officialSignaturePresent = true;
        }
        if (this.hallTicketFormat.isCollageStampNeeded) {
          this.collegeStampUrl = this.hallTicketFormat.collageStampPhoto[0].url;
          this.collegeStampName =
            this.hallTicketFormat.collageStampPhoto[0].name;
        }
      } else {
        this.hallTicketFormat = {};
      }
      this.hallTicketTableData.forEach((stud)=>stud.examDetails=[])

      if (this.isStmiras || this.isRiims || this.isMima) {
          this.subjectsData = this.subjectsData
              .map(subject => {
                  const { subjectDescription } = subject;
                  return {
                      ...subject,
                      subjectDescription: !isNaN(subjectDescription) && subjectDescription !== "" 
                          ? Number(subjectDescription) 
                          : subjectDescription
                  };
              })
              .sort((a, b) => {
                  const descA = a.subjectDescription;
                  const descB = b.subjectDescription;
                  return typeof descA === "number" && typeof descB === "number"
                      ? descA - descB
                      : String(descA).localeCompare(String(descB));
              });
      }
      console.log('this.subjectsData', this.subjectsData);
      this.subjectsData?.forEach((sub, index) => {
        this.hallTicketTableData?.forEach((stud) => {
          let timeFrame = "-";
          if (sub.examTime && sub.examDuration) {
            timeFrame = this.calculateTimeFrame(sub.examTime, sub.examDuration);
          }
          const getDayFromDate = (dateString) =>
            [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ][new Date(dateString).getDay()];
          if(sub.allocatedStudents?.includes(stud.uId)){
            let modifiedSemesterName =  this.selectedSemester.semName

        let romanNumerals = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];
        if(this.isStmiras === true){
          const [initial,] = this.selectedCourseYear?.split(' ')
          const [prefix, middle , postfix] = this.selectedSemester.semName?.split(' ')
          const tempSemNum = middle?.split('/')
          modifiedSemesterName = `${prefix} ${ tempSemNum[courseYearNumbersAbbr[initial]-1]} ${postfix}`
        }
        if(this.isRiims === true ){
          const [initial,] = this.selectedCourseYear?.split(' ')
          const [prefix, middle , postfix] = this.selectedSemester.semName?.split(' ')
          const tempSemNum = middle?.split('/')
          let convertedRomanNumber = romanNumerals[parseInt(tempSemNum[courseYearNumbersAbbr[initial]-1])-1] 
          modifiedSemesterName = `${prefix} ${convertedRomanNumber} ${postfix}`
        }
            //
            let internalExamSubjectIdsForStmiras = ["71d19ea7-041e-4f7b-a980-979ceca6eb61", "fb8184b2-847e-4a8a-ac27-64625eb8bc34", "ec05adba-fed0-42d8-a604-15995e416700", "0885e77f-96bc-4280-9bed-de6fa429d542", "d9a50d94-4439-4d83-83d0-5f3f3242d1f2", "6c8d4041-5dbf-43a1-bf69-4371d604a019", "b04c4903-538c-48f0-b01c-349d1189eb96"]
            let typeOfExam = "Internal-Endsem"
            if(!this.isStmiras) typeOfExam = this.exam.examFormat === 'Formative' ? 'CIE(Internal)' : this.exam.examFormat === 'Summative' ? 'SEE(External)' : '';
            else {
              if(internalExamSubjectIdsForStmiras.includes(sub.subjectId)) typeOfExam = 'Internal'
            }
            let isExamDetailsDuplicate = stud.examDetails.some((exam) => exam.subjectCode === sub.subjectCode)
            if(isExamDetailsDuplicate === false){
              let obj = {
                subjectName: sub.subjectName,
                date: moment(sub.examDate).format(' DD/MM/YYYY '),
                subjectCode: sub.subjectCode,
                timeFrame: timeFrame,
                typeOfExam: typeOfExam,
                semName: modifiedSemesterName,
                department: this.selectedDepartment,
                courseYear: this.selectedCourseYear,
                examDay: getDayFromDate(sub.examDate),
              };
              stud.examDetails.push(obj);
            }
          }
        });
      });
      if (this.instituteInfo.hallTicketFormat) {
        this.instituteInfo.hallTicketFormat["tableColumns"] = [];
        this.instituteInfo.hallTicketFormat.examDetails.map((item) => {
          let objToPush = {};
          if (item === "Department") {
            objToPush["key"] = "Department";
            objToPush["value"] = "department";
          }
          if (item === "Type of Exam") {
            objToPush["key"] = "Type of Exam";
            objToPush["value"] = "typeOfExam";
          }
          if (item === "Semester") {
            objToPush["key"] = "Semester";
            objToPush["value"] = "semName";
          }
          if (item === "Subject Code") {
            objToPush["key"] = "Subject Code";
            objToPush["value"] = "subjectCode";
          }
          if (item === "Subject Name") {
            objToPush["key"] = "Subject Name";
            objToPush["value"] = "subjectName";
          }
          if (item === "Exam Day") {
            objToPush["key"] = "Exam Day";
            objToPush["value"] = "examDay";
          }
          if (item === "Exam Time") {
            objToPush["key"] = "Exam Time";
            objToPush["value"] = "timeFrame";
          }
          if (item === "Exam Date") {
            objToPush["key"] = "Exam Date";
            objToPush["value"] = "date";
          }
          if (item === "Course Year") {
            objToPush["key"] = "Course Year";
            objToPush["value"] = "courseYear";
          }
          this.instituteInfo.hallTicketFormat.tableColumns.push(objToPush);
        });
      }
      this.instituteInfo['examName'] = this.exam?.title
    },
    handleFilter() {
      if (this.filterOption == 0) {
        this.filteredHallTicketTableData = this.hallTicketTableData;
      } else if (this.filterOption == 1) {
        //only hall ticket published
        this.filteredHallTicketTableData = this.hallTicketTableData.filter(
          (item) => item.isHallTicketPublished === true
        );
      } else if (this.filterOption == 2) {
        //hall ticket not published
        this.filteredHallTicketTableData = this.hallTicketTableData.filter(
          (item) => item.isHallTicketPublished === false
        );
      } else if (this.filterOption == 3) {
        //student with remarks
        this.filteredHallTicketTableData = this.hallTicketTableData.filter(
          (item) => item.examFormRemark !== null
        );
      } else if (this.filterOption == 4) {
        //exam form accepted with remarks
        this.filteredHallTicketTableData = this.hallTicketTableData.filter(
          (item) => item.isExamFormAccepted === true && item.examFormRemark
        );
      } else if (this.filterOption == 5) {
        //exam form accepted
        this.filteredHallTicketTableData = this.hallTicketTableData.filter(
          (item) => item.isExamFormAccepted === true
        );
      }
      this.filterMenu = false;
    },
    goToNextPage() {
      this.$emit("nextButtonClick");
    },
    publishAllHallTicket() {
      // console.log("acceptAllExamFormCheckbox",this.acceptAllExamFormCheckbox)
      const acceptValue = this.publishAllHallTicketCheckbox;
      this.hallTicketTableData?.forEach((item) => {
        if (item.isHallTicketPublished !== acceptValue) {
          item.isHallTicketPublished = acceptValue;
          item.isChanged = true;
        }
      });
      this.calculateProgressIndicator()
    },
    checkAllSelected() {
      this.publishAllHallTicketCheckbox = this.hallTicketTableData?.every(
        (item) => item.isHallTicketPublished
      );
    },
  },
};
</script>

<style src="./hallTicket.css"></style>