<template src="./sessionPlan.html"></template>

<script>
import NbaRepository from "../../../../Repository/NBA";
import SyallbusRepository from "../../../../Repository/Syllabus";
import SessionRepository from "../../../../Repository/Session";
import showStatus from "../../../../NetworkManager/showStatus";
import { v4 } from "uuid";
export default {
  name: "sessionPlan",
  components: {
    // basicDetails,
    // examsBasicDetails,
    // examSubjectDetails,
    // examForm,
    // hallTicket,
    // examQuestionDetails,
    // examMarks,
  },
  props: ["subjectId", "semId", "courseYear", "selectedDepartment"],
  async created() {
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.syllabusInstance = new SyallbusRepository(this);
    this.sessionInstance = new SessionRepository(this);
    await this.getSyllabus();
    await this.getSessions();
  },
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      selectedInstitute: "",
      fileDialog: "",
      headers: [
        {
          text: "session no",
          value: "sessionNumber",
          width: "10%",
          sortable: false,
        },
        {
          text: "type",
          value: "sessionType",
          width: "10%",
          sortable: false,
          cellClass: "unitClass",
        },
        {
          text: "Select Unit",
          value: "selectUnit",
          width: "10%",
          sortable: false,
          cellClass: "unitClass",
        },
        {
          text: "Select Topic",
          value: "selectTopic",
          width: "10%",
          sortable: false,
          cellClass: "unitClass",
        },
        {
          text: "Select Sub Topic",
          value: "selectSubTopic",
          width: "10%",
          sortable: false,
          cellClass: "unitClass",
        },
        {
          text: "Actions",
          value: "actions",
          width: "10%",
          sortable: false,
          cellClass: "actionsClass",
        },
      ],
      selectedItem: {},
      totalTeachingHours: "",
      sessionLength: "",
      sessions: [],
      loading: false,
      sessionTypeList: ["Teaching", "Assessment", "Tutorial"],
      syllabus: null,
      totalSessions: 0,
      deleteSessionCheck: false,
      sessionForDelete: null
    };
  },
  computed: {
    isSaveDisabled() {
      return this.sessions.some(session => 
        !session.sessionType || 
        !session.units.length || 
        session.units.some(unit => !unit.unitId)
      );
    }
  },
  methods: {
    async getSyllabus() {
      let res = await this.syllabusInstance.getSyllabus({
        instituteId: this.selectedInstitute,
        semId: this.semId,
        department: this.selectedDepartment,
        courseYear: this.courseYear,
        subjectId: this.subjectId,
      });
      this.syllabus = res.result;
    },

    handleSessionArray() {
      if (Number(this.totalTeachingHours) && Number(this.sessionLength)) {
        this.totalSessions = Math.floor(this.totalTeachingHours / this.sessionLength);
        if (this.sessions.length > this.totalSessions) {
          this.sessions.splice(this.totalSessions);
        } else if (this.sessions.length < this.totalSessions) {
          while (this.sessions.length < this.totalSessions) {
            this.sessions.push({
              sessionNumber: this.sessions.length + 1,
              sessionId: v4(),
              sessionType: "",
              units: [],
            });
          }
        }
      }
    },

    addSelectUnit(item) {
      if (!item.units) {
        this.$set(item, "units", []);
      }
      item.units.push({
        unitId: "",
        topics: [],
      });
    },

    removeUnit(item, unitIndex) {
      item.units.splice(unitIndex, 1);
    },

    addSelectTopic(item, unitIndex) {
      if (!item.units[unitIndex].topics) {
        this.$set(item.units[unitIndex], "topics", []);
      }
      item.units[unitIndex].topics.push({
        topicId: "",
        selectedSubTopics: [],
      });
    },

    removeTopic(item, unitIndex, topicIndex) {
      item.units[unitIndex].topics.splice(topicIndex, 1);
    },

    getTopicsByUnitId(unitId) {
      const unit = this.syllabus?.units?.find((u) => u.unitId === unitId);
      return unit ? unit.topics || [] : [];
    },

    getSubTopicsByTopicId(topicId, unitId) {
      const unit = this.syllabus?.units?.find((u) => u.unitId === unitId);
      if (!unit) {
        return [];
      }
      const topic = unit.topics?.find((t) => t.topicId === topicId);
      if (!topic) {
        return [];
      }
      return topic.subTopics || [];
    },

    async getSessions() {
      try {
        let res = await this.sessionInstance.getSessions({
          instituteId: this.selectedInstitute,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          subjectId: this.subjectId,
        });

        if (res.result.length >= 1) {
          this.sessions = res.result.map((session) => {
            session.units.forEach((unit) => {
              unit.topics.forEach((topic) => {
                // Ensure selectedSubTopics is initialized correctly
                topic.selectedSubTopics = topic.subTopics?.map((subTopic) => subTopic.subTopicId) || [];
              });
            });
            return session;
          });

          this.totalTeachingHours = res.result[0].totalTeachingHours;
          this.sessionLength = res.result[0].sessionLength;
        }
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    },

    async deleteSession(sessionId) {
      try {
        this.loading = true;

        // Find the session to be deleted
        const sessionToDelete = this.sessions.find(
          (session) => session.sessionId === sessionId
        );

        if (!sessionToDelete) {
          showStatus("Session not found", 2000, "error", this);
          return;
        }

        // Call the backend API to delete the session
        let res = await this.sessionInstance.deleteSession({
          instituteId: this.selectedInstitute,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          subjectId: this.subjectId,
          sessionId: sessionId,
        });

        if (res?.status?.status) {
          // Update Total Teaching Hours
          this.totalTeachingHours -= sessionToDelete.sessionLength;

          // Remove the session from the local sessions array
          this.sessions = this.sessions.filter(
            (session) => session.sessionId !== sessionId
          );

          // If the last session is deleted, clear the table
          if (this.sessions.length === 0) {
            this.sessions = [];
            this.totalTeachingHours = 0; // Reset total teaching hours
          }

          showStatus("Session deleted successfully", 2000, "success", this);
        } else {
          showStatus("Failed to delete session", 2000, "error", this);
        }
      } catch (error) {
        console.error("Error deleting session:", error);
        showStatus("Something went wrong", 2000, "error", this);
      } finally {
        this.loading = false;
      }
    },

    confirmDeleteSession(session) {
      this.sessionForDelete = session
      this.deleteSessionCheck = true
    },

    async saveSessions() {
      try {
        this.loading = true;

        // Process sessions before saving
        this.sessions.forEach((session) => {
          session.totalTeachingHours = Number(this.totalTeachingHours);
          session.sessionLength = Number(this.sessionLength);

          session.units.forEach((unit) => {
            unit.topics.forEach((topic) => {
              // Ensure subtopics are correctly mapped
              topic.subTopics = topic.selectedSubTopics
                .filter((subTopicId) => {
                  // Validate subtopic IDs against the syllabus
                  const validSubTopics = this.getSubTopicsByTopicId(topic.topicId, unit.unitId);
                  return validSubTopics.some((subTopic) => subTopic.subTopicId === subTopicId);
                })
                .map((subTopicId) => ({
                  subTopicId,
                }));
            });
          });
        });

        // Save data
        let res = await this.sessionInstance.createSessions({
          instituteId: this.selectedInstitute,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          subjectId: this.subjectId,
          sessions: this.sessions,
        });

        await this.getSessions();
        showStatus("Session saved successfully", 2000, "success", this);
      } catch (error) {
        console.error("Error saving sessions:", error);
        showStatus("Something went wrong", 2000, "error", this);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped src="./sessionPlan.css">
</style>