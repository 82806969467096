export default {
  getFacultiesForDepartmentWithNameAndUserName: '/semesterUser/getFacultiesForDepartmentWithNameAndUserName',
  getStudentForDepartmentWithNameAndUserName: '/semesterUser/getStudentForDepartmentWithNameAndUserName',
  getCurrentSemesterUserForAUser: '/semesterUser/getCurrentSemesterUserForAUser',
  getExamSeatNumberAndRollNumberOfMultipleSemUser: '/semesterUser/getExamSeatNumberAndRollNumberOfMultipleSemUser',
  generateExcelUpdatePRN: '/excel/semester/generateExcelUpdatePRN',
  generateExcelRegisterStudentToCourse: '/excel/semester/generateExcelRegisterStudentToCourse',
  generateExcelAddStudentsToGroup: '/excel/semester/generateExcelAddStudentsToGroup',
  createSemesterUser: '/semesterUser/createSemesterUser',
  createMultipleSemesterUsers: '/semesterUser/createMultipleSemesterUsers',
  getFacultiesOfADepartment: '/semesterUser/getFacultiesOfADepartment',
  getFacultyAllocation: '/semesterUser/getFacultyAllocation',
  getStudentAllocation: '/semesterUser/getStudentAllocation',
  getAllFacultiesOfADepartment: '/semesterUser/getAllFacultiesOfADepartment',
  getFacultiesOfAInstitute: '/semesterUser/getFacultiesOfAInstitute',
  getAllFacultiesOfAInstituteByInstituteId: '/semesterUser/getAllFacultiesOfAInstituteByInstituteId',
  getStudentsOfADivision: '/semesterUser/getStudentsOfADivision',
  getStudentsOfSubjects: '/semesterUser/getStudentsOfSubjects',
  getStudentsOfACourseYear: '/semesterUser/getStudentsOfACourseYear',
  getStudentsOfACourseYear: '/semesterUser/getStudentsOfACourseYear',
  getStudentsOfADepartment: '/semesterUser/getStudentsOfADepartment',
  getSemesterUsersOfASubjectOfAGroup: '/semesterUser/getSemesterUsersOfASubjectOfAGroup',
  getStudentsOfAInstitute: '/semesterUser/getStudentsOfAInstitute',
  getSubjectsOfASemesterUser: '/semesterUser/getSubjectsOfASemesterUser',
  getSubjectsOfASemesterUser: '/semesterUser/getSubjectsOfASemesterUser',
  getSemesterUsersForAUser: '/semesterUser/getSemesterUsersForAUser',
  getSemesterUsers: '/semesterUser/getSemesterUsers',
  getAllSemUserOfInstitute: '/semesterUser/getAllSemUserOfInstitute',
  getSemesterUsersForMultipleDivision: '/semesterUser/getSemesterUsersForMultipleDivision',
  getStudentsOfMultipleSemesters: '/semesterUser/getStudentsOfMultipleSemesters',
  getStudentsByInstituteAndDepartment: '/semesterUser/getStudentsByInstituteAndDepartment',
  getSemesterUser: '/semesterUser/getSemesterUser',
  getMultipleSemesterUsers: '/semesterUser/getMultipleSemesterUsers',
  getSemesterUserForACourseYear: '/semesterUser/getSemesterUserForACourseYear',
  updateDepartment: '/semesterUser/updateDepartment',
  removeSubjectObjectFromSubjectsArray: '/semesterUser/removeSubjectObjectFromSubjectsArray',
  pushSubjectObjectToSubjectsArray: '/semesterUser/pushSubjectObjectToSubjectsArray',
  removeBatchFromSubjectsAndTimetable: '/semesterUser/removeBatchFromSubjectsAndTimetable',
  updateCourseYear: '/semesterUser/updateCourseYear',
  resetStudentResultData: '/semesterUser/resetStudentResultData',
  updateSemesterUserResultDetails: '/semesterUser/updateSemesterUserResultDetails',
  updateDivision: '/semesterUser/updateDivision',
  getSemesterUsersOfASubjectOfADivision: '/semesterUser/getSemesterUsersOfASubjectOfADivision',
  updateBatch: '/semesterUser/updateBatch',
  updateRollNumber: '/semesterUser/updateRollNumber',
  updateExamSeatNumber: '/semesterUser/updateExamSeatNumber',
  updateSubjectsOfAStudent: '/semesterUser/updateSubjects',
  updateSemesterUser: '/semesterUser/updateSemesterUser',
  deleteSemesterUser: '/semesterUser/deleteSemesterUser',
  bulkDeleteSemesterUsers: '/semesterUser/bulkDeleteSemesterUsers',
  deleteMultipleSemUser: '/semesterUser/deleteMultipleSemUser',
  getUsersNotInAnySemester: '/semesterUser/getUsersNotInAnySemester',
  importFaculties: '/semesterUser/importFaculties',
  importStudentsOfADivision: '/semesterUser/importStudentsOfADivision',
  // getSemesterUsersOfASubjectOfADivision: '/semesterUser/getSemesterUsersOfASubjectOfADivision',
  getSemesterUsersOfASubjectOfAGroup: '/semesterUser/getSemesterUsersOfASubjectOfAGroup',
  getSemesterUsersOfASubject: '/semesterUser/getSemesterUsersOfASubject',
  getSemUserWithNameForAttendanceAnalysis: '/semesterUser/getSemUserWithNameForAttendanceAnalysis',
  getSubjectsByUId: '/semesterUser/getSubjectsByUId'
}
