import networkManager from "../NetworkManager";
import ApiNames from "../ApiNames/MailFormat"

class MailFormat {
    constructor(context) {
        this.context = context
    }
    async createFormat(data) {
        this.context.$store.commit("liveData/set_loading", true)
        const response = await networkManager.postRequest(ApiNames.createFormat, data)
        this.context.$store.commit("liveData/set_loading", false);
        return response;
    }
    async updateFormat(data) {
        this.context.$store.commit("liveData/set_loading", true)
        const response = await networkManager.postRequest(ApiNames.updateFormat, data)
        this.context.$store.commit("liveData/set_loading", false);
        return response;
    }
    async getFormat(data) {
        this.context.$store.commit("liveData/set_loading", true)
        const response = await networkManager.postRequest(ApiNames.getFormat, data)
        this.context.$store.commit("liveData/set_loading", false);
        return response.format;
    }
}
export default MailFormat