export const subjectTypes = [
  "Normal Theory",
  "Normal Practical",
  "Normal Tutorial",
  "Normal Audit Course",
  "Elective Theory",
  "Elective Practical",
  "Elective Tutorial",
  "Elective Audit Course",
  "Course Survey",
  "summer_theory",
  "Theory",
  "Practical",
  "Tutorial",
  "Audit",
];

export const conductableLectureTypes = [0, 1, 3, 4];

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const lectureTypes = {
  ORDINARY: 0,
  PENDING: 1,
  RESCHEDULED: 2,
  RESCHEDULED_EXTRA: 3,
  REMEDIAL: 4,
  HOLIDAY: 5,
  CANCELLED: 6,
};

export const questionTypes = [
  "Simple Question",
  "Single Answer MCQ",
  "Multiselect MCQ",
];
export const questionTypesTemp = ["Single Answer MCQ"];

export const optionEnabled = ["Single Answer MCQ", "Multiselect MCQ"];

export const ownershipStatuses = [""];

export const instituteTypes = [""];

export const semesterTypes = ["Current", "Upcoming", "Past"];

export const backgroundImagesForSubjectCards = [
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20836.png?alt=media&token=daf02332-bf0f-4df3-a14e-f499d70504ee",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20838.png?alt=media&token=5e3925a9-3cc8-49b7-a283-0db0c225fc4a",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20840.png?alt=media&token=02190c7e-4364-42b7-9c7f-a0ab6cf90403",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20842.png?alt=media&token=7f4c0e49-280c-4b34-911b-dc11563500fc",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20844.png?alt=media&token=9e56e2e9-7508-48d1-8427-3cbe41d57c95",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20846.png?alt=media&token=f27f7952-64cd-4b02-9991-b596ae9b8c92",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20848.png?alt=media&token=a9b93115-58db-4cac-86b1-161839cd5742",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20851.png?alt=media&token=220d525a-72d5-4480-9be7-b98391a63a8d",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20853.png?alt=media&token=87f86d3c-a048-4a78-bd31-6f7115a91ce4",
];

export const certificateTypes = [
  {
    text: "Provisional Certificate",
    value: 1,
  },
  {
    text: "Mark sheet",
    value: 2,
  },
  {
    text: "Grade sheet",
    value: 3,
  },
  {
    text: "Course year certificate",
    value: 4,
  },
  {
    text: "Transcript",
    value: 5,
  },
];

export const courseYearNumbers = {
  "First Year": 1,
  "Second Year": 2,
  "Third Year": 3,
  "Fourth Year": 4,
  "Fifth Year": 5,
  "Sixth Year": 6,
  "Seventh Year": 7,
};

export const courseYearNumbersAbbr = {
  FY: 1,
  SY: 2,
  TY: 3,
};

export const courseInitialStMiras = {
  BCOM: "C",
  BA: "A",
  BSc: "S",
  "BBA (CA)": "T",
  BBA: "B",
  "M.Sc.": "MS",
  MCOM: "MC",
  MA: "MA"
};

export const currentCourseYearInitialStMiras = {
  FY: 1,
  SY: 2,
  TY: 3,
};

export const examCategories = [
  {
    text: "Regular Exam",
    value: 1,
    primaryColor: "#EFFFE3",
    secondaryColor: "#729C18",
  },
  {
    text: "Supplementary Exam",
    value: 2,
    primaryColor: "#F5CDFF",
    secondaryColor: "#73088E",
  },
  {
    text: "Backlog Exam",
    value: 3,
    primaryColor: "#FFF1CD",
    secondaryColor: "#967319",
  },
];

export const getExamCategoryString = (value) => {
  let string = "";

  if (!value) return;

  let tempString = examCategories.filter(
    (category) => category.value === value
  )[0];

  if (!tempString) return;

  string = tempString.text;

  return string;
};

export const getExamCategoryValue = (text) => {
  let type = 0;

  if (!text) {
    return;
  }

  let tempValue = examCategories.filter(
    (category) => category.text === text
  )[0];

  if (!tempValue) return;

  type = tempValue.value;

  return type;
};

export const getExamCategoryObject = (value) => {
  let obj = {};

  if (!value) {
    return;
  }

  let tempObj = examCategories.filter(
    (category) => category.value === value
  )[0];

  if (!tempObj) return;

  obj = tempObj;

  return obj;
};

export const getNoRuleText = () => {
  let noRuleText = {};
  examCategories.map((type) => {
    noRuleText[type.value] = `No ${type.text} Rules are added yet.`;
  });
  return noRuleText;
};

export const currentCourseYearInitialMima = {
  FY: "First Year",
  SY: "Second Year",
  TY: "Third Year",
};

// Storing these constant values as arrays because the component
//    will be used more frequently and will be more optimal. If not then use the value generator functions
export const minutesArray = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

export const hoursArray = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const generateNumberStrings = (start, end, padLength = 2) => {
  if (
    typeof start !== "number" ||
    typeof end !== "number" ||
    typeof padLength !== "number"
  ) {
    throw new TypeError(
      "Parameters 'start', 'end', and 'padLength' must all be numbers."
    );
  }

  // Ensure start is less than or equal to end
  if (start > end) {
    throw new RangeError("'start' should be less than or equal to 'end'.");
  }

  // Ensure padLength is a non-negative integer
  if (!Number.isInteger(padLength) || padLength < 0) {
    throw new RangeError("'padLength' must be a non-negative integer.");
  }

  // Generate the array of strings
  return Array.from({ length: end - start + 1 }, (_, i) =>
    (i + start).toString().padStart(padLength, "0")
  );
};

// const hours12 = generateNumberStrings(1, 12);      // ["01", "02", ..., "12"]
// const hours24 = generateNumberStrings(0, 23);      // ["00", "01", ..., "23"]
// const minutes = generateNumberStrings(0, 59);      // ["00", "01", ..., "59"]

// ==================================================MANAGE USERS=========================================================

export const userRoles = [
  {
    role: "ADMIN", //DONT CHANGE THIS VALUE
    text: "Admin",
    value: 2,
    primaryColor: "rgba(168, 115, 255, 1)",
  },
  {
    role: "FACULTY", //DONT CHANGE THIS VALUE
    text: "Faculty",
    value: 1,
    primaryColor: "rgba(255, 84, 84, 1)",
  },
  {
    role: "STUDENT", //DONT CHANGE THIS VALUE
    text: "Student",
    value: 0,
    primaryColor: "rgba(168, 217, 32, 1)",
  },
  {
    role: "SUB_ADMIN", //DONT CHANGE THIS VALUE
    text: "Sub Admin",
    value: 3,
    primaryColor: "rgb(115, 211, 255)",
  },
];
// Function to get the role by numeric value
export const getRoleByValue = (value = -1) => {
  const role = userRoles.find((role) => role.value === value);
  return role ? role.role : "UNKNOWN_ROLE";
};

// Function to get the numeric value by role
export const getValueByRole = (roleName = "") => {
  const role = userRoles.find((role) => role.role === roleName);
  return role ? role.value : "UNKNOWN_VALUE";
};

export const getRoleByIndex = (index = -1) => {
  const role = userRoles[index]
  return role ? role.role : "UNKNOWN_VALUE";
};

// Function to get the complete role object by numeric value
export const getRoleObjectByValue = (value = -1) => {
  const role = userRoles.find((role) => role.value === value);
  return (
    role || {
      role: "UNKNOWN",
      text: "Unknown",
      value: -1,
      primaryColor: "rgba(0, 0, 0, 1)",
    }
  );
};

export const emailRegex = /^[a-zA-Z0-9._%+#-]+@[a-zA-Z0-9.-]+[-#a-zA-Z0-9]+\.[a-zA-Z]{2,}$/
export const dateOfBirthRegex = /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \([^)]+\)$/ //DD-MM-YYYY