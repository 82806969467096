<template>
  <v-btn icon @click="copyText">
    <v-icon :color="theme.primary" :ripple="false" v-if="copied">{{
      icons.check
    }}</v-icon>
    <v-icon :color="theme.primary" :ripple="false" v-else>{{
      icons.copy
    }}</v-icon>
  </v-btn>
</template>

<script>
import { theme } from "../../../../Constants/Utils/Theme";
import { icons, validateIcon } from "../../../../Constants/Utils/Icons";
import showStatus from "../../../../NetworkManager/showStatus";

export default {
  props: {
    text: {
      type: [String, Number],
      required: true,
    },
    resetDelay: {
      type: Number,
      default: 2000, // Reset after 2 seconds
    },
  },
  data() {
    return {
      copied: false,
      theme,
      icons,
    };
  },
  methods: {
    copyText() {
      const textToCopy = this.text != null ? String(this.text) : ''; // Convert to string if null
      if (this.copied) {
        return; // Prevent re-copying while in "copied" state
      }
      if (!textToCopy) {
        console.warn("No valid text provided to copy.");
        showStatus("No valid text provided to copy.", 7000, "warning", this);
        return;
      }
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          this.copied = true;
          showStatus("Copied to clipboard!", this.resetDelay, "success", this);
          setTimeout(() => {
            this.copied = false;
          }, this.resetDelay);
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
        });
    },
  },
};
</script>
