<template src="./activityQuestionScale.html"></template>

  <script>
import SoloIcon from "../../../Components/styleGuide/Buttons/Icons/SoloIcon.vue";
import { v4 } from "uuid";
import showStatus from "../../../NetworkManager/showStatus";
import Feedback from "../../../Repository/Feedback.js";

export default {
  name: "activityQuestionScale",
  components: {
    SoloIcon,
  },
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      black: "#000000",
      activeTab: 0,
      allQuestions: [
        {
          questionStatement: "",
          scales: [],
          attachmentFiles: "",
          selectedCos: [],
          selectedPos: [],
          selectedPso: [],
          selectedBlooms: [],
        },
      ],
      allTypeOfQuestions: [
        // "Short Answer",
        // "Long Answer",
        // "Rating",
        "Single Select",
        "Multiple Select",
      ],
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)",
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)",
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)",
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)",
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)",
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)",
        },
      ],
      allPOs: [],
      allPso: [],
      attachmentFiles: [],
      headers: [
        { text: "#", value: "index", align: "start", sortable: false },
        { text: "Question", value: "questionStatement", sortable: false },
        { text: "Type", value: "selectedType", sortable: false },
        {
          text: "Scale/Option",
          value: "selectedScaleOrOption",
          sortable: false,
        },
        {
          text: "No. of Scales/Options",
          value: "noOfOptions",
          sortable: false,
        },
        { text: "Labels", value: "scaleLabel", sortable: false },
        { text: "Mapped PO", value: "selectedPos", sortable: false },
        { text: "Mapped PSO", value: "selectedPso", sortable: false },
        { text: "Mapped Blooms", value: "selectedBlooms", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      scaleOrOptions: ["Options", "Scale"],
      selectedTypeOfSurvey: "",
      createdFeedback: {},
      selectedSubject: 0,
      threshold: 0,
      tempQuestions: [],
      deleteErrorMessage: "",
      deleteIndex: "",
      deleteQuestionsArray: [],
      headersForQuestions: [],
      showAttachment: false,
      copyQuestionsModal: false,
      deleteQuestionsModal: false,
      copyFromSubject: "",
      isSaved: false,
      isSavedDialog: false,
      isNext: false,
      isTab: false,
      isThresholdEnable: null,
      isBackdated: false,
      isForAttainment: null,
      acceptAllScale: false,
      acceptAllType: false,
      applyAllCos: false,
      applyAllPo: false,
      applyAllPso: false,
    };
  },
  props: [
    "props_selectedFeedback",
    "props_isTab",
    "props_isTabActive",
    "props_isScaleChange",
    "props_isNextClick",
    "props_conduction",
  ],
  async created() {
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.createdFeedback = this.$store.getters["liveData/get_createdFeedback"];
    this.allPos = this.$store.getters["liveData/get_allPosForFeedback"];
    this.allPso = this.$store.getters["liveData/get_allPsosForFeedback"];
    this.feedbackRepositoryInstance = new Feedback(this);
    this.feedbackSelections =
      this.$store.getters["liveData/get_feedbackTopSelections"];
    this.isTab = this.props_isTab;
    this.isEdit = this.props_selectedFeedback._id !== undefined ? true : false;
    this.selectedTypeOfSurvey = this.createdFeedback.selectedTypeOfSurvey;

    this.threshold = this.createdFeedback?.threshold || "";
    this.tempQuestions =
      this.createdFeedback?.formType === "Course Feedback"
        ? this.createdFeedback.courses
        : this.createdFeedback.allQuestions;
    this.isBackdated =
      this.createdFeedback?.conductionDetails === "Backdated" ? true : false;
    // this.isForAttainment = this.createdFeedback.setting.isForNBA;
    this.conduction = this.props_conduction;

    if (
      this.createdFeedback?.feedbackId !== undefined ||
      this.createdFeedback?.isTemplateSelected
    ) {
      this.isFirstVisit = this.props_isTabActive;
    }
    this.isForAttainment = this.$store.getters["liveData/get_isForAttainment"];
  },
  watch: {
    async props_isNextClick() {
      // Skip if it's the first visit
      if (this.isFirstVisit || this.isFirstVisit === null) {
        this.isFirstVisit = false;
        return;
      }
      this.createdFeedback =
        this.$store.getters["liveData/get_createdFeedback"];

      this.tempQuestions = [];
      this.threshold = this.createdFeedback?.threshold || "";
      this.isForAttainment =
        this.$store.getters["liveData/get_isForAttainment"];
      this.tempQuestions =
        this.createdFeedback?.formType === "Course Feedback"
          ? this.createdFeedback.courses
          : this.createdFeedback.allQuestions;
    },
    async props_isTab() {
      // Skip if it's the first visit
      if (this.isFirstVisit || this.isFirstVisit === null) {
        this.isFirstVisit = false;
        this.createdFeedback =
          this.$store.getters["liveData/get_createdFeedback"];
        return;
      }
      this.createdFeedback =
        this.$store.getters["liveData/get_createdFeedback"];
      this.tempQuestions = [];
      this.threshold = this.createdFeedback?.threshold || 2;
      this.isForAttainment =
        this.$store.getters["liveData/get_isForAttainment"];
      this.tempQuestions =
        this.createdFeedback?.formType === "Course Feedback"
          ? this.createdFeedback.courses
          : this.createdFeedback.allQuestions;
    },
  },
  computed: {
    isThresholdEmpty() {
      if (!this.createdFeedback?.setting?.isForNBA) {
        return true; // Threshold is not required if isForNBA is false
      }
      const { formType, courses } = this.createdFeedback || {};
      if (formType === "Course Feedback") {
        return this.validateThreshold(
          courses?.[this.selectedSubject]?.threshold
        );
      } else {
        return this.validateThreshold(this.threshold);
      }
    },
  },
  methods: {
    getHeaders(subject) {
      const baseHeaders = [
        { text: "Index", value: "index", sortable: false },
        { text: "Question", value: "questionStatement", sortable: false },
        { text: "Type", value: "selectedType", sortable: false },
        {
          text: "Scale/Option",
          value: "selectedScaleOrOption",
          sortable: false,
        },
        { text: "No. of Options/Scale", value: "noOfOptions", sortable: false },
        { text: "Labels", value: "scaleLabel", sortable: false },
        {
          text: "Mapped COs",
          value: "selectedCos",
          sortable: false,
        },
        { text: "Mapped Blooms", value: "selectedBlooms", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ];
      if (subject.showAttachment) {
        baseHeaders.splice(6, 0, {
          text: "Attachment",
          value: "attachmentFiles",
          sortable: false,
        });
      }
      return baseHeaders;
    },
    handleShowAttachment(subject) {
      if (this.createdFeedback?.formType !== "Course Feedback") {
        const attachmentColumnIndex = this.headers.findIndex(
          (header) => header.value === "attachmentFiles"
        );

        if (this.showAttachment) {
          if (attachmentColumnIndex === -1) {
            this.headers.splice(6, 0, {
              text: "Attachment",
              value: "attachmentFiles",
              sortable: false,
            });
          }
        } else {
          if (attachmentColumnIndex !== -1) {
            this.headers.splice(attachmentColumnIndex, 1);
          }
        }
      }
    },
    removeNegativeSign(threshold) {
      if (threshold < 0) {
        threshold = 0;
      }
    },
    validateThreshold(threshold) {
      if (typeof threshold === "number") {
        return threshold > 0;
      } else if (typeof threshold === "string") {
        return !isNaN(threshold) && Number(threshold) > 0;
      }
      return false; // If it's neither a number nor a valid numeric string
    },
    validateScale(item) {
      if (item.scaleLowest > item.scaleHighest) {
        item.errorMsg = "Invalid range";
      } else {
        item.errorMsg = "";
        this.addOptions(item);
      }
    },
    handleSelectedType(item) {
      if (item?.scales?.selectedType === "Single Select") {
        item.scales.selectedScaleOrOption = "Scale";
      } else {
        item.scales.selectedScaleOrOption = "Options";
      }
    },
    addOptions(item, i) {
      let arr = [];
      let length = Number(item?.scales?.noOfOptions);
      let selectedNo = item?.scales?.noOfOptions;
      if (length <= 10) {
        if (selectedNo && selectedNo >= (item?.scales.scale?.length || 0)) {
          for (let j = item?.scales.scale?.length || 0; j < length; j++) {
            arr.push({
              scaleNumber: j + 1,
              scaleLabel: "",
            });
          }
          item.scales.scale = item.scales.scale.concat(arr);
        } else {
          item.scales.scale = item.scales.scale.slice(0, length);
        }
      } else {
        showStatus("Range should be less than 10", 2000, "error", this);
      }
    },
    addRow(subject) {
      if (this.createdFeedback.formType === "Course Feedback") {
        subject.allQuestions.push({
          questionId: v4(),
          questionNumber: `Q${subject.allQuestions?.length + 1}`,
          questionStatement: "",
          attachmentFiles: "",
          selectedCos: [],
          selectedPos: [],
          selectedPso: [],
          selectedBlooms: [],
          scales: {
            scale: [],
            selectedType: "",
            selectedScaleOrOption: "",
            noOfOptions: "",
          },
        });
      } else {
        this.tempQuestions.push({
          questionId: v4(),
          questionNumber: `Q${this.tempQuestions?.length + 1}`,
          questionStatement: "",
          attachmentFiles: "",
          selectedCos: [],
          selectedPos: [],
          selectedPso: [],
          selectedBlooms: [],
          scales: {
            scale: [],
            selectedType: "",
            selectedScaleOrOption: "",
            noOfOptions: "",
          },
        });
      }
    },
    // checkQuestionArePresent(allQuestions) {
    //   if (allQuestions.length === 0) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // },
    // validateQuestions() {
    //   if (this.createdFeedback?.formType === "Course Feedback") {
    //     const result = this.createdFeedback?.courses?.every((obj) =>
    //       this.checkQuestionArePresent(obj.allQuestions)
    //     );

    //     return result;
    //   } else {
    //     const result = this.checkQuestionArePresent(
    //       this.createdFeedback.allQuestions
    //     );
    //     return result;
    //   }
    // },
    validateQuestions() {
      const checkQuestions = (questions) =>
        questions?.length > 0 &&
        questions.every(
          (q) =>
            q.questionStatement?.trim() !== "" && // Check if questionStatement is not empty
            q.scales?.scale?.length > 0 // Check if scales.scale is not empty
        );

      if (this.createdFeedback?.formType === "Course Feedback") {
        return this.createdFeedback?.courses?.every((obj) =>
          checkQuestions(obj.allQuestions)
        );
      }
      return checkQuestions(this.createdFeedback?.allQuestions);
    },
    async createQuestionArray() {
      if (this.createdFeedback.formType !== "Course Feedback") {
        this.createdFeedback.allQuestions = this.tempQuestions;
      }
      const isValid = this.validateQuestions();
      if (isValid) {
        this.addQuestionToFeedback();
      } else {
        this.isSavedDialog = true;
      }
    },
    selectAllScaleLabel(subject) {
      const isCourseFeedback =
        this.createdFeedback?.formType === "Course Feedback";
      const targetArray = isCourseFeedback ? subject.allQuestions : subject;
      const acceptAllScale = isCourseFeedback
        ? subject.acceptAllScale
        : this.acceptAllScale;

      if (targetArray.length > 0) {
        const firstItemScales = JSON.parse(
          JSON.stringify(targetArray[0].scales)
        );

        targetArray.forEach((obj, i) => {
          if (i > 0) {
            if (acceptAllScale) {
              obj.scales.scale = [...firstItemScales.scale];
              obj.scales.noOfOptions = firstItemScales.noOfOptions;
            } else {
              obj.scales.scale = [];
              obj.scales.noOfOptions = "";
            }
          }
        });
      }
    },
    applyAllType(subject) {
      const isCourseFeedback =
        this.createdFeedback?.formType === "Course Feedback";
      const targetArray = isCourseFeedback ? subject.allQuestions : subject;

      const acceptAllType = isCourseFeedback
        ? subject.acceptAllType
        : this.acceptAllType;

      if (targetArray.length > 0) {
        const firstItemScales = JSON.parse(
          JSON.stringify(targetArray[0].scales)
        );

        targetArray.forEach((obj, i) => {
          if (i > 0) {
            if (acceptAllType) {
              obj.scales.selectedType = firstItemScales.selectedType;
              obj.scales.selectedScaleOrOption =
                firstItemScales.selectedScaleOrOption;
            } else {
              obj.scales.selectedType = [];
              obj.scales.selectedScaleOrOption = "";
            }
          }
        });
      }
    },
    selectAllMappedPo(targetArray) {
      if (targetArray.length === 0) return;

      const firstSelectedPos = targetArray[0].selectedPos;

      targetArray.forEach((obj, i) => {
        if (i > 0) {
          if (this.applyAllPo) {
            obj.selectedPos = firstSelectedPos;
          } else {
            obj.selectedPos = [];
          }
        }
      });
    },
    selectAllMappedPso(targetArray) {
      if (targetArray.length === 0) return;
      const firstSelectedPsos = targetArray[0].selectedPso;
      targetArray.forEach((obj, i) => {
        if (i > 0) {
          if (this.applyAllPso) {
            obj.selectedPso = firstSelectedPsos;
          } else {
            obj.selectedPso = [];
          }
        }
      });
    },
    selectAllMappedCo(subject) {
      if (subject.allQuestions.length === 0) return;

      const targetArray = subject.allQuestions;
      const firstSelectedCos = targetArray[0].selectedCos;

      targetArray.forEach((obj, i) => {
        if (i > 0) {
          if (subject.applyAllCos) {
            obj.selectedCos = firstSelectedCos;
          } else {
            obj.selectedCos = [];
          }
        }
      });
    },
    async addQuestionToFeedback() {
      try {
        let res = {};
        const objToPush = {
          instituteId: this.createdFeedback.instituteId,
          semId: this.createdFeedback.semId,
          conductionDetails: this.createdFeedback.conductionDetails,
          courseYear: this.createdFeedback.courseYear,
          divisions: this.createdFeedback.divisions,
          department: this.createdFeedback.department,
          startDate: this.createdFeedback.startDate,
          endDate: this.createdFeedback.endDate,
          extendedDate: this.createdFeedback.extendedDateTime,
          stakeholder: this.createdFeedback.stakeholder,
          formDescription: this.createdFeedback.formDescription,
          formTitle: this.createdFeedback.formTitle,
          formType: this.createdFeedback.formType,
          formURL: this.createdFeedback.formURL,
          responseType: this.createdFeedback.responseType,
          selectedTypeOfSurvey: this.createdFeedback.selectedTypeOfSurvey,
          setting: this.createdFeedback.setting,
          stakeHolderDetails: this.createdFeedback.stakeHolderDetails,
          status: this.createdFeedback.status
            ? this.createdFeedback.status
            : "Draft",
        };
        if (this.createdFeedback?.formType === "Course Feedback") {
          objToPush["courses"] = this.tempQuestions;
        } else {
          objToPush["threshold"] = this.threshold || "";
          objToPush["allQuestions"] = this.createdFeedback.allQuestions;
        }
        if (this.isSaved) {
          if (this.createdFeedback.feedbackId !== undefined) {
            objToPush["feedbackId"] = this.createdFeedback.feedbackId;
            res = await this.feedbackRepositoryInstance.updateFeedback(
              objToPush
            );
          } else {
            objToPush["feedbackId"] = this.createdFeedback.formURL;
            res = await this.feedbackRepositoryInstance.createFeedback(
              objToPush
            );
          }
          if (res.status === 200) {
            this.$store.commit("liveData/set_createdFeedback", objToPush);
            showStatus("Successfully saved .", 2000, "success", this);
            this.isDataSaved = true;
            this.isNext = false;
            this.isSaved = false;
            this.isTab = false;
          } else {
            showStatus("Something went wrong", 2000, "error", this);
          }
          this.createdFeedback =
            this.$store.getters["liveData/get_createdFeedback"];
        } else {
          let obj =
            this.createdFeedback.feedbackId !== undefined
              ? this.createdFeedback
              : objToPush;
          await this.goToNextPage(obj);
        }

        this.isSavedDialog = false;
      } catch (e) {
        console.error(e);
      }
    },
    async goToNextPage(objToPush) {
      this.$store.commit("liveData/set_createdFeedback", objToPush);

      if (this.isNext) {
        this.$emit("nextButtonClick");
      }
    },
    handleDeleteQuestionModal(index, allQuestions) {
      this.deleteIndex = index;
      this.deleteQuestionsArray = allQuestions;

      this.deleteQuestionsModal = true;
    },
    deleteQuestion(index, tempQuestions) {
      tempQuestions.splice(index, 1);
      this.deleteQuestionsModal = false;
    },
    handleCopyQuestionsModal(subject) {
      this.copyQuestionsModal = true;
      this.copiedSubjectDetail = subject;
    },
    handleCopyFromSubject() {
      if (this.copyFromSubject?.allQuestions?.length) {
        this.copiedSubjectDetail.allQuestions =
          this.copyFromSubject.allQuestions.flatMap((obj) => {
            const updatedObj = { ...obj };
            const newItems = [];

            updatedObj.questionId = v4();
            newItems.push({ ...updatedObj });

            return newItems;
          });

        showStatus(
          `${this.copyFromSubject.subjectName} data copied successfully.`,
          2000,
          "success",
          this
        );
      } else {
        showStatus(`No data is present to copy.`, 2000, "error", this);
      }
      this.copyQuestionsModal = false;
      this.copiedSubjectDetail = {};
      this.copyFromSubject = "";
    },
  },
};
</script>
  
<style src="./activityQuestionScale.css" scoped></style>

  