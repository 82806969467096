<template>
  <div>
    <!-- Show Skeleton Loader when loading -->
    <v-skeleton-loader
      v-if="loading"
      :type="type"
      :width="width"
      :height="height"
    />

    <!-- Render the slot or default content when loading is false -->
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: "SkeletonLoader",
  props: {
    type: {
      type: String,
      default: "text", // Default to text skeleton
    },
    loading: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "100%", // Default to full width
    },
    height: {
      type: String,
      default: "auto", // Default to auto height
    },
  },
};
</script>

<style scoped>
/* Add specific styles if needed */
</style>
