import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/SemesterUser'

class SemesterUser {
  constructor(context) {
    this.context = context
  }

  async getSemUserWithNameForAttendanceAnalysis(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemUserWithNameForAttendanceAnalysis, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getCurrentSemesterUserForAUser(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getCurrentSemesterUserForAUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUser
  }

  async getFacultiesForDepartmentWithNameAndUserName(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFacultiesForDepartmentWithNameAndUserName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
  async getStudentForDepartmentWithNameAndUserName(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentForDepartmentWithNameAndUserName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
  async generateExcelUpdatePRN(data) {
    // console.log('front end');
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateExcelUpdatePRN, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getSemesterUsersOfASubjectOfAGroup(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersOfASubjectOfAGroup, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
  async generateExcelRegisterStudentToCourse(data) {
    // console.log('front end');
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateExcelRegisterStudentToCourse, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async generateExcelAddStudentsToGroup(data) {
    // console.log('front end');
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateExcelAddStudentsToGroup, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  // async getSemesterUsersOfASubjectOfADivision(data) {
  //   this.context.$store.commit('liveData/set_loading', true)
  //   const response = await networkManager.getRequest(ApiNames.getSemesterUsersOfASubjectOfADivision, data)
  //   this.context.$store.commit('liveData/set_loading', false)
  //   return response.semesterUsers
  // }
  async getSemesterUsersOfASubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
  async getAllSemUserOfInstitute(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllSemUserOfInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
  
  async getSemesterUsersForMultipleDivision (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersForMultipleDivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
  async getSemesterUsers (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsers, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfMultipleSemesters(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getStudentsOfMultipleSemesters, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.result
  }

  async getStudentsByInstituteAndDepartment(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsByInstituteAndDepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getSemesterUser(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getMultipleSemesterUsers(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getMultipleSemesterUsers, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getSemesterUserForACourseYear(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUserForACourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUser
  }

  async getUsersNotInAnySemester(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getUsersNotInAnySemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.uIds
  }

  async createMultipleSemesterUsers(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createMultipleSemesterUsers, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async createSemesterUser(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createSemesterUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUser
  }

  async getFacultiesOfADepartment(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFacultiesOfADepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getFacultyAllocation(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFacultyAllocation, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.result
  }

  async getStudentAllocation(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getStudentAllocation, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.result
  }

  async getAllFacultiesOfADepartment(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllFacultiesOfADepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getFacultiesOfAInstitute(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFacultiesOfAInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
  async getAllFacultiesOfAInstituteByInstituteId(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllFacultiesOfAInstituteByInstituteId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfADivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfSubjects(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfSubjects, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfACourseYear(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfACourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfACourseYear(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfACourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfADepartment(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfADepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfAInstitute(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfAInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
  async getExamSeatNumberAndRollNumberOfMultipleSemUser(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamSeatNumberAndRollNumberOfMultipleSemUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.rollNumbers
  }

  async updateDepartment(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateDepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.department
  }
  async resetStudentResultData(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.resetStudentResultData, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async removeSubjectObjectFromSubjectsArray(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.removeSubjectObjectFromSubjectsArray, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async pushSubjectObjectToSubjectsArray(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.pushSubjectObjectToSubjectsArray, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async removeBatchFromSubjectsAndTimetable(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.removeBatchFromSubjectsAndTimetable, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateCourseYear(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateCourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.courseYear
  }

  async updateSemesterUserResultDetails(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSemesterUserResultDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateDivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateDivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.division
  }

  async updateBatch(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateBatch, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.batch
  }

  async updateRollNumber(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateRollNumber, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.rollNumber
  }

  async updateExamSeatNumber(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateExamSeatNumber, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examSeatNumber
  }

  async updateSubjectsOfAStudentInASemester(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSubjectsOfAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }

  async updateSemesterUser(data) {
    console.log('data1', data)
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSemesterUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async importFaculties(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.importFaculties, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async importStudentsOfADivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.importStudentsOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async deleteSemesterUser(data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deleteSemesterUser, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async deleteMultipleSemUser(data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deleteMultipleSemUser, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
  async bulkDeleteSemesterUsers(data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.bulkDeleteSemesterUsers, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getSubjectsOfASemesterUser(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectsOfASemesterUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }

  async getSemesterUsersForAUser(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersForAUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getSemesterUsersOfASubjectOfADivision(data) {
    console.log('ghjk')
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersOfASubjectOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getSemesterUsersOfASubjectOfAGroup (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersOfASubjectOfAGroup, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
  async getSubjectsByUId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectsByUId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async temp (data) {
    console.log('data', data);
    console.log('kjsdbvhkbsd');
  }
}

export default SemesterUser
