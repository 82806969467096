<template>
  <v-hover v-slot:default="{ hover }">
    <div class="d-flex align-center">
      <span>{{ displayText }}</span>
      <CopyButton v-if="hover && displayText" :text="displayText" />
    </div>
  </v-hover>
</template>

<script>
import CopyButton from "./CopyToClipboard.vue"; // Ensure the path is correct

export default {
  components: {
    CopyButton,
  },
  props: {
    text: {
      type: [String, Number],
      default: "", // Avoids undefined/null issues
    },
  },
  computed: {
    displayText() {
      return this.text != null ? String(this.text) : ""; // Ensures text is always a string
    },
  },
};
</script>
