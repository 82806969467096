<template src="./activityBasicDetails.html"></template>

<script>
import showStatus from "../../../NetworkManager/showStatus";
import NewFeedbackFormRepository from "../../../Repository/Feedbackform";
import moment from "moment-timezone";
import Feedback from "../../../Repository/Feedback.js";
import nbaRepository from "../../../Repository/NBA.js";
import { v4 as uuidv4 } from "uuid";
// import DateTimePicker from "../../../Components/styleGuide/InputsAndControls/DateTimePicker.vue";

export default {
  name: "activityBasicDetails",
  // components: {
  //   DateTimePicker,
  // },
  data() {
    return {
      // tempTime:"",
      time: null, //to solve time
      chevronIcon: "mdi-chevron-down",
      backgroundColor: "#E7E7FFCF",
      progressIndicator: 0,
      uploadBasicDetailsViaExcel: false,
      title: "",
      description: "",
      conductionType: "",
      responseType: "Non Anonymous",
      startDateModal: false,
      startDate: null,
      startTimeModal: null,
      startTime: null,
      endDateModal: false,
      endDate: null,
      endTimeModal: null,
      endTime: null,
      extendedDateModal: false,
      extendedDate: null,
      extendedTimeModal: null,
      extendedTime: null,
      typesOfSurvey: [],
      selectedTypeOfSurvey: "",
      allCOS: [],
      selectedCos: [],
      allPOs: [],
      allPSOs: [],
      selectedPos: [],
      isForAttainment: false,
      isSingleForAllCourse: false,
      feedbackSelections: {},
      headers: [
        { text: "Sr No", cols: 1 },
        { text: "Details", cols: 6 },
        { text: "Type", cols: 4 },
        { text: "Action", cols: 1 },
      ],
      allFeedbackDetails: [],
      allStakeholderDetails: [
        {
          stakeholder: "Students",
          details: [
            {
              srNo: 1,
              details: "Student Name",
              type: "Compulsory",
            },
          ],
        },
        {
          stakeholder: "Faculty",
          details: [
            {
              srNo: 1,
              details: "Faculty Name",
              type: "Compulsory",
            },
          ],
        },
      ],
      allTypes: ["Compulsory", "Optional"],
      templates: [],
      selectedTemplate: {},
      allQuestions: [],
      tempAllQuestions: [],
      subjectWiseCos: [],
      tempFeedbackId: "",
      allCourseOutcome: [],
      allProgramOutcome: [],
      allProgramSpecificOutcome: [],
      feedbackURL: "",
      isCOExist: false,
      progressPercentage: 0,
      isNext: false,
      feedbackId: "",
      conduction: "",
      isEdit: false,
      isFirstVisit: null,
      rules: {
        required: (value) => !!value || "This field is required",
        totalMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
        passingMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
      },
    };
  },
  props: [
    "props_selectedFeedback",
    "props_selections",
    "props_mainTabNames",
    "props_isTab",
    "props_isTabActive",
    "props_isScaleChange",
    "props_isScaleChangeForBasic",
    "props_isNextClick",
    "courseList",
    "props_conduction",
  ],
  mounted() {
    if (!this.tempFeedbackId) {
      if (this.typesOfSurvey.length > 0) {
        this.selectedTypeOfSurvey = this.typesOfSurvey[0];
      }
      this.conductionType = "Upcoming";
      this.isSingleForAllCourse = true;
      this.handleStakehoderDetails();
    }
  },
  async created() {
    this.NewFeedbackFormRepositoryInstance = new NewFeedbackFormRepository(
      this
    );
    this.nbaRepositoryInstance = new nbaRepository(this);
    this.feedbackRepositoryInstance = new Feedback(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.feedbackSelections =
      this.$store.getters["liveData/get_feedbackTopSelections"];
    this.createdFeedback = this.$store.getters["liveData/get_createdFeedback"];
    this.selectedLevel = this.$store.getters["liveData/get_selectedLevel"];
    this.selectedBatch = this.$store.getters["liveData/set_selectedBatch"];
    this.selectedAcademicYear =
      this.$store.getters["liveData/set_selectedAcademicYear"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.tempFeedbackId =
      this.props_selectedFeedback?.feedbackId !== undefined ? true : false;
    this.isEdit = this.props_selectedFeedback._id !== undefined ? true : false;
    this.createdFeedback = this.props_selectedFeedback;

    this.selectedTypeOfSurvey = this.handleMapCOsPos();
    if (this.tempFeedbackId) {
      this.storeValues();
      this.updateParent();
    }
    const feedbackType = this.props_selections.selectedTypeOfFeedback;
    this.typesOfSurvey =
      feedbackType === "Course Feedback"
        ? ["COs"]
        : feedbackType === "Program Feedback"
        ? ["POs/PSOs"]
        : ["COs", "POs/PSOs"];

    await this.getTemplates();
    this.isFirstVisit = this.props_isTabActive;
  },
  watch: {
    async props_isNextClick() {
      if (this.props_isScaleChangeForBasic && !this.isEdit) {
        this.createdFeedback =
          this.$store.getters["liveData/get_createdFeedback"];
        this.createdFeedback.feedbackId = this.createdFeedback.formURL;
        const feedback = await this.getFeedbackById();

        feedback.tempQuestions = feedback.courses || feedback.allQuestions;
        feedback.coursesWiseQuestions =
          feedback.courses || feedback.allQuestions;
        feedback.coursesForScale = feedback.courses || feedback.allQuestions;

        await this.$store.commit("liveData/set_createdFeedback", feedback);
        this.handleScaleChangeForBasic(false);
      }
    },
    async props_isTab() {
      if (this.props_isScaleChangeForBasic && this.isEdit) {
        const feedback = await this.getFeedbackById();

        feedback.tempQuestions = feedback.courses || feedback.allQuestions;
        feedback.coursesWiseQuestions =
          feedback.courses || feedback.allQuestions;
        feedback.coursesForScale = feedback.courses || feedback.allQuestions;

        await this.$store.commit("liveData/set_createdFeedback", feedback);

        this.handleScaleChangeForBasic(false);
      }
    },
  },
  methods: {
    handleScaleChangeForBasic(value) {
      this.$emit("update-Scale-basic", value);
    },
    updateParent() {
      this.$emit("update-parent", {
        conductionType:
          this.conductionType || this.createdFeedback.conductionDetails,
        isFeedbackSaved: this.tempFeedbackId,
        status: this.createdFeedback.status,
      });
    },
    handleStakehoderDetails() {
      const { details } = this.allStakeholderDetails.find(
        (obj) => obj.stakeholder === "Students"
      );
      this.allFeedbackDetails = details;
      return details;
    },
    calculateProgress() {
      const totalFields = 6;
      const conditions = [
        this.title !== "",
        this.description !== "",
        this.startDate !== null,
        this.endDate !== null,
        this.responseType !== "",
        this.selectedTypeOfSurvey !== "",
      ];

      const filledFields = conditions.filter((condition) => condition).length;

      this.progressIndicator = (filledFields / totalFields) * 100;
    },
    storeValues() {
      const {
        formTitle = "",
        formDescription = "",
        conductionDetails = "",
        startDate = "",
        endDate = "",
        extendedDate = "",
        responseType = "",
        setting: { isForNBA = false, isSingleForAllCourse = false } = {},
        feedbackURL = "",
        stakeHolderDetails,
      } = this.createdFeedback || {};

      this.title = formTitle;
      this.description = formDescription;
      this.conductionType = conductionDetails;
      this.responseType = responseType;
      this.isForAttainment = isForNBA;
      this.isSingleForAllCourse = isSingleForAllCourse;
      this.feedbackURL = feedbackURL;
      this.allFeedbackDetails =
        stakeHolderDetails?.map((details, index) => ({
          srNo: index + 1,
          details: Object.keys(details)[0],
          type: Object.values(details)[0],
        })) || [];

      if (conductionDetails === "Upcoming") {
        const startDT = this.convertDateTimeToUTCFormat(startDate);
        const endDT = this.convertDateTimeToUTCFormat(endDate);
        this.startDate = startDT.date;
        this.startTime = startDT.time;
        this.endDate = endDT.date;
        this.endTime = endDT.time;
        if (extendedDate !== null) {
          const extendedDT = this.convertDateTimeToUTCFormat(extendedDate);
          this.extendedDate = extendedDT.date;
          this.extendedTime = extendedDT.time;
        }
      } else {
        this.startDate = startDate;
        this.endDate = endDate;
      }
      this.$store.commit("liveData/set_isForAttainment", isForNBA);
      this.$store.commit("liveData/set_createdFeedback", this.createdFeedback);
    },
    async handleTemplateData() {
      if (this.selectedTemplate.templateName === "None") {
        this.handleStakehoderDetails();
        // this.selectedTemplate.allQuestions = [];
        this.allQuestions = [];
      } else {
        this.allFeedbackDetails =
          this.selectedTemplate?.stakeHolderDetails?.map((details, index) => ({
            srNo: index + 1,
            details: Object.keys(details)[0],
            type: Object.values(details)[0],
          })) || [];
        let questions =
          this.selectedTemplate?.allQuestions[0]?.question[0]?.question;
        // this.selectedTemplate.allQuestions = questions;
        this.allQuestions = questions;
      }
    },
    convertDateTimeToUTCFormat(localDateOrUTC, localTime = null) {
      try {
        let dateObj;

        if (localTime) {
          // Combine the local date and time if both are provided
          const localDateTime = `${localDateOrUTC} ${localTime}`;
          dateObj = new Date(localDateTime);

          // Check if the Date object is valid
          if (isNaN(dateObj.getTime())) {
            throw new Error(
              "Invalid date or time format. Please use 'YYYY-MM-DD' for the date and 'HH:MM' for the time."
            );
          }

          // Convert the Date object to a UTC string
          const utcDateTime = dateObj.toISOString();

          // Format the UTC string to the desired format
          return utcDateTime.replace(/\.\d{3}Z$/, "Z");
        } else {
          // If only one argument is passed, assume it is in UTC and parse it
          dateObj = new Date(localDateOrUTC);

          // Check if the Date object is valid
          if (isNaN(dateObj.getTime())) {
            throw new Error(
              "Invalid date format. Please use 'YYYY-MM-DDTHH:MM:SSZ' or similar format."
            );
          }

          // Convert the UTC date to local date and time
          const localDate = dateObj.toLocaleDateString("en-CA"); // Format: YYYY-MM-DD
          const localTime = dateObj.toLocaleTimeString("en-US", {
            hour12: false,
          }); // Format: HH:MM:SS

          return { date: localDate, time: localTime };
        }
      } catch (error) {
        return `Error: ${error.message}`;
      }
    },
    async validateFields() {
      if (this.title === "") {
        showStatus("Title is required", 2000, "warning", this);
        return false;
      }
      if (this.conductionType === "") {
        showStatus("Conduction Type is required", 2000, "warning", this);
        return false;
      }
      if (this.responseType === "") {
        showStatus("Response Type is required", 2000, "warning", this);
        return false;
      }
      if (this.selectedTypeOfSurvey === "") {
        showStatus("Type of Survey is required", 2000, "warning", this);
        return false;
      }
      if (this.startDate === null) {
        showStatus("Start Date is required", 2000, "warning", this);
        return false;
      }
      if (this.endDate === null) {
        showStatus("End Date is required", 2000, "warning", this);
        return false;
      }
      if (new Date(this.endDate) < new Date(this.startDate)) {
        showStatus(
          "End Date must be later than Start Date",
          2000,
          "warning",
          this
        );
        return false;
      }
      if (
        this.conductionType === "Upcoming" &&
        (this.startTime === null || this.endTime === null)
      ) {
        showStatus(
          "Start Time and End Time are required for Upcoming surveys",
          2000,
          "warning",
          this
        );
        return false;
      }

      return true;
    },
    async savedDetails() {
      const isSaved = await this.validateFields();
      if (isSaved) {
        if (this.isSaved && !this.isNext) {
          await this.saveBasicDetails();
        } else {
          await this.goToNextPage();
        }
      }
    },
    handleMapCOsPos() {
      let type = "";
      if (this.createdFeedback.formType === "Course Feedback") {
        this.selectedTypeOfSurvey = "COs";
        type = "COs";
      } else if (this.createdFeedback.formType === "Program Feedback") {
        this.selectedTypeOfSurvey = "POs/PSOs";
        type = "POs/PSOs";
      } else {
        this.selectedTypeOfSurvey = "";
        type = "";
      }
      return type;
    },
    handleIsForAttainment() {
      this.$store.commit("liveData/set_isForAttainment", this.isForAttainment);
    },
    addStakeholder() {
      let length = this.allFeedbackDetails?.length + 1;
      let obj = {
        srNo: length,
        details: "",
        type: "",
      };
      this.allFeedbackDetails.push(obj);
    },
    removeStakeholder(index) {
      this.allFeedbackDetails.splice(index, 1);
      for (let i = index; i < this.allFeedbackDetails.length; i++) {
        this.allFeedbackDetails[i].srNo = i + 1;
      }
    },
    async commomObjToPush() {
      let allCos = [];
      let allPos = [];
      let allPSOs = [];
      let feedbackId = "";
      let startDateTime = "";
      let endDateTime = "";
      let extendedDateTime = "";
      let isEdit = Object.keys(this.createdFeedback).length > 0 ? true : false;
      let isTemplateSelected =
        this.selectedTemplate?.templateId !== null
          ? Boolean(this.selectedTemplate?.templateId)
          : false;

      if (this.selectedTypeOfSurvey === "COs") {
        allCos = await this.getCourseOutcome();
        this.$store.commit("liveData/set_allPosForFeedback", allCos);
      } else {
        const { allPos, allPSOs } = await this.getProgramOutcome();
        this.$store.commit("liveData/set_allPosForFeedback", allPos);
        this.$store.commit("liveData/set_allPsosForFeedback", allPSOs);
      }
      if (this.createdFeedback.feedbackId !== undefined) {
        this.tempFeedbackId =
          this.createdFeedback.feedbackId !== "" ? true : false;
        feedbackId = this.createdFeedback.feedbackId;
      } else {
        this.tempFeedbackId = this.feedbackId !== "" ? true : false;
        feedbackId = this.feedbackId;
      }
      const {
        selectedSubjectIds,
        semester,
        department,
        courseYear,
        selectedTypeOfFeedback,
        selectedFeedbackFor,
        divisions,
      } = this.feedbackSelections;

      const {
        courses: fallbackCourses,
        divisions: fallbackDivisions,
        semId: fallbackSemId,
        department: fallbackDepartment,
        courseYear: fallbackCourseYear,
        formType: fallbackFormType,
        stakeholder: fallbackStakeholder,
        allQuestions: fallbackAllQuestions,
        threshold: threshold,
        status: status,
      } = this.createdFeedback || {};

      let stakeholder =
        fallbackStakeholder !== undefined
          ? fallbackStakeholder
          : selectedFeedbackFor;

      if (this.conductionType === "Upcoming") {
        startDateTime = this.convertDateTimeToUTCFormat(
          this.startDate,
          this.startTime
        );
        endDateTime = this.convertDateTimeToUTCFormat(
          this.endDate,
          this.endTime
        );
        extendedDateTime =
          this.extendedDate !== null && this.extendedTime !== null
            ? this.convertDateTimeToUTCFormat(
                this.extendedDate,
                this.extendedTime
              )
            : null;
      }
      // selectedSubjectIds
      const courses = this.courseList?.map((subject) => {
        let matchingCos = [];

        if (Array.isArray(allCos) && allCos?.length > 0) {
          matchingCos = allCos
            .filter((cos) => cos.subjectId === subject.subjectId)
            .map((cosObj) => cosObj.cos)
            .flat(); // Flatten the array at once
        }

        return {
          subjectId: subject.subjectId,
          subjectName: subject.subjectName,
          threshold: "",
          allQuestions: isTemplateSelected ? this.allQuestions : [],
          cos: matchingCos,
        };
      });
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: semester?.semId || fallbackSemId,
        department: department || fallbackDepartment,
        courseYear: courseYear || fallbackCourseYear,
        courses: isEdit ? fallbackCourses : courses,
        divisions: isEdit ? fallbackDivisions : divisions,
        formType: selectedTypeOfFeedback || fallbackFormType,
        formURL: feedbackId ? feedbackId : uuidv4(),
        stakeholder: stakeholder,
        formTitle: this.title,
        formDescription: this.description,
        conductionDetails: this.conductionType,
        responseType: this.responseType,
        selectedTypeOfSurvey: this.selectedTypeOfSurvey,
        threshold: threshold ? threshold : "",
        stakeHolderDetails: this.allFeedbackDetails
          .filter((feedback) => feedback.details && feedback.type)
          .map((feedback) => ({ [feedback.details]: feedback.type })),
        setting: {
          isForNBA: this.isForAttainment,
          isSingleForAllCourse: this.isSingleForAllCourse,
        },
        startDate:
          this.conductionType === "Upcoming" ? startDateTime : this.startDate,
        endDate:
          this.conductionType === "Upcoming" ? endDateTime : this.endDate,
        selectedTemplate: this.selectedTemplate,
        isTemplateSelected: isTemplateSelected,
        pos: allPos?.length > 0 ? allPos : [],
        psos: allPSOs?.length > 0 ? allPSOs : [],
        status: status ? status : "Draft",
      };
      if (fallbackAllQuestions?.length > 0 || fallbackCourses?.length > 0) {
        const cosMap = allCos
          ? Object.fromEntries(allCos.map((obj) => [obj.subjectId, obj.cos]))
          : {};

        fallbackCourses?.forEach((course) => {
          course.cos = cosMap[course.subjectId] || [];
        });
        objToPush.allQuestions = fallbackAllQuestions;
      } else {
        objToPush.allQuestions = [];
      }

      if (this.conductionType === "Upcoming") {
        objToPush.extendedDate = extendedDateTime;
      }
      if (this.tempFeedbackId) {
        objToPush.feedbackId = feedbackId;

        const tempQuestionsSource =
          this.createdFeedback.formType === "Course Feedback"
            ? this.createdFeedback.courses
            : fallbackAllQuestions;
        objToPush.tempQuestions =
          tempQuestionsSource?.length > 0 ? tempQuestionsSource : [];
      }
      if (isTemplateSelected) {
        const obj = await this.storeTemplateData(objToPush);
        return obj;
      } else {
        return objToPush;
      }
    },
    storeTemplateData(objToPush) {
      if (objToPush.formType === "Course Feedback") {
        objToPush?.courses?.forEach((courseObj) => {
          this.allCourseOutcome.forEach((coObj) => {
            if (courseObj.subjectId === coObj.subjectId) {
              let updatedQuestions =
                courseObj.allQuestions !== undefined
                  ? JSON.parse(JSON.stringify(courseObj.allQuestions))
                  : [];

              updatedQuestions = updatedQuestions.filter((questionObj1) => {
                questionObj1.questionStatement = this.replaceCONumberWithCOName(
                  questionObj1.questionStatement,
                  coObj.coArray
                );

                questionObj1.scales.noOfOptions =
                  questionObj1.scales.highestNo || "";
                return questionObj1.questionStatement.trim() !== "";
              });

              courseObj.allQuestions = updatedQuestions;
            }
          });
        });
      } else {
        this.allQuestions = this.allQuestions
          .map((questionGroup) => {
            questionGroup.questionStatement = this.replacePONumberWithPOName(
              questionGroup.questionStatement,
              this.allProgramOutcome
            );

            questionGroup.scales.noOfOptions =
              questionGroup.scales.highestNo || "";
            return questionGroup;
          })
          .filter(
            (questionGroup) => questionGroup.questionStatement.trim() !== ""
          );

        // For other form types, just assign the filtered `allQuestions` to `objToPush`
        objToPush.allQuestions = this.allQuestions;
      }

      return objToPush;
    },
    // tempstoreTemplateData(objToPush) {
    //   if (objToPush.formType === "Course Feedback") {
    //     objToPush?.courses?.forEach((courseObj) => {
    //       this.allCourseOutcome.forEach((coObj) => {
    //         if (courseObj.subjectId === coObj.subjectId) {
    //           const updatedQuestions = JSON.parse(
    //             JSON.stringify(courseObj.allQuestions)
    //           );

    //           updatedQuestions.map((questionObj1) => {
    //             questionObj1.questionStatement = this.replaceCONumberWithCOName(
    //               questionObj1.questionStatement,
    //               coObj.coArray
    //             );

    //             // Remove obj if questionStatement is blank
    //             return questionObj1.questionStatement.trim() !== "";
    //           });
    //           courseObj.allQuestions = updatedQuestions;
    //         }
    //       });
    //     });
    //   } else {
    //     this.selectedTemplate.allQuestions.forEach((questionGroup) => {
    //       questionGroup.questionStatement = this.replacePONumberWithPOName(
    //         questionGroup.questionStatement,
    //         this.allProgramOutcome
    //       );
    //       return questionObj1.questionStatement.trim() !== "";
    //     });

    //     // For other form types, just assign allQuestions from the template
    //     objToPush.allQuestions = this.selectedTemplate.allQuestions;
    //   }
    //   return objToPush;
    // },
    replacePONumberWithPOName(inputStr, coArray) {
      // Regular expression to match pattern like <CO1>
      const regex = /<([^>]+)>/;
      const match = inputStr.match(regex);

      if (match) {
        // Extracted CO number (without the < >)
        const extractedCONumber = match[1];

        // Find the corresponding CO object in coArray
        const coObject = coArray.find(
          (co) => co.PONumber === extractedCONumber
        );
        if (coObject) {
          // Replace the matched CONumber with COName
          return inputStr.replace(`<${extractedCONumber}>`, coObject.POName);
        } else {
          return inputStr.replace(`<${extractedCONumber}>`, "");
        }
      }
      return inputStr; // Return original if no match
    },
    replaceCONumberWithCOName(inputStr, coArray) {
      // Regular expression to match pattern like <CO1>
      const regex = /<([^>]+)>/;
      const match = inputStr.match(regex);

      if (match) {
        // Extracted CO number (without the < >)
        const extractedCONumber = match[1];

        // Find the corresponding CO object in coArray
        const coObject = coArray.find(
          (co) => co.CONumber === extractedCONumber
        );
        if (coObject) {
          // Replace the matched CONumber with COName
          return inputStr.replace(`<${extractedCONumber}>`, coObject.COName);
        } else {
          return inputStr.replace(`<${extractedCONumber}>`, "");
        }
      }
      return inputStr; // Return original if no match
    },
    async saveBasicDetails() {
      try {
        let res = {};
        const objToPush = await this.commomObjToPush();
        if (
          this.createdFeedback.feedbackId !== undefined ||
          this.feedbackId !== ""
        ) {
          res = await this.feedbackRepositoryInstance.updateFeedback(objToPush);
        } else {
          res = await this.feedbackRepositoryInstance.createFeedback(objToPush);
          objToPush.feedbackId = res.feedback.feedbackId;
          this.feedbackId = res.feedback.feedbackId;
        }
        if (res.status === 200) {
          this.$store.commit("liveData/set_createdFeedback", objToPush);

          showStatus("Successfully saved.", 2000, "success", this);
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
        this.createdFeedback =
          this.$store.getters["liveData/get_createdFeedback"];
      } catch (e) {
        console.error(e);
      }
    },
    async getTemplates() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          feedback: [
            this.props_selections.selectedTypeOfFeedback,
            "Other Feedback",
          ],
          stakeHolder: this.props_selections.selectedFeedbackFor,
        };
        this.templates =
          await this.NewFeedbackFormRepositoryInstance.getTemplatesByTypeAndStakeholder(
            objToPush
          );

        this.templates?.map((obj, index) => {
          obj.templateName = `${obj.typeOfFeedback}_${index + 1}`;
        });
        this.templates.unshift({ templateName: "None", id: null });
      } catch (e) {
        console.error(e);
      }
    },
    async getCourseOutcome() {
      try {
        const subjectIds = this.tempFeedbackId
          ? this.createdFeedback.courses?.map((course) => course.subjectId)
          : this.feedbackSelections.selectedSubjectIds.map(
              (subject) => subject.subjectId
            );

        const cosDataResponse =
          await this.nbaRepositoryInstance.getCOsForSubjects({
            lmsInstituteId: this.selectedInstitute,
            semesterId: this.feedbackSelections.semester.semId,
            subjectIds: subjectIds,
          });
        if (cosDataResponse?.data?.length > 0) {
          const cosDataMap = new Map();
          const allCourseOutcome = [];

          cosDataResponse?.data?.forEach((co) => {
            if (!cosDataMap.has(co.subjectId)) {
              cosDataMap.set(co.subjectId, []);
            }
            cosDataMap.get(co.subjectId).push(`CO${co.CONumber}`);

            if (!allCourseOutcome[co.subjectId]) {
              allCourseOutcome[co.subjectId] = [];
            }
            allCourseOutcome[co.subjectId].push({
              CONumber: `CO${co.CONumber}`,
              COName: co.COName,
            });
          });

          this.subjectWiseCos = subjectIds.map((subjectId) => ({
            subjectId: subjectId,
            cos: cosDataMap.get(subjectId) || [],
          }));

          this.allCourseOutcome = subjectIds.map((subjectId) => ({
            subjectId: subjectId,
            coArray: allCourseOutcome[subjectId] || [],
          }));
          this.isCOExist = true;
          return this.subjectWiseCos;
        } else {
          showStatus("Course Outcome is not available", 9000, "warning", this);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getProgramOutcome() {
      try {
        let department = "";
        let courseYear = "";
        let batchId = "";
        let semId = "";

        if (this.tempFeedbackId) {
          department = this.createdFeedback.department;
          courseYear = this.createdFeedback.courseYear;
          semId = this.createdFeedback.semId;
        } else {
          department = this.feedbackSelections.department;
          courseYear = this.feedbackSelections.courseYear;
          semId = this.feedbackSelections.semId;
        }

        let poPsoData =
          await this.nbaRepositoryInstance.getPoPsoByLmsInstituteId({
            lmsInstituteId: this.selectedInstitute,
            department: department,
            semesterId: semId,
            courseYear: courseYear,
          });

        if (poPsoData) {
          batchId = poPsoData.batch._id;
          poPsoData?.po?.map((po) => {
            this.allPOs.push(`PO${po.poNumber}`);

            // Push to allProgramOutcome
            this.allProgramOutcome.push({
              PONumber: `PO${po.poNumber}`,
              POName: po.poName,
            });
          });

          poPsoData?.pso?.map((pso) => {
            this.allPSOs.push(`PSO${pso.psoNumber}`);

            // Push to allProgramSpecificOutcome
            this.allProgramSpecificOutcome.push({
              PSONumber: `PSO${pso.psoNumber}`,
              PSOName: pso.psoName,
            });
          });
        }

        return { allPos: this.allPOs, allPSOs: this.allPSOs };
      } catch (e) {
        console.error(e);
      }
    },
    async getFeedbackById() {
      try {
        if (this.createdFeedback.feedbackId) {
          let feedback =
            await this.feedbackRepositoryInstance.getFeedbackDetailsByFeedbackId(
              { feedbackId: this.createdFeedback.feedbackId }
            );
          return feedback[0];
        }
      } catch (e) {
        console.error(e);
      }
    },
    async goToNextPage() {
      const objToPush = await this.commomObjToPush();
      await this.$store.commit("liveData/set_createdFeedback", objToPush);
      await this.$store.commit(
        "liveData/set_isForAttainment",
        this.isForAttainment
      );

      if (this.isNext) {
        this.$emit("nextButtonClick");
      }
    },
  },
};
</script>

<style src="./activityBasicDetails.css" scoped></style>
