<template>
  <div>
    <div>{{label}}</div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formattedDate"
          :placeholder="placeholder"
          readonly
          solo
          flat
          background-color="#E1E2F8"
          v-bind="attrs"
          v-on="on"
          :error-messages="errorMessages"
          clearable
          @click:clear="clearDate"
        />
      </template>
      <v-date-picker
        v-model="internalValue"
        :min="min"
        :max="max"
        :locale="locale"
        @input="updateValue"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    label: {
      type: String,
      default: "Select Date",
    },
    placeholder: {
      type: String,
      default: "DD-MM-YYYY",
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: "en",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menu: false,
      internalValue: this.value,
    };
  },
  computed: {
    formattedDate() {
      return this.internalValue
        ? new Date(this.internalValue).toLocaleDateString(this.locale)
        : "";
    },
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
      this.menu = false; // Close menu after selection
    },
    clearDate() {
      this.internalValue = null;
      this.$emit("input", null);
    },
  },
};
</script>

<style scoped>
/* Add any necessary custom styles here */
</style>
