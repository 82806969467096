<template src="./activityInstitutesManagment.html"></template>
<script>
import inputContainer from "../../Components/inputContainer";
import activityInstitutesBasicDetails from "../activityInstitutesBasicDetails/activityInstitutesBasicDetails.vue";
import activityAddSubjectInstituteLevel from "../activityAddSubjectInstituteLevel/activityAddSubjectInstituteLevel.vue";
import activityControlAccess from "../activityControlAccess/activityControlAccess.vue";
import activityReferenceInfrastructure from "../activityReferenceInfrastructure/activityReferenceInfrastructure.vue";
import activityExamSettings from "../activityExamSettings/activityExamSettings.vue";
import activityReferenceAcademicDetails from "../activityReferenceAcademicDetails/activityReferenceAcademicDetails.vue";
import activityReferenceShiftDetails from "../activityReferenceShiftDetails/activityReferenceShiftDetails.vue";
import activitySettingsAndTemplates from "../activityFeedbackFormRevamped/activitySettingsAndTemplates/activitySettingsAndTemplates.vue";
export default {
  name: "activityInstitutesManagment",
  components: {
    inputContainer,
    activityInstitutesBasicDetails,
    activityAddSubjectInstituteLevel,
    activityControlAccess,
    activityReferenceInfrastructure,
    activityExamSettings,
    activityReferenceAcademicDetails,
    activityReferenceShiftDetails,
    activitySettingsAndTemplates,
  },
  props: ["prop_tab"],
  data() {
    return {
      mainTabNames: [
        "Basic Details",
        "Structure",
        "Courses",
        "Feedback",
        "Template",
        "Infrastructure",
        "Control Access",
      ],
      tabs: 0,
    };
  },
  mounted() {
    const activeTab = this.$route.params.activeTab;
    this.tabs = activeTab;
  },
  computed: {},

  created() {
    if (this.prop_tab) {
      this.tabs = this.prop_tab;
    }
  },

  methods: {},
};
</script>
<style scoped src="./activityInstitutesManagment.css"></style>
