const arrayOps = {
  sortByDates(array) {
    console.log(array);
    array.sort(
      (a, b) => this.dateStringToDateObject(a) - this.dateStringToDateObject(b)
    );
    return array;
  },

  sortByDate(array, fieldToSortBy, order) {
    function compareDates(a, b) {
      const dateA = new Date(a[fieldToSortBy].split("-").reverse().join("-"));
      const dateB = new Date(b[fieldToSortBy].split("-").reverse().join("-"));
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return array.sort(compareDates);
  },

  dateStringToDateObject(obj) {
    const D = new Date(
      parseInt(obj.date.split("/")[2]),
      parseInt(obj.date.split("/")[1]) - 1,
      parseInt(obj.date.split("/")[0]),
      parseInt(obj.time.split("_")[0]),
      parseInt(obj.time.split("_")[1])
    );
    return D;
  },

  removeFromArray(array, n) {
    if (n > -1) {
      array.splice(n, 1);
    }
    return array;
  },

  compareArrays(array1, array2) {
    array1.sort();
    array2.sort();
    return (
      array1.length === array2.length &&
      array1.every((element, index) => element === array2[index])
    );
  },

  sortByProperty(array, property, order) {
    let orderBy = order ? order : "asc";
    if (property) {
      return array.sort((a, b) => {
        const valueA = a[property];
        const valueB = b[property];

        if (typeof valueA === "number" && typeof valueB === "number") {
          return orderBy === "asc" ? valueA - valueB : valueB - valueA;
        } else if (typeof valueA === "string" && typeof valueB === "string") {
          return orderBy === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else {
          // Handle alphanumeric values
          const stringValueA = String(valueA);
          const stringValueB = String(valueB);
          return orderBy === "asc"
            ? stringValueA.localeCompare(stringValueB)
            : stringValueB.localeCompare(stringValueA);
        }
      });
    } else {
      return [];
    }
  },

  sortArray(array, order) {
    /*
     * Sorts an array of strings in ascending or descending order, with error handling.
     *
     * @param {Array<string>} array - The array of strings to be sorted.
     * @param {string} [order='asc'] - The order to sort by ('asc' for ascending, 'desc' for descending) (default order is ascending).
     * @returns {{sortedArray: Array<string>, errors: Array<string>}} An object containing the sorted array and any errors encountered during sorting.
     *                    - {Array<string>} sortedArray: The sorted array of strings.
     *                    - {Array<string>} errors: An array of error messages, if any.
     *
     * @example
     * const fruits = ['apple', 'orange', 'banana', 'pear'];
     *
     * // Sort fruits in ascending order
     * const resultAsc = sortStrings(fruits, 'asc');
     * console.log(resultAsc.sortedArray); // ['apple', 'banana', 'orange', 'pear']
     * console.log(resultAsc.errors); // []
     *
     * // Sort fruits in ascending order
     * const resultAsc = sortStrings(fruits, 'asc');
     * console.log(resultAsc.sortedArray); // ['apple', 'banana', 'orange', 'pear']
     * console.log(resultAsc.errors); // []
     *
     * // Sort fruits in descending order
     * const resultDesc = sortStrings(fruits, 'desc');
     * console.log(resultDesc.sortedArray); // ['pear', 'orange', 'banana', 'apple']
     * console.log(resultDesc.errors); // []
     *
     * // Handle invalid input
     * const invalidArray = ['apple', 123, 'banana', null];
     * const resultWithErrors = sortStrings(invalidArray, 'asc');
     * console.log(resultWithErrors.sortedArray); // []
     * console.log(resultWithErrors.errors); // ['Invalid element type: 123 is not a string', 'Invalid element type: null is not a string']
     *
     * // Handle invalid order
     * const resultInvalidOrder = sortStrings(fruits, 'ascending');
     * console.log(resultInvalidOrder.sortedArray); // []
     * console.log(resultInvalidOrder.errors); // ['Order should be either "asc" or "desc"']
     */
    const errors = [];
    let isAsc = true;

    if (order === "desc") isAsc = false;

    // Error handling: check if the input is an array
    if (!Array.isArray(array)) {
      errors.push("Input should be an array");
      return { result: [], errors };
    }

    // Error handling: check if all elements in the array are strings
    for (const item of array) {
      if (typeof item !== "string") {
        errors.push(`Invalid element type: ${item} is not a string`);
      }
    }

    // If there are errors, return the errors and an empty sorted array
    if (errors.length > 0) {
      return { result: [], errors };
    }

    const result = array.sort((a, b) => {
      return isAsc ? a.localeCompare(b) : b.localeCompare(a);
    });

    return { result, errors };
  },
  isArrayOfObjects(array) {
    if (!Array.isArray(array)) {
      throw new Error("Input is not an array.");
    }

    // Check if all elements in the array are objects
    return array.every(
      (item) =>
        typeof item === "object" && item !== null && !Array.isArray(item)
    );
  },
  extractKey(data, key) {
    try {
      if (!Array.isArray(data)) {
        throw new TypeError("Expected an array of objects.");
      }

      return data
        .map((item, index) => {
          if (typeof item !== "object" || item === null) {
            console.warn(`Skipping invalid object at index ${index}:`, item);
            return null; // Return null for invalid entries instead of breaking
          }

          if (!(key in item)) {
            console.warn(
              `Key "${key}" not found in object at index ${index}:`,
              item
            );
            return null; // Return null if key is missing
          }

          return item[key];
        })
        .filter((value) => value !== null); // Remove null values from result
    } catch (error) {
      console.error("Error in extractKey function:", error.message);
      return []; // Return an empty array on error
    }
  },
};

export default arrayOps;
