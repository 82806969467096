export default {
  updateUserDetail: '/users/updateUserDetail',
  deleteMultipleUser: '/users/deleteMultipleUser',
  createUser: '/users/createUser',
  addEmail: '/auth/createUserAuthenticationWithEmail',
  addAndUpdateUserName:'/auth/createUserAuthenticationWithUserName',
  addPhone: '/auth/createUserAuthenticationWithPhone',
  login: '/auth/login',
  getUserProfile: '/users/getUser',
  getUsers: '/users/getUsers',
  getUsersOfDesignationOfAInstitute: '/users/getUsersOfDesignationOfAInstitute',
  getUserAuthentication: '/auth/getUserAuthenticationsOfAUser',
  getUserAuthenticationByEmail: '/auth/getUserAuthenticationByEmail',
  getFullNameOfUser: '/users/getFullNameOfUser',
  getFullNameOfUsers: '/users/getFullNameOfUsers',
  getParentsOfAUser: '/users/getParentsOfAUser',
  updateFirstName: '/users/updateFirstName',
  updateMiddleName: '/users/updateMiddleName',
  updateLastName: '/users/updateLastName',
  updateDateOfBirth: '/users/updateDateOfBirth',
  updateGender: '/users/updateGender',
  updateEmail: '/auth/updateEmail',
  updatePhone: '/auth/updatePhone',
  deleteEmail: '/auth/deleteUserAuthenticationUsingEmail',
  deletePhone: '/auth/deleteUserAuthenticationUsingPhone',
  loginWithUserNameAndPassword: '/auth/loginWithUserNameAndPassword',
  passwordUpdate: '/auth/passwordUpdate',
  getDivisionInformationOfAFacultyAsClassTeacher: '/semester/getDivisionInformationOfAFacultyAsClassTeacher',
  forgotPassword: '/auth/loginPasswordByUserName',
  addMultipleUsersOnBothSides: '/user/addMultipleUsersOnBothSides',
  bulkUpdateSubAdmin: '/user/bulkUpdateSubAdmin',
  deleteFromEveryWhere: '/user/deleteFromEveryWhere',
  updateMultipleUsersOnBothSides: '/user/updateMultipleUsersOnBothSides',
  deleteEmailsFromUserAuth: '/user/deleteEmailsFromUserAuth'
}
