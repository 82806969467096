<template>
  <v-dialog width="60%" v-model="uploadFileDialog">
    <v-card>
      <v-card-title>
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 100%;
            color: #313131;
          "
        >
          <span>{{ title }}</span>
          <span>
            <SoloIcon
              color="#313131"
              icon="close"
              @click="closeDialog"
            ></SoloIcon>
          </span>
        </div>
      </v-card-title>
      <v-card-text>
        <FileUpload
          :allow-sample-download="allowSampleDownload"
          @download-sample="$emit('sample-download')"
        ></FileUpload>
      </v-card-text>
      <v-card-actions>
        <CustomTertiaryButton @click="cancel">Cancel</CustomTertiaryButton>
        <CustomPrimaryButton @click="upload">Upload</CustomPrimaryButton>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "../InputsAndControls/FileUpload.vue";
import SoloIcon from "../Buttons/Icons/SoloIcon.vue";
import CustomTertiaryButton from "../Buttons/Tertiary.vue";
import CustomPrimaryButton from "../Buttons/Button.vue";

export default {
  name: "FileUploadDialog",
  components: {
    FileUpload,
    SoloIcon,
    CustomTertiaryButton,
    CustomPrimaryButton,
  },
  props: {
    uploadFileDialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "Upload via Excel",
    },
    allowSampleDownload: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("update:uploadFileDialog", false);
    },
    cancel() {
      this.$emit("cancel");
    },
    upload() {
      this.$emit("upload");
    },
  },
};
</script>

<style scoped>
</style>
