var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle-container",class:_vm.containerClass},_vm._l((_vm.normalizedTextEntries),function(entry,index){return _c('div',{key:index,staticClass:"circle-wrapper",class:entry.position},[_c('div',{staticClass:"circle",style:({
        backgroundColor: entry.primaryColor || _vm.backgroundColor,
        borderColor: entry.borderColor || _vm.borderColor,
        width: entry.size || _vm.size,
        height: entry.size || _vm.size,
        borderWidth: _vm.borderWidth,
      }),on:{"click":function($event){entry.onClick ? _vm.handleClick(entry) : null}}}),_c('p',{staticClass:"circle-text",style:({ fontSize: entry.textSize })},[_vm._v(" "+_vm._s(entry.text)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }