<template src="./activityManageUsers.html"></template>

<script>
import UserRepository from "../../Repository/User";
import UserAuthRepository from "../../Repository/UserAuthentication";
import InstituteUserRepository from "../../Repository/InstituteUser";
import SemesterUserRepository from "../../Repository/SemesterUser";
import inputContainer from "../../Components/inputContainer";
import arrayOps from "../../Services/Utils/arrayOps";
import stringOps from "../../Services/Utils/stringOps";
import numOps from "../../Services/Utils/numOps";
import showStatus from "../../NetworkManager/showStatus";
import MailSenderRepository from "../../Repository/EmailSender";
import departmentDetailsRepository from "../../Repository/DepartmentDetails";
import {
  generateJsonFromExcel,
  generateFromJson,
  createExcelThroughJsonAndSchema,
} from "../../utils/excel";
import InstituteRepository from "../../Repository/Institute";
import UserAuthenticationRepository from "../../Repository/UserAuthentication";
import auth from "../../Services/Firebase/auth";
import { v4 as uuidv4 } from "uuid";

import { ROLES } from "../../Constants";
import moment from "moment";
import objectOperations from "../../Services/Utils/objectOperations";

// Global Components
import CircularTag from "../../Components/styleGuide/Tags/Circular.vue";
import CircularTagGroup from "../../Components/styleGuide/Tags/CircularGroup.vue";
import {
  userRoles,
  getRoleObjectByValue,
  getRoleByIndex,
} from "../../Constants/Utils/Statics";
import CustomPrimaryButton from "../../Components/styleGuide/Buttons/Button.vue";
import CustomSecondaryButton from "../../Components/styleGuide/Buttons/Secondary.vue";
import CustomTertiaryButton from "../../Components/styleGuide/Buttons/Tertiary.vue";
import CustomSelect from "../../Components/styleGuide/InputsAndControls/Select.vue";
import CustomTextInput from "../../Components/styleGuide/InputsAndControls/TextField.vue";
import SearchField from "../../Components/styleGuide/InputsAndControls/Search.vue";
import SkeletonLoader from "../../Components/styleGuide/Loaders/Skeleton.vue";
import CircularLoader from "../../Components/styleGuide/Loaders/Circular.vue";
import SoloIcon from "../../Components/styleGuide/Buttons/Icons/SoloIcon.vue";
import HoverCopyToClipboard from "../../Components/styleGuide/Buttons/Icons/HoverCopyToClipboard.vue";
import FileUploadInput from "../../Components/styleGuide/InputsAndControls/FileUpload.vue";
import FileUploadDialog from "../../Components/styleGuide/Dialogs/FileUpload.vue";
import DeleteDialog from "../../Components/styleGuide/Dialogs/Delete.vue";
import DatePicker from "../../Components/styleGuide/InputsAndControls/DatePicker.vue";
import DisableWrapper from "../../Components/styleGuide/DisableWrapper/primary.vue";
import { tr } from "date-fns/locale";
export default {
  name: "activityManageUsers",
  components: {
    inputContainer,
    CircularTag,
    CircularTagGroup,
    CustomPrimaryButton,
    CustomSecondaryButton,
    CustomSelect,
    CustomTextInput,
    SearchField,
    SkeletonLoader,
    CircularLoader,
    SoloIcon,
    FileUploadInput,
    CustomTertiaryButton,
    FileUploadDialog,
    DeleteDialog,
    DatePicker,
    DisableWrapper,
    HoverCopyToClipboard,
  },
  data() {
    return {
      tab: 0,
      userRoles: userRoles,
      userRolesOnSubAdminPopUp: [],
      userRolesForSubAdmin: [],
      tempMotherName: "",
      uploadExcelLoading: false,
      isCorporateInstitute: false,
      isCandidate: "",
      checkUserNameChanged: "",
      isInstituteForNaacOrNBA: false,
      alertFromExcel: "",
      showAlertForUserExcel: false,
      usersUploadExcelLoading: false,
      allErrorArray: [],
      departments: [],
      emailsArrayToDelete: [],
      tempDepartment: "",
      isSubAdminSelected: "",
      isSubAdmin: "false",
      tempSetPassword: "",
      tempUserName: "",
      senderMail: "",
      emailBody: "",
      senderMailPassword: "",
      displayName: "",
      subjectDescription: "",
      instituteStudentBrandingName: "",
      instituteStudentLogoLink: "",
      file: null,
      updateUsersFile: null,
      role: "",
      showUploadUsersDialog: false,
      loading: false,
      createUserLoading: false,
      search: "",
      addingParent: false,
      prevUserName: "",
      editingParent: false,
      addUserDialog: false,
      editUserDialog: false,
      addUserStepNumber: 1,
      selectedUser: "",
      tempFirstName: "",
      tempInstituteProvidedId: "",
      tempMiddleName: "",
      excelLink: "",
      tempLastName: "",
      tempEmails: [],
      tempPhones: [],
      parents: [],
      parentsEdited: [],
      tempEmail: "",
      userName: "",
      tempPhone: "",
      addingEmail: false,
      addingPhone: false,
      isTeaching: false,
      tempGender: "",
      tempDesignation: "",
      tempgrNumber: "",
      tempAbcIdNumber: "",
      tempDateOfBirth: "",
      tempStudentId: "",
      tempBloodGroup: "",
      ExcelErr: [],
      designationDetails: [],
      bloodGroups: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "fullName",
          filterable: true,
        },
        {
          text: "Institute Id",
          sortable: true,
          value: "instituteProvidedId",
          filterable: true,
        },
        {
          text: "Actions",
          value: "action",
          align: "center",
        },
      ],
      users: [],
      percentage: 0,
      updateUsersDialog: false,
      userDetails: [],
      excelDataLoader: false,
      allUIds: [],
      userAuthentications: [],
      excelValidationErrors: [],
      updateUsersLoader: false,
      existingEmails: [],
      // ------------------------------------------------------------------------------------------------------------------------------------
      subAdmin: "",
      // ++++++++++++++++++++++++++++++++++++++++++++++++++++Admin
      adminHeaders: [
        {
          text: "Edit",
          value: "action",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "First Name",
          value: "firstName",
        },
        {
          text: "Middle Name",
          value: "middleName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          text: "User Name",
          value: "userName",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Mobile No.",
          value: "phone",
        },
        {
          text: "Gender",
          value: "gender",
        },
        {
          text: "Date of Birth",
          value: "modifiedDob",
          width: "150px",
        },
      ],
      totalAdmins: 0,
      totalInstituteUsers: 0,
      adminDetails: [],
      selectedAdminColumns: [],
      adminColumnOptions: [],
      searchAdmin: "",
      adminExcelSchema: {},
      isAdminDetailsEditable: false,
      selectedAdmins: [],
      confirmDeleteAdminDialog: false,
      deleteAdminsLoader: false,

      // ++++++++++++++++++++++++++++++++++++++++++++++++++++Faculty
      facultyHeaders: [
        {
          text: "Edit",
          value: "action",
        },
        {
          text: "Roles",
          value: "modifiedRoles",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "First Name",
          value: "firstName",
        },
        {
          text: "Middle Name",
          value: "middleName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          text: "Designation",
          value: "designation",
        },
        {
          text: "Core Department",
          value: "department",
        },
        {
          text: "Allocation",
          value: "allocation",
          align: "center",
        },
        {
          text: "Mobile No.",
          value: "phone",
        },
        {
          text: "User Name",
          value: "userName",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Gender",
          value: "gender",
        },
        {
          text: "Date of Birth",
          value: "modifiedDob",
          width: "150px",
        },
      ],
      isFacultyDetailsEditable: false,
      totalFaculties: 0,
      totalProfessors: 0,
      totalAssociateProfessors: 0,
      totalAssistantProfessors: 0,
      totalNonTeachingProfessors: 0,
      facultyDetails: [],
      selectedFacultyColumns: [],
      facultyColumnOptions: [],
      searchFaculty: "",
      invalidFaculties: [],
      facultyAllocationDetails: [],
      confirmDeleteFacultyDialog: false,
      facultyName: "",
      facultyAllocationDialog: false,
      selectedFaculties: [],
      facultyAllocation: [],
      facultyUIds: [],
      deleteFacultyLoader: false,
      facultyExcelSchema: {},
      totalFacultiesWithNoAllocations: 0,
      facultyTypes: ["Teaching", "Non-Teaching"],

      // ++++++++++++++++++++++++++++++++++++++++++++++++++++Student
      studentHeaders: [
        {
          text: "Edit",
          value: "action",
        },
        {
          text: "Roles",
          value: "modifiedRoles",
        },
        {
          text: "PRN",
          value: "collegePRNNo",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "First Name",
          value: "firstName",
        },
        {
          text: "Middle Name",
          value: "middleName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          text: "Allocation",
          value: "allocation",
          align: "center",
        },
        {
          text: "User Name",
          value: "userName",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Mobile No.",
          value: "phone",
        },
        {
          text: "Mother's Name",
          value: "motherName",
        },
        {
          text: "Gender",
          value: "gender",
        },
        {
          text: "Date of Birth",
          value: "modifiedDob",
          width: "150px",
        },
      ],
      isStudentDetailsEditable: false,
      totalStudents: 0,
      totalStudentsWithNoAllocations: 0,
      studentDetails: [],
      selectedStudentColumns: [],
      studentColumnOptions: [],
      searchStudent: "",
      studentAllocationDetails: [],
      invalidStudents: [],
      studentAllocationDialog: false,
      confirmDeleteStudentDialog: false,
      selectedStudents: [],
      studentName: "",
      studentUIds: [],
      studentAllocation: [],
      deleteStudentsLoader: false,
      studentExcelSchema: {},

      // ++++++++++++++++++++++++++++++++++++++++++++++++++++Sub Admin
      subAdminHeaders: [
        {
          text: "Roles",
          value: "modifiedRoles",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "First Name",
          value: "firstName",
        },
        {
          text: "Middle Name",
          value: "middleName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          text: "Gender",
          value: "gender",
        },
        {
          text: "Date of Birth",
          value: "modifiedDob",
          width: "150px",
        },
        {
          text: "Mobile No.",
          value: "phone",
        },
        {
          text: "User Name",
          value: "userName",
        },
        {
          text: "Email",
          value: "email",
        },
      ],
      subAdminDetails: [],
      selectedSubAdminColumns: [],
      subAdminColumnOptions: [],
      searchSubAdmin: "",
      searchToBeSubAdmin: "",
      uploadFileDialog: false,
      updateUserDialog: false,
      updateUserFile: null,
      subAdminDialog: false,
      allUserDetailsExceptSubAdmins: [],
      usersToPromoteToSubAdmin: [],
      toBeSubAdminTableHeaders: [
        {
          text: "",
          value: "roles",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "First Name",
          value: "firstName",
        },
        {
          text: "Middle Name",
          value: "middleName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
      ],
      isSubAdminDetailsEditable: false,
      usersToDemoteFromSubAdmin: [],
      demoteSubAdminDialog: false,
      loadingAllocation: false,
      duplicateEmails: [],
      duplicateUserNames: [],
      duplicateMobileNumbers: [],
      existingEmailsInFirebase: [],
      dataTableLoading: false,
      subAdminLoader: false,
      totalStudentsAsSubAdmin: 0,
      totalFacultiesAsSubAdmin: 0,
      editUserDialog: false,
      userToEdit: {},
      genders: ["Male", "Female", "Other"],
      menu2: false,
      date: "",
      userPassword: "",
      currentInstitute: "",
      updateUserLoading: false,
      repeatedEmails: [],
      repeatedUserNames: [],
      repeatedPhones: [],
      error: false,
      singleUserUpdateError: "",
      warningDialog: false,
      restrictEditOperation: false,
      allTitles: [],
      inputRules: {
        numberOnly: (value) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || "Please Enter Only Numbers.";
        },
        alphabetsOnly: (value) => {
          if (value) {
            const pattern = /^[A-Za-z]+$/;
            return pattern.test(value) ? true : "Please enter only alphabets.";
          }
        },
        alphabetsWithSpaceOnly: (value) => {
          if (value) {
            // const pattern = /^[A-Za-z]+$/;
            return stringOps.isValidAlphabeticalStringWithSpaces(value)
              ? true
              : "Please enter only alphabets.";
          }
        },
        numberCounter: (value) => {
          if (value) {
            if (value.toString().length < 10 || value.toString().length > 10) {
              return "Phone number should be of 10 digits.";
            } else {
              return "";
            }
          }
        },
        emailPattern: (value) => {
          if (value) {
            let emailPattern =
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailPattern.test(value) || "Invalid email address.";
          }
        },
        compulsoryField: (value) => !!value || "This field cannot be empty.",
      },
    };
  },
  computed: {
    // Admin
    visibleAdminHeaders() {
      // Filter headers based on selected columns
      if (this.selectedAdminColumns.includes("all")) {
        return this.adminHeaders;
      }
      return this.adminHeaders.filter((header) =>
        this.selectedAdminColumns.includes(header.value)
      );
    },
    // Faculty
    visibleFacultyHeaders() {
      // Filter headers based on selected columns
      if (this.selectedFacultyColumns.includes("all")) {
        return this.facultyHeaders;
      }
      return this.facultyHeaders.filter((header) =>
        this.selectedFacultyColumns.includes(header.value)
      );
    },
    // Student
    visibleStudentHeaders() {
      // Filter headers based on selected columns
      if (this.selectedStudentColumns.includes("all")) {
        return this.studentHeaders;
      }
      return this.studentHeaders.filter((header) =>
        this.selectedStudentColumns.includes(header.value)
      );
    },
    // Sub Admin
    visibleSubAdminHeaders() {
      // Filter headers based on selected columns
      if (this.selectedSubAdminColumns.includes("all")) {
        return this.subAdminHeaders;
      }
      return this.subAdminHeaders.filter((header) =>
        this.selectedSubAdminColumns.includes(header.value)
      );
    },
  },
  async created() {
    // Initialize column options with an "All" option
    this.handleAdminColumnsSelection();
    this.handleFacultyColumnsSelection();
    this.handleStudentColumnsSelection();
    this.handleSubAdminColumnsSelection();

    this.userRepositoryInstance = new UserRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.UserAuthenticationRepositoryInstance =
      new UserAuthenticationRepository(this);
    this.departmentDetailsRepositoryInstance = new departmentDetailsRepository(
      this
    );
    this.UserAuthRepositoryInstance = new UserAuthRepository(this);
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this);
    this.MailSenderRepositoryInstance = new MailSenderRepository(this);
    this.instituteRepositoryInstance = new InstituteRepository(this);

    this.$store.commit("liveData/set_selectedActivityName", "Manage Users");
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.isCorporateInstitute =
      this.$store.getters["liveData/get_isCorporateInstitute"];
    this.senderMail = this.$store.getters["liveData/get_senderMail"];
    this.senderMailPassword =
      this.$store.getters["liveData/get_senderMailPassword"];
    this.emailBody = this.$store.getters["liveData/get_emailBody"];
    this.displayName = this.$store.getters["liveData/get_displayName"];
    this.subjectDescription =
      this.$store.getters["liveData/get_subjectDescription"];
    this.instituteStudentBrandingName =
      this.$store.getters["liveData/get_instituteStudentBrandingName"];
    this.instituteStudentLogoLink =
      this.$store.getters["liveData/get_instituteStudentLogoLink"];
    this.loading = true;
    await this.getDepartments();
    await this.getSalutations();
    await this.getDesignationsOfInstitute();
    await this.fetchData();
    this.loading = false;
    await this.getFacultyAllocation();
    await this.getStudentAllocation();
  },
  methods: {
    clearErrors() {
      this.duplicateEmails = [];
      this.duplicateMobileNumbers = [];
      this.duplicateUserNames = [];
      this.ExcelErr = [];
      this.existingEmailsInFirebase = [];
    },
    async openFacultyAllocationDialog(param) {
      this.facultyName = `${param.title || ""} ${param.firstName} ${
        param.middleName
      } ${param.lastName}`;
      this.facultyAllocation = param.allocations;
      this.facultyAllocationDialog = true;
    },
    openStudentAllocationDialog(param) {
      this.studentAllocation = param.allocations;
      this.studentName = `${param.title || ""} ${param.firstName} ${
        param.middleName
      } ${param.lastName}`;
      this.studentAllocationDialog = true;
    },
    closeFacultyAllocationDialog() {
      this.facultyAllocationDialog = false;
    },
    closeStudentAllocationDialog() {
      this.studentAllocationDialog = false;
    },
    openWarningDialog() {
      this.warningDialog = true;
    },
    gotoActivityReferenceAcademcDetails() {
      let objToPush = {
        prop_tab: 1,
      };
      this.$router.push({
        name: "activityReferenceAcademicDetails",
        params: objToPush,
      });
    },
    closeWarningDialog() {
      this.warningDialog = false;
    },
    closeFileUploadDialog() {
      this.ExcelErr = [];
      this.duplicateEmails = [];
      this.duplicateMobileNumbers = [];
      this.duplicateUserNames = [];
      this.file = null;
      this.$refs.fileUpload.resetFile(); // Call the reset method in the FileUpload component
      this.usersUploadExcelLoading = false;
      this.uploadFileDialog = false;
    },
    closeUpdateUserDialog() {
      // this.file = null;
      this.$refs.fileUpload.resetFile(); // Call the reset method in the FileUpload component
      this.updateUserDialog = false;
    },
    handleFileUploadOpen() {
      this.file = null;
      if (this.designationDetails.length === 0 && this.tab === 1) {
        this.openWarningDialog();
      } else {
        this.uploadFileDialog = true;
      }
    },
    handleUpdateUserOpen() {
      // this.file = null
      this.updateUserDialog = true;
    },
    handleAdminColumnsSelection() {
      // Initialize column options with an "All" option
      this.adminColumnOptions = [
        { text: "All", value: "all" },
        ...this.adminHeaders.map((header) => ({
          text: header.text,
          value: header.value,
        })),
      ];
      // Initially select all columns
      this.selectedAdminColumns = ["all"];
    },
    handleFacultyColumnsSelection() {
      // Initialize column options with an "All" option
      this.facultyColumnOptions = [
        { text: "All", value: "all" },
        ...this.facultyHeaders.map((header) => ({
          text: header.text,
          value: header.value,
        })),
      ];
      // Initially select all columns
      this.selectedFacultyColumns = ["all"];
    },
    handleStudentColumnsSelection() {
      // Initialize column options with an "All" option
      this.studentColumnOptions = [
        { text: "All", value: "all" },
        ...this.studentHeaders.map((header) => ({
          text: header.text,
          value: header.value,
        })),
      ];
      // Initially select all columns
      this.selectedStudentColumns = ["all"];
    },
    handleSubAdminColumnsSelection() {
      // Initialize column options with an "All" option
      this.subAdminColumnOptions = [
        { text: "All", value: "all" },
        ...this.subAdminHeaders.map((header) => ({
          text: header.text,
          value: header.value,
        })),
      ];
      // Initially select all columns
      this.selectedSubAdminColumns = ["all"];
    },
    updateAdminHeaders() {
      if (this.selectedAdminColumns.includes("all")) {
        // If "All" is selected, deselect other options
        this.selectedAdminColumns = ["all"];
      } else {
        // Ensure "All" is deselected when any other option is selected
        this.selectedAdminColumns = this.selectedAdminColumns.filter(
          (col) => col !== "all"
        );
      }
    },
    updateFacultyHeaders() {
      if (this.selectedFacultyColumns.includes("all")) {
        // If "All" is selected, deselect other options
        this.selectedFacultyColumns = ["all"];
      } else {
        // Ensure "All" is deselected when any other option is selected
        this.selectedFacultyColumns = this.selectedFacultyColumns.filter(
          (col) => col !== "all"
        );
      }
    },
    updateStudentHeaders() {
      if (this.selectedStudentColumns.includes("all")) {
        // If "All" is selected, deselect other options
        this.selectedStudentColumns = ["all"];
      } else {
        // Ensure "All" is deselected when any other option is selected
        this.selectedStudentColumns = this.selectedStudentColumns.filter(
          (col) => col !== "all"
        );
      }
    },
    updateSubAdminHeaders() {
      if (this.selectedSubAdminColumns.includes("all")) {
        // If "All" is selected, deselect other options
        this.selectedSubAdminColumns = ["all"];
      } else {
        // Ensure "All" is deselected when any other option is selected
        this.selectedSubAdminColumns = this.selectedSubAdminColumns.filter(
          (col) => col !== "all"
        );
      }
    },
    handleEditUser(user) {
      this.restrictEditOperation = false;
      this.userPassword = "";
      if (user.roles.includes(2)) {
        this.restrictEditOperation = true;
      }
      this.userToEdit = { ...user };
      this.editUserDialog = true;
    },
    closeEditUserDialog() {
      this.editUserDialog = false;
    },
    async updateUser() {
      this.singleUserUpdateError = "";
      this.updateUserLoading = true;
      let usersToUpdate = [];
      this.userToEdit.newPassword = "";
      if (this.userPassword !== "") {
        this.userToEdit.newPassword = this.userPassword;
      }
      [this.userToEdit].map((item) => {
        let trimmedData = this.normalizeObject(item);
        usersToUpdate.push(trimmedData);
      });
      let error = false;
      console.log("usersToUpdate[0].phone", usersToUpdate[0].phone);
      // if (
      //   (usersToUpdate[0].email && usersToUpdate[0].email === "") ||
      //   (usersToUpdate[0].firstName && usersToUpdate[0].firstName === "") ||
      //   (usersToUpdate[0].lastName && usersToUpdate[0].lastName === "") ||
      //   (usersToUpdate[0].phone && usersToUpdate[0].phone === "") ||
      //   (usersToUpdate[0].gender && usersToUpdate[0].gender === "") ||
      //   (usersToUpdate[0].userName && usersToUpdate[0].userName === "")
      // ) {
      //   showStatus("Please fill required fields.", 9000, "error", this);
      //   error = true;
      // }
      const requiredFields = [
        "email",
        "firstName",
        "lastName",
        "phone",
        "gender",
        "userName",
      ];
      const invalidFields = this.validateFields(
        usersToUpdate[0],
        requiredFields
      );

      if (invalidFields.length > 0) {
        const errorMessage = `Please fill the required fields: ${invalidFields.join(
          ", "
        )}`;
        showStatus(errorMessage, 9000, "error", this);
        error = true;
        this.updateUserLoading = false;
        return
      }

      if (usersToUpdate[0].primaryEmail) {
        let response = stringOps.isValidEmail(usersToUpdate[0].primaryEmail);
        if (!response) {
          error = true;
          showStatus("Please enter a valid Email Id.", 9000, "error", this);
        }
      }

      if (usersToUpdate[0].phone) {
        let phonePattern = /^\d{10}$/;
        if (Number(usersToUpdate[0].phone) === NaN) {
          error = true;
        } else {
          let response = stringOps.doesPatternMatch(
            phonePattern,
            usersToUpdate[0].phone.toString()
          );
          if (!response) {
            error = true;
          }
        }
      }

      if (!this.genders.includes(usersToUpdate[0].gender)) {
        showStatus("Please enter a valid Gender.", 9000, "error", this);
        error = true;
      }

      if (usersToUpdate[0].firstName !== "") {
        let response = stringOps.isValidAlphabeticalStringWithSpaces(
          usersToUpdate[0].firstName
        );
        if (!response) {
          showStatus("Please enter a valid First Name.", 9000, "error", this);
          error = true;
        } else {
          let temp = stringOps.capitalizeWords(usersToUpdate[0].firstName);
          usersToUpdate[0].firstName = temp;
        }
      }

      if (usersToUpdate[0].middleName && usersToUpdate[0].middleName !== "") {
        let response = stringOps.isValidAlphabeticalStringWithSpaces(
          usersToUpdate[0].middleName
        );
        if (!response) {
          showStatus("Please enter a valid Middle Name.", 9000, "error", this);
          error = true;
        } else {
          let temp = stringOps.capitalizeWords(usersToUpdate[0].middleName);
          usersToUpdate[0].middleName = temp;
        }
      }

      if (usersToUpdate[0].lastName !== "") {
        let response = stringOps.isValidAlphabeticalStringWithSpaces(
          usersToUpdate[0].lastName
        );
        if (!response) {
          showStatus("Please enter a valid Last Name.", 9000, "error", this);
          error = true;
        } else {
          let temp = stringOps.capitalizeWords(usersToUpdate[0].lastName);
          usersToUpdate[0].lastName = temp;
        }
      }

      //faculty
      if (usersToUpdate[0].roles && usersToUpdate[0].roles.includes(1)) {
        if (usersToUpdate[0].modifiedFacultyType === "") {
          error = true;
        } else {
          usersToUpdate[0].isTeaching = this.isFacultyTeaching(
            usersToUpdate[0].modifiedFacultyType
          );
        }
      }

      // student
      if (usersToUpdate[0].roles && usersToUpdate[0].roles.includes(0)) {
        if (usersToUpdate[0].motherName && usersToUpdate[0].motherName !== "") {
          let response = stringOps.isValidAlphabeticalStringWithSpaces(
            usersToUpdate[0].motherName
          );
          if (!response) {
            showStatus("Please enter a valid First Name.", 9000, "error", this);
            error = true;
          } else {
            let temp = stringOps.capitalizeWords(usersToUpdate[0].motherName);
            usersToUpdate[0].motherName = temp;
          }
        }
      }

      let fieldsToBeRemoved = [
        "modifiedFacultyType",
        "modifiedDob",
        "modifiedRoles",
      ];

      let result = objectOperations.removeFields(
        usersToUpdate[0],
        fieldsToBeRemoved
      ).modifiedObject;

      usersToUpdate[0] = result;
      if (error) {
        showStatus("Please enter valid values.", 9000, "error", this);
      }
      console.log("usersToUpdate", usersToUpdate);
      if (this.userToEdit?.email?.length > 0 && !error) {
        const objToPush = {
          users: usersToUpdate,
          instituteId: this.selectedInstitute,
        };
        // let temp = setInterval(() => {
        //   this.updatePerscetage();
        // }, 100);
        try {
          const result =
            await this.userRepositoryInstance.updateMultipleUsersOnBothSides(
              objToPush
            );
          if (!result.success) {
            this.duplicateEmails = result.data.duplicateEmails;
            this.duplicateUserNames = result.data.duplicateUserNames;
            this.duplicateMobileNumbers = result.data.duplicateMobileNumbers;
            if (this.duplicateEmails.length > 0) {
              this.singleUserUpdateError = `The email ${this.duplicateEmails[0]} already exists.`;
            }
            if (this.duplicateUserNames.length > 0) {
              this.singleUserUpdateError = ` The User Name ${this.duplicateUserNames[0]} already exists.`;
            }
            if (this.duplicateMobileNumbers.length > 0) {
              this.singleUserUpdateError = `The Mobile Number ${this.duplicateMobileNumbers[0]} already exists.`;
            }
            showStatus(this.singleUserUpdateError, 9000, "error", this);

            // clearInterval(temp);
          } else {
            showStatus("User updated successfully.", 4000, "success", this);
            // this.closeUpdateUserDialog();
            this.closeEditUserDialog();
            await this.fetchData();
            this.getFacultyAllocation();
            this.getStudentAllocation();
          }
        } catch (error) {
          showStatus("Error updating the user.", 9000, "error", this);
          console.error("error", error);
        }
      }
      this.updateUserLoading = false;
    },
    toNormalText(camelCase) {
      return camelCase
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase());
    },

    validateFields(data, fields) {
      const missingFields = [];

      for (const field of fields) {
        if (
          data[field] === undefined ||
          (typeof data[field] === "string" && data[field].trim() === "")
        ) {
          missingFields.push(this.toNormalText(field));
        }
      }

      return missingFields;
    },
    isFacultyTeaching(type) {
      if (!type) return false;

      return type === "Teaching" ? true : false;
    },
    async deleteFaculty() {
      this.deleteFacultyLoader = true;

      let uIdsToDelete = [];
      let emailsToDelete = [];

      this.selectedFaculties.map((u) => {
        uIdsToDelete.push(u.uId);
        emailsToDelete.push(u.primaryEmail);
      });

      if (uIdsToDelete.length > 0) {
        let objToPush = {
          uIds: uIdsToDelete,
          emails: emailsToDelete,
        };
        try {
          const response =
            await this.userRepositoryInstance.deleteFromEveryWhere(objToPush);
          this.closeConfirmDeleteFaculty();
          await this.fetchData();
          this.getFacultyAllocation();
          this.getStudentAllocation();
        } catch (error) {
          console.error("error", error);
        }
      }
      this.selectedFaculties = [];
      this.deleteFacultyLoader = false;
      // deleteFromEveryWhere
    },
    async deleteAdmins() {
      this.deleteAdminsLoader = true;
      let uIdsToDelete = [];
      let emailsToDelete = [];

      this.selectedAdmins.map((u) => {
        uIdsToDelete.push(u.uId);
        // if (Array.isArray(u.email)) {
        emailsToDelete.push(u.primaryEmail);
        // }
        // else {
        //   emailsToDelete.push(u.email);
        // }
      });

      if (uIdsToDelete.length > 0) {
        let objToPush = {
          uIds: uIdsToDelete,
          emails: emailsToDelete,
        };
        try {
          const response =
            await this.userRepositoryInstance.deleteFromEveryWhere(objToPush);
          await this.fetchData();
          this.getFacultyAllocation();
          this.getStudentAllocation();
        } catch (error) {
          console.error("error", error);
        }
      }
      this.selectedAdmins = [];
      this.deleteAdminsLoader = false;
      this.closeConfirmDeleteAdmin();
    },
    async deleteStudents() {
      this.deleteStudentsLoader = true;
      let uIdsToDelete = [];

      this.selectedStudents.map((u) => {
        uIdsToDelete.push(u.uId);
      });

      if (uIdsToDelete.length > 0) {
        let objToPush = {
          uIds: uIdsToDelete,
          emails: [],
        };
        try {
          const response =
            await this.userRepositoryInstance.deleteFromEveryWhere(objToPush);
          await this.fetchData();
          this.getFacultyAllocation();
          this.getStudentAllocation();
        } catch (error) {
          console.error("error", error);
        }
      }
      this.deleteStudentsLoader = false;
      this.closeConfirmDeleteStudent();
    },
    updatePerscetage() {
      const per = this.$store.getters["liveData/get_percentage"];
      this.percentage = numOps.roundOff(per.percentage);
    },
    async handleGenerateExcel() {
      let schema = {};
      let userType = "";
      if (this.tab === 0) {
        userType = "Admin";
        schema = this.adminExcelSchema;
      } else if (this.tab === 1) {
        userType = "Faculty";
        schema = this.facultyExcelSchema;
      } else if (this.tab === 2) {
        userType = "Student";
        schema = this.studentExcelSchema;
      }
      this.schema = {
        Sheet1: schema,
      };
      await this.downloadExcel(`Manage User Sample ${userType}.xlsx`);
    },
    async downloadExcel(excelName) {
      this.excelLink = await generateFromJson(this.schema);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = this.excelLink;
      link.target = "_blank"; // Open in a new tab

      // Set the custom filename
      link.setAttribute("download", excelName);

      // Append the link to the document, trigger the click, and then remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async getDesignationsOfInstitute() {
      this.designationDetails = [];
      try {
        let data =
          await this.instituteRepositoryInstance.getDesignationsOfInstitute({
            instituteId: this.selectedInstitute,
          });
        this.designationDetails = data.map((e) => e.name);
      } catch (err) {
        console.error("err", err);
      }
    },
    async getDepartments() {
      this.allDepartmentDetails = [];
      try {
        let deptData =
          await this.departmentDetailsRepositoryInstance.getDepartmentDetailsForInstitute(
            {
              instituteId: this.selectedInstitute,
            }
          );
        if (deptData.length > 0) {
          this.departments = await deptData.map((e) => e.departmentName);
        }
      } catch (err) {
        console.error("err", err);
      }
    },
    async fetchData() {
      this.dataTableLoading = true;
      this.totalStudentsAsSubAdmin = 0;
      this.totalFacultiesAsSubAdmin = 0;
      this.totalAdmins = 0;
      this.totalNonTeachingProfessors = 0;
      this.totalAssociateProfessors = 0;
      this.totalProfessors = 0;
      this.totalInstituteUsers = 0;
      this.totalFaculties = 0;
      this.totalStudents = 0;
      this.subAdminDetails = [];
      this.adminDetails = [];
      this.facultyDetails = [];
      this.studentDetails = [];
      this.allUsersExceptSubAdmins = [];
      this.allUserDetailsExceptSubAdmins = [];
      const objToPush = {
        instituteId: this.selectedInstitute,
      };

      this.allUIds = [];
      try {
        const instituteUsers =
          await this.instituteUserRepositoryInstance.getAllDetailsOfTheUsers(
            objToPush
          );

        instituteUsers.map((user) => {
          this.totalInstituteUsers++;
          let modifiedRoles = [];
          user.disabled = false;
          user.primaryEmail = "";
          user.modifiedDob = "";
          if (user.email) {
            user.primaryEmail = this.getPrimaryEmail(user.email);
          }
          if (user.uId === this.userData.uId) {
            user.disabled = true;
          }
          if (user.dateOfBirth) {
            user.modifiedDob = moment(user.dateOfBirth).format("DD-MM-YYYY");
          }
          if (user.isTeaching !== undefined) {
            user.modifiedFacultyType = user.isTeaching
              ? "Teaching"
              : "Non-Teaching";
          }
          getRoleObjectByValue();
          if (user.roles && user.roles.includes(3)) {
            if (user.roles.includes(0)) {
              this.totalStudentsAsSubAdmin++;
            }
            if (user.roles.includes(1)) {
              this.totalFacultiesAsSubAdmin++;
            }
            this.subAdminDetails.push(user);
            if (Array.isArray(user.roles)) {
              user.roles.map((r, ri) => {
                let objToPush = {};
                let data = getRoleObjectByValue(r);

                objToPush.role = r;
                objToPush.primaryColor = data.primaryColor;
                modifiedRoles.push({
                  role: objToPush.role,
                  primaryColor: objToPush.primaryColor,
                });
              });
            }
          }
          if (user.roles && user.roles.includes(2)) {
            this.totalAdmins++;
            this.adminDetails.push(user);
          }
          if (user.roles && user.roles.includes(1)) {
            this.totalFaculties++;
            if (user.designation === "Professor") {
              this.totalProfessors++;
            }
            if (user.designation === "Associate Professor") {
              this.totalAssociateProfessors++;
            }
            if (!user.isTeaching) {
              this.totalNonTeachingProfessors++;
            }
            this.facultyDetails.push(user);
            this.facultyUIds.push(user.uId);
          }
          if (user.roles && user.roles.includes(0)) {
            this.totalStudents++;
            this.studentDetails.push(user);
            this.studentUIds.push(user.uId);
          }
          if (user.roles && user.roles.includes(1) && !user.roles.includes(3)) {
            this.allUserDetailsExceptSubAdmins.push(user);
          }
          user.modifiedRoles = arrayOps.sortByProperty(
            modifiedRoles,
            "role",
            "asc"
          );
        });
        this.processData();
      } catch (err) {
        console.error(err);
      }
      this.dataTableLoading = false;
    },
    getPrimaryEmail(email) {
      let primaryEmail = "";
      if (Array.isArray(email)) {
        if (email.length > 0) {
          let lastIndex = email.length - 1;
          // set latest email as primary email. if empty then set previous latest.
          primaryEmail =
            email[lastIndex] !== "" ? email[lastIndex] : email[lastIndex - 1];
        } else {
          primaryEmail = email;
        }
      } else {
        primaryEmail = email;
      }
      return primaryEmail;
    },
    processData() {
      this.allUserDetailsExceptSubAdmins.map((user) => {
        let { primaryColor } = getRoleObjectByValue(user.roles[0]);
        user.primaryColor = primaryColor;
      });

      this.userRolesOnSubAdminPopUp = this.userRoles.filter(
        (u, i) => u.value === 1
      );

      this.userRolesForSubAdmin = this.userRoles.filter((u) => u.value === 3);

      this.adminExcelSchema = {
        "Email id": {
          validation: {
            type: "email",
            required: true,
            toLowerCase: true,
          },
          variableName: "primaryEmail",
        },
        Title: {
          validation: {
            type: "list",
            values: this.allTitles,
          },
          variableName: "title",
        },
        "First Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            required: true,
            capitalize: true,
          },
          variableName: "firstName",
        },
        "Middle Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            capitalize: true,
          },
          variableName: "middleName",
        },
        "Last Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            required: true,
            capitalize: true,
          },
          variableName: "lastName",
        },
        Gender: {
          validation: {
            type: "list",
            required: true,
            values: ["Male", "Female", "Other"],
          },
          variableName: "gender",
        },
        "Mobile number": {
          validation: {
            type: "phone",
            required: true,
          },
          variableName: "phone",
        },
        Dob: {
          validation: {
            type: "dateOfBirth",
            required: true,
          },
          variableName: "dateOfBirth",
        },
        "User Name": {
          validation: {
            type: "textLength",
            required: true,
            toLowerCase: true,
          },
          variableName: "userName",
        },
      };
      this.facultyExcelSchema = {
        "Email id": {
          validation: {
            type: "email",
            required: true,
            toLowerCase: true,
          },
          variableName: "primaryEmail",
        },
        "Core Department": {
          validation: {
            type: "list",
            values: this.departments,
            required: true,
          },
          variableName: "department",
        },
        "Faculty Type": {
          validation: {
            type: "list",
            values: this.facultyTypes,
            required: true,
          },
          variableName: "isTeaching",
        },
        Designation: {
          validation: {
            type: "list",
            values: this.designationDetails,
            required: true,
          },
          variableName: "designation",
        },
        Title: {
          validation: {
            type: "list",
            values: this.allTitles,
          },
          variableName: "title",
        },
        "First Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            required: true,
            capitalize: true,
          },
          variableName: "firstName",
        },
        "Middle Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            capitalize: true,
          },
          variableName: "middleName",
        },
        "Last Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            required: true,
            capitalize: true,
          },
          variableName: "lastName",
        },
        Gender: {
          validation: {
            type: "list",
            required: true,
            values: ["Male", "Female", "Other"],
          },
          variableName: "gender",
        },
        "Mobile number": {
          validation: {
            type: "phone",
            required: true,
          },
          variableName: "phone",
        },
        Dob: {
          validation: {
            type: "dateOfBirth",
            required: true,
          },
          variableName: "dateOfBirth",
        },
        "User Name": {
          validation: {
            type: "textLength",
            required: true,
            toLowerCase: true,
          },
          variableName: "userName",
        },
      };
      this.studentExcelSchema = {
        "Email id": {
          validation: {
            type: "email",
            required: true,
            toLowerCase: true,
          },
          variableName: "primaryEmail",
        },
        Title: {
          validation: {
            type: "list",
            values: this.allTitles,
          },
          variableName: "title",
        },
        "First Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            required: true,
            capitalize: true,
          },
          variableName: "firstName",
        },
        "Middle Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            capitalize: true,
          },
          variableName: "middleName",
        },
        "Last Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            required: true,
            capitalize: true,
          },
          variableName: "lastName",
        },
        Gender: {
          validation: {
            type: "list",
            required: true,
            values: ["Male", "Female", "Other"],
          },
          variableName: "gender",
        },
        "Mobile number": {
          validation: {
            type: "phone",
            required: true,
          },
          variableName: "phone",
        },
        Dob: {
          validation: {
            type: "dateOfBirth",
            required: true,
          },
          variableName: "dateOfBirth",
        },
        "User Name": {
          validation: {
            type: "textLength",
            required: true,
            toLowerCase: true,
          },
          variableName: "userName",
        },
        "GR Number": {
          validation: {
            type: "textLength",
          },
          variableName: "grNumber",
        },
        "abcId Number": {
          validation: {
            type: "textLength",
          },
          variableName: "abcId",
        },
        "College PRN": {
          validation: {
            type: "textLength",
          },
          variableName: "collegePRNNo",
        },
        "Parents Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            capitalize: true,
          },
          variableName: "parentsName",
        },
        "Mother Name": {
          validation: {
            type: "lettersWithSpaceOnly",
            capitalize: true,
          },
          variableName: "motherName",
        },
        "Parents Email": {
          validation: {
            type: "email",
            toLowerCase: true,
          },
          variableName: "parentsEmailId",
        },
        "Parents Number": {
          validation: {
            type: "phone",
          },
          variableName: "parentsMobile",
        },
      };
      if (
        this.userData.instituteId === "6604982c-cc08-4e00-81fb-a375a10b72e4"
      ) {
        this.currentInstitute = "stmiras";
      }

      if (this.currentInstitute === "stmiras ") {
        this.studentHeaders.push(
          {
            text: "ABCID Number",
            value: "abcIdNumber",
          },
          {
            text: "GR Number",
            value: "grNumber",
          }
        );
      }
    },
    async getFacultyAllocation() {
      this.totalFacultiesWithNoAllocations = 0;
      this.loadingAllocation = true;
      try {
        let objToPush = {
          instituteId: this.selectedInstitute,
          uIds: this.facultyUIds,
        };
        let response =
          await this.semesterUserRepositoryInstance.getFacultyAllocation(
            objToPush
          );
        this.facultyDetails.map((item) => {
          item["allocations"] = [];
          let result = response.filter((u) => u.uId === item.uId)[0];
          if (result) {
            item.allocations = result.allocations;
          }
          if (item.allocations.length === 0) {
            this.totalFacultiesWithNoAllocations++;
          }
        });
      } catch (error) {
        console.error("error", error);
      }
      this.loadingAllocation = false;
    },
    async getStudentAllocation() {
      this.totalStudentsWithNoAllocations = 0;
      try {
        let objToPush = {
          instituteId: this.selectedInstitute,
          uIds: this.studentUIds,
        };
        let response =
          await this.semesterUserRepositoryInstance.getStudentAllocation(
            objToPush
          );
        this.studentDetails.map((item) => {
          item["allocations"] = [];
          let result = response.filter((u) => u.uId === item.uId)[0];
          if (result) {
            item.allocations = result.allocations;
          }
          if (item.allocations.length === 0) {
            this.totalStudentsWithNoAllocations++;
          }
        });
      } catch (error) {
        console.error("error", error);
      }
    },
    async promoteToSubAdmin() {
      this.subAdminLoader = true;
      const uIdsForSubAdmin = this.usersToPromoteToSubAdmin.map((u) => u.uId);
      let objToPush = {
        userData: uIdsForSubAdmin,
        makeSubAdmin: true, //to promote to sub admin
      };
      try {
        await this.userRepositoryInstance.bulkUpdateSubAdmin(objToPush);
        this.closeSubAdminDialog();
        showStatus("Users promoted to the Sub Admin.", 7000, "success", this);
        await this.fetchData();
        this.getFacultyAllocation();
        this.getStudentAllocation();
      } catch (error) {
        showStatus(
          "Error promoting users to the Sub Admin.",
          7000,
          "warning",
          this
        );
        console.error("error", error);
      }
      this.subAdminLoader = false;
    },
    async demoteFromSubAdmin() {
      this.subAdminLoader = true;
      const uIdsForSubAdmin = this.usersToDemoteFromSubAdmin.map((u) => u.uId);
      let objToPush = {
        userData: uIdsForSubAdmin,
        makeSubAdmin: false, // demote from sub admin
      };
      if (uIdsForSubAdmin.length > 0) {
        try {
          await this.userRepositoryInstance.bulkUpdateSubAdmin(objToPush);
          this.subAdminLoader = false;
          this.closeDemoteSubAdminDialog();
          await this.fetchData();
          this.getFacultyAllocation();
          this.getStudentAllocation();
        } catch (error) {
          this.subAdminLoader = false;
          this.closeDemoteSubAdminDialog();
          console.error("error", error);
        }
      }
    },
    closeSubAdminDialog() {
      this.subAdminDialog = false;
    },
    openSubAdminDialog() {
      this.subAdminDialog = true;
    },
    handleSubAdminOperations() {
      this.isSubAdminDetailsEditable = !this.isSubAdminDetailsEditable;
    },
    gotoControlAccess() {
      let objToPush = {
        prop_tab: 5,
      };
      this.$router.push({
        name: "activityInstitutesManagment",
        params: objToPush,
      });
    },
    handleFacultyOperations() {
      this.selectedFaculties = [];
      this.isFacultyDetailsEditable = !this.isFacultyDetailsEditable;
    },
    handleAdminOperations() {
      this.selectedAdmins = [];
      this.isAdminDetailsEditable = !this.isAdminDetailsEditable;
    },
    handleStudentOperations() {
      this.selectedStudents = [];
      this.isStudentDetailsEditable = !this.isStudentDetailsEditable;
    },
    resetAllFields() {
      this.selectedAdmins = [];
      this.selectedFaculties = [];
      this.selectedStudents = [];
      this.invalidFaculties = [];
      this.invalidStudents = [];
      this.repeatedEmails = [];
      this.repeatedUserNames = [];
      this.repeatedPhones = [];
      this.singleUserUpdateError = "";
      this.usersToPromoteToSubAdmin = [];
      this.usersToDemoteFromSubAdmin = [];
      this.userToEdit = {};
      this.searchAdmin = "";
      this.searchFaculty = "";
      this.searchStudent = "";
      this.isAdminDetailsEditable = false;
      this.isFacultyDetailsEditable = false;
      this.isSubAdminDetailsEditable = false;
      this.isStudentDetailsEditable = false;
    },
    openDemoteSubAdminDialog() {
      let temp = this.usersToDemoteFromSubAdmin.filter(
        (user) => user.uId !== this.userData.uId
      );
      this.usersToDemoteFromSubAdmin = temp;
      this.demoteSubAdminDialog = true;
    },
    closeDemoteSubAdminDialog() {
      this.demoteSubAdminDialog = false;
    },
    confirmDeleteFaculty() {
      this.invalidFaculties = [];
      this.selectedFaculties.map((u) => {
        if (u.allocations.length !== 0) {
          this.invalidFaculties.push(
            `${u?.title || ""} ${u.firstName} ${u.middleName} ${u.lastName}`
          );
        }
      });
      this.confirmDeleteFacultyDialog = true;
    },
    confirmDeleteStudent() {
      this.invalidStudents = [];
      this.selectedStudents.map((u) => {
        if (u.allocations.length !== 0) {
          this.invalidStudents.push(
            `${u?.title || ""} ${u.firstName} ${u.middleName} ${u.lastName}`
          );
        }
      });
      this.confirmDeleteStudentDialog = true;
    },
    confirmDeleteAdmin() {
      let temp = this.selectedAdmins.filter(
        (user) => user.uId !== this.userData.uId
      );
      this.selectedAdmins = temp;
      this.confirmDeleteAdminDialog = true;
    },
    closeConfirmDeleteFaculty() {
      this.confirmDeleteFacultyDialog = false;
    },
    closeConfirmDeleteStudent() {
      this.confirmDeleteStudentDialog = false;
    },
    closeConfirmDeleteAdmin() {
      this.confirmDeleteAdminDialog = false;
    },
    openUploadUsersDialog() {
      this.showUploadUsersDialog = true;
    },
    async uploadExcel() {
      this.duplicateEmails = [];
      this.duplicateUserNames = [];
      this.duplicateMobileNumbers = [];
      this.existingEmailsInFirebase = [];

      let schema = {};
      if (this.tab === 0) {
        schema = this.adminExcelSchema;
      } else if (this.tab === 1) {
        schema = this.facultyExcelSchema;
      } else if (this.tab === 2) {
        schema = this.studentExcelSchema;
      }
      const schema1 = {
        Sheet1: schema,
      };
      this.ExcelErr = [];
      let res = await generateJsonFromExcel(this.file, schema1, false);
      let userData = [];
      res.data.Sheet1.map((item) => {
        let trimmedData = this.normalizeObject(item);
        trimmedData.roles = getRoleByIndex(this.tab);
        userData.push(trimmedData);
      });
      const { errors, data } = res;
      if (Array.isArray(errors) && errors.length > 0) {
        this.ExcelErr = errors;
      } else if (Array.isArray(data.Sheet1) && data.Sheet1.length > 0) {
        let res = this.validateUsersData(data.Sheet1);
        this.ExcelErr = [...errors, ...res];
      }
      if (userData.length === 0) {
        showStatus("Cannot upload empty file.", 7000, "warning", this);
        // this.closeFileUploadDialog();
        return;
      }
      if (userData.length > 0) {
        const response = this.findRepeatingFields(userData);
        this.repeatedEmails = response.repeatedEmails;
        this.repeatedUserNames = response.repeatedUserNames;
        this.repeatedPhones = response.repeatedPhones;
      }
      if (this.ExcelErr.length === 0) {
        this.usersUploadExcelLoading = true;
        const objToPush = {
          users: userData,
          instituteId: this.selectedInstitute,
          userType: getRoleByIndex(this.tab),
        };
        // let temp = setInterval(() => {
        //   this.updatePerscetage();
        // }, 100);
        try {
          const result =
            await this.userRepositoryInstance.addMultipleUsersOnBothSides(
              objToPush
            );
          if (!result.success) {
            this.duplicateEmails = [...result.data.duplicateEmails];
            this.duplicateUserNames = [...result.data.duplicateUserNames];
            this.duplicateMobileNumbers = [
              ...result.data.duplicateMobileNumbers,
            ];
            this.usersUploadExcelLoading = false;
          } else {
            this.existingEmailsInFirebase =
              result.data.existingEmailsInFirebase;
            if (this.existingEmailsInFirebase.length !== 0) {
              this.usersUploadExcelLoading = false;
            } else {
              this.closeFileUploadDialog();
              this.file = null;
              this.existingEmailsInFirebase = [];
              let totalUsersAdded = result?.data?.result?.length || "";
              showStatus(
                `${totalUsersAdded} users added successfully.`,
                7000,
                "success",
                this
              );
              await this.fetchData();
              this.getFacultyAllocation();
              this.getStudentAllocation();
            }
          }
        } catch (error) {
          showStatus("Error adding the users.", 9000, "error", this);
          console.error("error", error);
          this.closeFileUploadDialog();
        }
        // clearInterval(temp);
      }
    },
    findRepeatingFields(data) {
      const counts = {
        email: new Map(),
        userName: new Map(),
        phone: new Map(),
      };

      const repeated = {
        emails: new Set(),
        userNames: new Set(),
        phones: new Set(),
      };

      // Single loop for counting and detecting duplicates
      for (const item of data) {
        // Process email
        if (item.email) {
          if (counts.email.has(item.email)) {
            repeated.emails.add(item.email);
          } else {
            counts.email.set(item.email, 1);
          }
        }

        // Process userName
        if (item.userName) {
          if (counts.userName.has(item.userName)) {
            repeated.userNames.add(item.userName);
          } else {
            counts.userName.set(item.userName, 1);
          }
        }

        // Process phone
        if (item.phone) {
          if (counts.phone.has(item.phone)) {
            repeated.phones.add(item.phone);
          } else {
            counts.phone.set(item.phone, 1);
          }
        }
      }

      // Convert sets to arrays for the final result
      return {
        repeatedEmails: Array.from(repeated.emails),
        repeatedUserNames: Array.from(repeated.userNames),
        repeatedPhones: Array.from(repeated.phones),
      };
    },
    normalizeObject(obj) {
      // Centralized pattern handlers
      const handlers = {
        // Handle 'richText' arrays
        richText: (value) => {
          if (Array.isArray(value.richText)) {
            return value.richText
              .map((item) => (item.text ? item.text.trim() : ""))
              .join(" ");
          }
          return value; // Fallback if not a valid richText object
        },
        // Handle objects with a 'text' property
        text: (value) => {
          if ("text" in value && typeof value.text === "string") {
            return value.text.trim();
          }
          return value; // Fallback if not a valid text object
        },
      };

      // Recursive normalization function
      const normalizeValue = (value) => {
        if (typeof value === "string") {
          return value.trim();
        } else if (Array.isArray(value)) {
          // Normalize each item in the array
          return value.map((item) => normalizeValue(item));
        } else if (value && typeof value === "object") {
          // Apply centralized pattern handlers
          for (const key in handlers) {
            if (key in value) {
              return handlers[key](value);
            }
          }
          // Recursively normalize nested objects
          return this.normalizeObject(value);
        }
        return value; // Return value as-is if it doesn't match any pattern
      };

      // Normalize each property in the object
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = normalizeValue(obj[key]);
        }
      }
      return obj;
    },
    validateUsersData(arr) {
      let err = [];
      arr.map((e, i) => {
        if (e.roles === "FACULTY") {
          if (e.department === undefined || e.department === "") {
            err.push(
              `Code Department cannot be empty for Faculty ${e?.title || ""} ${
                e.firstName
              } ${e.lastName}`
            );
          }
          if (e.isTeaching === undefined || e.isTeaching === "") {
            err.push(
              `Faculty Type cannot be empty for Faculty ${e?.title || ""} ${
                e.firstName
              } ${e.lastName}`
            );
          }
          if (e.designation === undefined || e.designation === "") {
            err.push(
              `designation cannot be empty for Faculty ${e?.title || ""} ${
                e.firstName
              } ${e.lastName}`
            );
          }
        }
      });
      return err;
    },
    updateUsersExcelDialog() {
      this.percentage = 0;
      this.excelValidationErrors = [];
      this.existingEmails = [];
      this.updateUsersFile = null;
      this.updateUsersDialog = !this.updateUsersDialog;
    },
    async updateMultipleUsersViaExcel() {
      this.duplicateEmails = [];
      this.duplicateUserNames = [];
      this.duplicateMobileNumbers = [];
      this.updateUsersLoader = true;
      this.excelValidationErrors = [];
      const instututeData = {
        instituteId: this.selectedInstitute,
      };
      let schema = {};
      if (this.tab === 0) {
        schema = this.adminExcelSchema;
      } else if (this.tab === 1) {
        schema = this.facultyExcelSchema;
      } else if (this.tab === 2) {
        schema = this.studentExcelSchema;
      }
      const schema1 = {
        Sheet1: schema,
      };

      this.ExcelErr = [];
      let roles = Object.keys(ROLES);
      let { errors, data } = await generateJsonFromExcel(
        this.updateUserFile,
        schema1,
        false
      );
      if (Array.isArray(errors) && errors.length > 0) {
        this.ExcelErr = errors;
      } else if (Array.isArray(data.Sheet1) && data.Sheet1.length > 0) {
        let res = this.validateUsersData(data.Sheet1);
        this.ExcelErr = [...errors, ...res];
      }
      let parsedData = [];
      data.Sheet1.map((item) => {
        let trimmedData = this.normalizeObject(item);
        trimmedData.roles = getRoleByIndex(this.tab);
        parsedData.push(trimmedData);
      });
      let newArray = [];
      let emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      parsedData.map((obj, i) => {
        let objToReturn = {};
        if (obj.department && obj.department !== "") {
          if (!this.departments?.includes(obj.department)) {
            this.excelValidationErrors.push(
              `Invalid department name entered for ${obj?.title || ""} ${
                obj.firstName
              } ${obj.lastName} at row number ${i + 2}`
            );
          }
        }
        if (obj.isTeaching !== undefined) {
          obj.isTeaching = obj.isTeaching === "Teaching" ? true : false;
        }
        if (obj.roles && obj.roles !== "") {
          if (!roles?.includes(obj.roles)) {
            this.excelValidationErrors.push(
              `Invalid role entered for ${obj?.title || ""} ${obj.firstName} ${
                obj.lastName
              } at row number ${i + 2}`
            );
          }
        }
        if (obj.designation && obj.designation !== "") {
          if (!this.designationDetails?.includes(obj.designation)) {
            this.excelValidationErrors.push(
              `Invalid designation entered for ${obj?.title || ""} ${
                obj.firstName
              } ${obj.lastName} at row number ${i + 2}`
            );
          }
        }
        if (obj.email) {
          let tempEmail = obj.email.text ? obj.email.text : obj.email;
          if (!emailRegex.test(tempEmail.trim())) {
            this.excelValidationErrors.push(
              `Invalid email entered for ${obj?.title || ""} ${obj.firstName} ${
                obj.lastName
              } at row number ${i + 2}`
            );
          }
        }

        for (const [key, value] of Object.entries(obj)) {
          if (value !== undefined) {
            if (value !== "") {
              objToReturn[key] = value;
            }
          }
        }
        newArray.push(objToReturn);
      });
      if (
        this.excelValidationErrors.length === 0 &&
        this.ExcelErr.length === 0 &&
        newArray.length > 0
      ) {
        const objToPush = {
          users: newArray,
          instituteId: this.selectedInstitute,
        };
        // let temp = setInterval(() => {
        //   this.updatePerscetage();
        // }, 100);
        try {
          const result =
            await this.userRepositoryInstance.updateMultipleUsersOnBothSides(
              objToPush
            );
          if (!result.success) {
            this.duplicateEmails = result.data.duplicateEmails;
            this.duplicateUserNames = result.data.duplicateUserNames;
            this.duplicateMobileNumbers = result.data.duplicateMobileNumbers;
            // clearInterval(temp);
          } else {
            showStatus("Users updated successfully.", 4000, "success", this);
            this.closeUpdateUserDialog();
            await this.fetchData();
            this.getFacultyAllocation();
            this.getStudentAllocation();
          }
        } catch (error) {
          showStatus("Error updating the users.", 9000, "error", this);
          console.error("error", error);
        }
      }
      this.updateUsersLoader = false;
      this.$store.commit("liveData/set_percentage", {
        percentage: 0,
      });
    },
    async generateExistingUsersExcel() {
      const tabNames = {
        0: "Admins",
        1: "Faculties",
        2: "Students",
        3: "Sub Admins",
      };

      const excelName = tabNames[this.tab] || "Users";
      this.excelDataLoader = true;
      let userArray = [];
      if (this.tab === 0) {
        userArray = this.adminDetails;
      } else if (this.tab === 1) {
        userArray = this.facultyDetails;
      } else if (this.tab === 2) {
        userArray = this.studentDetails;
      } else if (this.tab === 2) {
        userArray = this.subAdminDetails;
      }
      const array = userArray.map((user) => {
        let tempFacultyType = "";
        if (user.isTeaching === undefined) {
          tempFacultyType = "";
        } else {
          tempFacultyType = user.isTeaching ? "Teaching" : "Non-Teaching";
        }
        // let dob = "";
        // if (user.dateOfBirth) {
        //   dob = user?.dateOfBirth?.includes("T")
        //     ? user.dateOfBirth.split("T")[0]
        //     : user.dateOfBirth;
        // }
        let objToReturn = {};
        if (this.tab === 0) {
          // Admin
          objToReturn = {
            Email: user.primaryEmail,
            Title: user.title,
            "First Name": user.firstName,
            "Middle Name": user.middleName,
            "Last Name": user.lastName,
            Gender: user.gender,
            "Mobile number": user.phone,
            Dob: user.modifiedDob,
            "User Name": user?.userName,
          };
        } else if (this.tab === 1) {
          // Faculty
          objToReturn = {
            Email: user.primaryEmail,
            "Core Department": user.department ?? "",
            "Faculty Type": tempFacultyType,
            Designation: user.designation,
            Title: user.title,
            "First Name": user.firstName,
            "Middle Name": user.middleName,
            "Last Name": user.lastName,
            Gender: user.gender,
            "Mobile number": user.phone,
            Dob: user.modifiedDob,
            "User Name": user?.userName,
          };
        } else if (this.tab === 2) {
          // Student
          objToReturn = {
            Email: user.primaryEmail,
            Title: user.title,
            "First Name": user.firstName,
            "Middle Name": user.middleName,
            "Last Name": user.lastName,
            Gender: user.gender,
            "Mobile number": user.phone,
            Dob: user.modifiedDob,
            "User Name": user?.userName,
            "GR Number": user.grNumber,
            "ABCID Number": user.abcIdNumber,
            "College PRN": user.collegePRNNo,
            "Parents Name": user?.parentsName,
            "Mother Name": user?.motherName,
            "Parents Email": user?.parentsEmailId,
            "Parents Number": user?.parentsMobile,
          };
        }
        return objToReturn;
      });
      let schema = {};
      if (this.tab === 0) {
        schema = this.adminExcelSchema;
      } else if (this.tab === 1) {
        schema = this.facultyExcelSchema;
      } else if (this.tab === 2) {
        schema = this.studentExcelSchema;
      }
      const schema1 = {
        Sheet1: schema,
      };
      createExcelThroughJsonAndSchema(array, schema1, excelName);
      // createExcelThroughJson(array, 'Users')
      this.excelDataLoader = false;
    },
    closeEditUserDialog() {
      this.editUserDialog = false;
      this.isSubAdmin = "false";
      this.selectedUser = "";
      this.tempFirstName = "";
      this.tempMotherName = "";
      this.tempInstituteProvidedId = "";
      this.tempMiddleName = "";
      this.tempLastName = "";
      this.tempEmails = [];
      this.tempPhones = [];
      this.tempGender = "";
      this.tempDateOfBirth = "";
      this.tempBloodGroup = "";
      this.tempUserName = "";
      this.tempSetPassword = "";
      this.role = "";
    },
    async getSalutations() {
      try {
        const data = await auth.getNameTitlesFromFirebase();
        this.allTitles = arrayOps.extractKey(data, "abbreviation");
      } catch (error) {
        console.error(error);
      }
    },
    async portalNavigation(userName) {
      if (!userName) {
        showStatus(
          "Cannot access the username for this user.",
          9000,
          "error",
          this
        );
        return;
      }
      const userObj = {
        roles: this.role,
        userName: this.userName,
        email: this.tempEmails[this.tempEmails.length - 1],
        userName: this.tempUserName,
        uId: this.selectedUser.uId,
        isRedirectedFromAdmin: true,
        uuId: uuidv4(),
      };

      await auth.portalNavigationSecurity({
        userName,
        uId: this.selectedUser.uId,
        authenticationId: userObj.uuId,
        adminUId: this.userData.uId,
      });
      const baseUrl =
        userObj.roles === "STUDENT"
          ? process.env.VUE_APP_STUDENT_PORTAL
          : userObj.roles === "FACULTY"
          ? process.env.VUE_APP_FACULTY_PORTAL
          : "";

      const loginUrl = baseUrl
        ? `${baseUrl}?userName=${encodeURIComponent(
            userName
          )}&isRedirectedFromAdmin=${userObj.isRedirectedFromAdmin}&uId=${
            this.selectedUser.uId
          }&uuId=${userObj.uuId}&adminUId=${this.userData.uId}`
        : "";

      if (baseUrl) {
        window.open(loginUrl, "_blank");
      }
    },
  },
};
</script>

<style src="./activityManageUsers.css" scoped></style>
