<template>
  <div class="circle-container" :class="containerClass">
    <div
      v-for="(entry, index) in normalizedTextEntries"
      :key="index"
      class="circle-wrapper"
      :class="entry.position"
    >
      <div
        class="circle"
        :style="{
          backgroundColor: entry.primaryColor || backgroundColor,
          borderColor: entry.borderColor || borderColor,
          width: entry.size || size,
          height: entry.size || size,
          borderWidth: borderWidth,
        }"
        @click="entry.onClick ? handleClick(entry) : null"
      ></div>
      <p class="circle-text" :style="{ fontSize: entry.textSize }">
        {{ entry.text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CircleComponent",
  props: {
    backgroundColor: {
      type: String,
      default: "#3498db", // Default background color (blue)
    },
    borderColor: {
      type: String,
      default: "transparent", // Default border color (transparent)
    },
    size: {
      type: String,
      default: "14px", // Default size (diameter) for the circular tag
    },
    borderWidth: {
      type: String,
      default: "1px", // Default border width
    },
    textEntries: {
      type: Array,
      default: () => [], // Array of text entries
    },
    textSize: {
      type: String,
      default: "14px", // Default text size
    },
    position: {
      type: String,
      default: "right", // Default overall position
      validator: (value) => ["left", "center", "right"].includes(value), // Only allow valid values
    },
  },
  computed: {
    normalizedTextEntries() {
      return this.textEntries.map((entry) => ({
        ...entry,
        position: entry.position || "right", // Default position to 'right'
        textSize: entry.textSize || this.textSize, // Default text size to '14px'
        size: entry.size || this.size, // Default circular size to '14px'
      }));
    },
    containerClass() {
      // Apply the appropriate class based on the position prop
      return {
        "flex-left": this.position === "left",
        "flex-center": this.position === "center",
        "flex-right": this.position === "right",
      };
    },
  },
  methods: {
    handleClick(entry) {
      // Call the onClick function from the entry if it exists
      if (entry.onClick) {
        entry.onClick(entry); // Pass the entry data to the onClick function
      } else {
        console.log("Circle clicked: ", entry.text);
      }
    },
  },
};
</script>

<style scoped>
.circle-container {
  display: flex;
  gap: 20px; /* Space between tags */
  justify-content: flex-end; /* Default alignment to the right */
}

.flex-left {
  justify-content: flex-start; /* Align to the left */
}

.flex-center {
  justify-content: center; /* Align to the center */
}

.flex-right {
  justify-content: flex-end; /* Align to the right */
}

.circle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.circle-wrapper.left,
.circle-wrapper.right {
  flex-direction: row;
}

.circle-wrapper.left .circle-text {
  margin-right: 10px;
}

.circle-wrapper.right .circle-text {
  margin-left: 10px;
}

.circle-wrapper.top .circle-text {
  margin-bottom: 10px;
}

.circle-wrapper.bottom .circle-text {
  margin-top: 10px;
}

.circle {
  border-radius: 50%;
  display: inline-block;
  border-style: solid;
  box-sizing: border-box; /* Ensure border is included in the size */
  cursor: pointer; /* Add pointer cursor for interactivity */
}

.circle-text {
  margin: 5px;
  color: #333; /* Default text color */
}
</style>
