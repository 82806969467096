<template src="./activitySettings.html"></template>

<script>
import showStatus from "../../../../NetworkManager/showStatus";
import NewFeedbackFormRepository from "../../../../Repository/Feedbackform";
export default {
  name: "activitySettings",
  data() {
    return {
      backgroundColor: "#ECECFF",
      allTypesOfFeedbacks: [
        "Course Feedback",
        "Program Feedback",
        // "Teachers effectiveness",
        // "Alumni feedback",
        // "Employer feedback",
        // "Guest feedback",
        // "Parents feedback",
        // "Infrastructure feedback",
        // "Event feedback",
        "Other Feedback",
      ],
      allStakeHolders: [],
      // allStakeHolders: [
      //   "Students",
      //   "Faculty",
      //   "Parents",
      //   "Alumni",
      //   "Employer/Recruiters",
      //   "Guest",
      //   "Specific Students",
      //   "Specific Faculties",
      // ],
      feedbackWiseStakeHolderList: [
        {
          typeOfFeedback: "Course Feedback",
          stackholders: ["Students"],
        },
        {
          typeOfFeedback: "Program Feedback",
          stackholders: ["Students"],
        },
        {
          typeOfFeedback: "Teachers effectiveness",
          stackholders: ["Faculty"],
        },
        {
          typeOfFeedback: "Alumni feedback",
          stackholders: ["Alumni", "Parents", "Guest"],
        },
        {
          typeOfFeedback: "Employer feedback",
          stackholders: ["Employer/Recruiters"],
        },
        {
          typeOfFeedback: "Guest feedback",
          stackholders: ["Guest"],
        },
        {
          typeOfFeedback: "Parents feedback",
          stackholders: ["Parents"],
        },
        {
          typeOfFeedback: "Infrastructure feedback",
          stackholders: [],
        },
        {
          typeOfFeedback: "Event feedback",
          stackholders: [
            "Parents",
            "Alumni",
            "Employer/Recruiters",
            "Guest",
            "Parents",
            "Specific Students",
            "Specific Faculties",
          ],
        },
        {
          typeOfFeedback: "Other Feedback",
          stackholders: [
            "Students",
            "Faculty",
            // "Parents",
            // "Alumni",
            // "Employer/Recruiters",
            // "Guest",
            // "Specific Students",
            // "Specific Faculties",
          ],
        },
      ],
      templateName: "",
      allowResubmission: false,
      resubmissionAttempt: 1,
      selectedStakeholder: [],
      selectedFeedback: "",
      isScaleChange: "No",
      selectedDepartment: undefined,
      templateData: [],
      templateId: undefined,
      template: {},
      lengthOfQuestions: true,
      allFeedbackDetails: [
        {
          srNo: 1,
          details: "Student Name",
          type: "Compulsory",
        },
      ],
      allTypes: ["Compulsory", "Optional"],
      filterdFeedbacks: [],
      allQuestions: [],
    };
  },
  props: ["prop_settings"],
  activated() {},
  async created() {
    this.NewFeedbackFormRepositoryInstance = new NewFeedbackFormRepository(
      this
    );
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.template = this.$store.getters["liveData/get_templateTabDependencies"];
    if (this.template?.templateId !== undefined) {
      this.selectedFeedback = this.template.typeOfFeedback;
      this.selectedStakeholder = this.template.stakeHolders;
      this.allQuestions = this.template.allQuestions;
      this.template.tempQuestions = JSON.parse(
        JSON.stringify(this.template.allQuestions)
      );

      this.handleTypeOfFeedbacks();
      if (this.template?.stakeHolderDetails?.length > 0) {
        this.allFeedbackDetails = [];
        let srNo = 0;
        this.template?.stakeHolderDetails?.forEach((details) => {
          srNo++;
          this.allFeedbackDetails.push({
            srNo: srNo,
            details: Object.keys(details)[0],
            type: Object.values(details)[0],
          });
        });
      }
      if (this.template?.allowResubmission) {
        this.allowResubmission = this.template?.allowResubmission;
        this.resubmissionAttempt = this.template?.resubmissionAttempt;
      }
    }
    this.templateId = this.template?.templateId;
    this.selectedDepartment = this.prop_settings.department;

    const createdTemplatesSet = new Set(this.prop_settings.createdTemplates);

    this.filterdFeedbacks = this.allTypesOfFeedbacks.filter((item) => {
      // Always keep "Other feedback" in the filtered results
      if (item === "Other Feedback") {
        return true;
      }
      // Exclude items that are in the createdTemplatesSet
      return !createdTemplatesSet.has(item);
    });
  },
  methods: {
    resetValues() {
      this.selectedTypeOfQuestions = [];
      this.isScaleChange = "No";
      this.settingId = "";
    },
    areRequiredFieldsEmpty() {
      return (
        this.selectedFeedback === undefined ||
        this.selectedStakeholder === undefined ||
        this.selectedStakeholder?.length === 0
      );
    },
    getCommonObjToPush() {
      let objToPush = {
        instituteId: this.selectedInstitute,
        stakeHolders: this.selectedStakeholder,
        typeOfFeedback: this.selectedFeedback,
        templateId: this.templateId ?? undefined,
        allQuestions: this.allQuestions ?? [],
        stakeHolderDetails: this.allFeedbackDetails
          .filter((feedback) => feedback.details && feedback.type)
          .map((feedback) => ({ [feedback.details]: feedback.type })),
        allowResubmission: this.allowResubmission,
        resubmissionAttempt: this.allowResubmission
          ? this.resubmissionAttempt
          : 0,
        tempQuestions: this.template.tempQuestions,
      };
      return objToPush;
    },
    async validateTemplateDetails() {
      if (this.areRequiredFieldsEmpty()) {
        showStatus("Please Fill Required Fields First", 9000, "warning", this);
      } else {
        await this.handleQuestionSettings();
      }
    },
    handleTypeOfFeedbacks() {
      // this.selectedStakeholder = [];
      let selectedStackholder = this.feedbackWiseStakeHolderList.find(
        (item) => item.typeOfFeedback === this.selectedFeedback
      );
      this.allStakeHolders = selectedStackholder?.stackholders;
      this.$emit("selectedFeedback", this.selectedFeedback);
    },
    addStakeholder() {
      let length = this.allFeedbackDetails?.length + 1;
      let obj = {
        srNo: length,
        details: "",
        type: "",
      };
      this.allFeedbackDetails.push(obj);
    },
    removeStakeholder(index) {
      this.allFeedbackDetails.splice(index, 1);
      for (let i = index; i < this.allFeedbackDetails.length; i++) {
        this.allFeedbackDetails[i].srNo = i + 1;
      }
    },
    async handleQuestionSettings() {
      try {
        const objToPush = this.getCommonObjToPush();
        const response =
          await this.NewFeedbackFormRepositoryInstance.createTemplates(
            objToPush
          );
        this.$store.commit(
          "liveData/set_templateTabDependencies",
          response.template
        );
        this.templateId = response.template?.templateId;

        if (response.status.status === 200) {
          this.lengthOfQuestions = !(this.selectedTypeOfQuestions?.length > 0);
          showStatus("Successfully saved.", 2000, "success", this);
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
      } catch (error) {
        console.error(error);
      }
    },
    goToNextPage() {
      if (this.areRequiredFieldsEmpty()) {
        showStatus("Please Fill Required Fields First", 9000, "warning", this);
      } else {
        const objToPush = this.getCommonObjToPush();
        this.$store.commit("liveData/set_templateTabDependencies", objToPush);
        this.$emit("nextButtonClick");
      }
    },
  },
};
</script>

<style src="./activitySettings.css" scope></style>
