<template src="./activityReferenceAcademicDetails.html"></template>
<script>
import inputContainer from '../../Components/inputContainer'
import { instituteCardsInfo } from '../../Services/Utils/allowedModule';
import departmentDetailsRepository from '../../Repository/DepartmentDetails';
import InstituteRepository from '../../Repository/Institute'
import showStatus from '../../NetworkManager/showStatus'
import { generateJsonFromExcel, generateFromJson, createExcelThroughJson } from "../../utils/excel"

export default {
    name: 'activityReferenceAcademicDetails',
    components: {
        inputContainer
    },
    props:["prop_tab"],
    data() {
        return {
            errorMessages: '',
            dynamicDisc: '',
            showDialog: false,
            dataRemoveWarning: false,
            designationCreateDialog: false,
            departmentValueForCreate: [],
            createDeptDesignation: {},
            headers: [
                { text: 'Department', value: 'departmentName' },
                { text: 'Designation type', value: 'designationType' },
                { text: 'Teaching Hours', value: 'teachingHours' },
                { text: 'Mentoring Hours', value: 'mentoringHours' },
                { text: 'Administrative Work Hours', value: 'administrativeWorkHours' },
                { text: 'Other Hours', value: 'otherHours' },
                { text: 'Total Hours', value: 'totalHours' },
            ],
            headersForDept: [
                { text: 'Dept', value: 'departmentName' },
                { text: 'Dept Code', value: 'departmentCode' },
                { text: 'Course Year', value: 'courseYearName' },
                { text: 'Credits', value: 'credits' },
                { text: 'Total Teaching weeks', value: 'totalTeachingWeeks' },
                { text: 'Total Non Teaching weeks', value: 'totalNonTeachingWeeks' },
                { text: 'Other Weeks', value: 'otherWeeks' },
                { text: 'Division', value: 'divisionList', sortable: false },
            ],

            loaderForDeptStructure: false,
            totalHrsTableData: [],
            excelLink: '',
            panel: '',
            panel2: '',
            designationReset: {},
            editDesignationDept: {},
            editDesignationDept2: {},
            departmentValueForEdit: [],
            designationDataWithHours2: [],
            designationEditDialog: false,
            designationDeleteDialog: false,
            searchDepartmentOfDesignation: '',
            designationAndDiscFlag: false,
            selectedDeptForDesignation: [],
            instituteDetails: [],
            deptNameArr: [],
            noOfDesignations: 0,
            designationDataWithHours: [],
            designationInformation: [
                {
                    name: 'Dean/Director',
                    description: '',
                    // teachingHours: 0,
                    // mentoringHours: 0,
                    // administrativeWorkHours: 0,
                    // otherHours: 0
                },
                {
                    name: 'Principal/ HOD',
                    description: '',
                    // teachingHours: 0,
                    // mentoringHours: 0,
                    // administrativeWorkHours: 0,
                    // otherHours: 0
                },
                {
                    name: 'Professor',
                    description: '',
                    // teachingHours: 0,
                    // mentoringHours: 0,
                    // administrativeWorkHours: 0,
                    // otherHours: 0
                },
                {
                    name: 'Associate Professor',
                    description: '',
                    // teachingHours: 0,
                    // mentoringHours: 0,
                    // administrativeWorkHours: 0,
                    // otherHours: 0
                },
                {
                    name: 'Assistant  Professor',
                    description: '',
                    // teachingHours: 0,
                    // mentoringHours: 0,
                    // administrativeWorkHours: 0,
                    // otherHours: 0
                },
            ],
            isAllDataFilledForUpdate: false,
            editDeptCourseDataLength: 0,
            courseYearDetailsForEdit: [],
            departmentEditDialog: false,
            isAllDataFilled: false,
            departmentDeleteDialog: false,
            departmentForEdit: {},
            departmentForDelete: {},
            departmentName: '',
            departmentCode: '',
            allDepartmentDetails: [],
            selectedInstitute: '',
            instituteCards: [],
            accessibleModule: [],
            courseYearCountForNewDept: 0,
            dummyArrForCourseYearCount: [],
            isSubAdmin: false,
            accessIndex: [],
            tabs: 0,
            addDepartmentStructure: false,
            activityListDialog: false,
            structureTabs: 0,
            structureTabsArr: ['Setup of Structure', 'Faculty Designation-wise Workload', 'Required Workload'],
            mainTabNames: ['Basic Details', 'Structure', 'Courses', 'Template', 'Infrastructure', 'Control Access', 'Manage Slot'],
            activityNames: ['Lectures', 'Practical', 'Tutorial', 'Extra Lecture', 'Seminar', 'Course Development', 'innovative Teaching Activities',
                'Events', 'Invigilation', 'Paper Setting', 'Mentoring', 'Answer Sheet evaluation', 'Committee Work', 'Internal Assessment Evaluation',
                'Professional Development', 'Research', 'Community Engagement Activities', 'Admin duties - Exam Form filling session'],
            headersForStructureSetup: [
                {
                    text: 'Dept',
                    value: 'departmentName'
                },
                {
                    text: 'Dept Code',
                    value: 'departmentCode'
                },
                {
                    text: 'Course Year',
                    value: 'courseYearName'
                },
                {
                    text: 'Credits',
                    value: 'Credits'
                },
                {
                    text: 'Total Teaching weeks',
                    value: 'TotalTeachingWeeks'
                },
                {
                    text: 'Total Non Teaching weeks',
                    value: 'TotalNonTeachingWeeks'
                },
                {
                    text: 'Other Weeks',
                    value: 'OtherWeeks'
                },
                {
                    text: 'Divisions',
                    value: 'division'
                },
            ],
            headersForLoad: [
                {
                    text: 'Designation',
                    value: 'designation',
                    width: '150px'

                },
                {
                    text: 'Teaching Load',
                    value: 'teachingLoad',
                    width: '200px'
                },
                {
                    text: 'Non Teaching Load',
                    value: 'nonTeachingLoad',
                    width: '200px'

                },
                {
                    text: 'Other Load',
                    value: 'otherLoad',
                    width: '200px'

                },
                {
                    text: 'Total',
                    value: 'total',
                    width: '200px'

                },
            ],
            headersForCreditDist: [
                {
                    text: 'Department',
                    value: 'departments'
                },
                {
                    text: 'Credit',
                    value: 'credit'
                },
                {
                    text: 'Total Teaching Hour',
                    value: 'TotalTeachingHour'
                },
                {
                    text: 'Assessment Marks',
                    value: 'AssessmentMarks'
                },
            ],
            structureDataArr: [],
            creditDisDataArr: [],
            flattenedData2: [],
            flattenedData: [],
            finalActivityList: [],
            loadFattenData: [],
            editActivityArr: [],
            selectedDeptForActivity: [],
            selectedCopyToDept: [],
            totalLoadArr: [],
            selectedDeptForAddHr: [],
            headersForTeaching: [{
                text: 'Designation',
                value: 'name',
                width: '150px',
                isOpen: false

            },
            {
                text: 'Total Hours',
                value: 'total',
                width: '150px'

            },
            ],
            headersForNonTeaching: [
                {
                    text: 'Designation',
                    value: 'designationName',
                    width: '150px'

                },
                {
                    text: 'Total Hours',
                    value: 'total',
                    width: '150px'

                },
            ],
            headersForOther: [
                {
                    text: 'Designation',
                    value: 'designationName',
                    width: '150px'

                },
                {
                    text: 'Total Hours',
                    value: 'total',
                    width: '150px'

                },
            ],
            editLoadActivityDialog: false,
            editedDeptLoadActivity: '',
            editMultipleLoadActivityDialog: false,
            deptWiseTeachingLoadHr: [],
            deptWiseNonTeachingLoadHr: [],
            deptWiseOtherLoadHr: [],
            allDesignationDataForDept: [],
            allDataOfDesignation: [],
            copyToDeptName: [],
            copyMultipleDialog: false,
            typeArr: ['TeachingLoad', 'NonTeachingLoad', 'OtherLoad'],
        }
    },
    async created() {
        this.noOfDesignations = this.designationInformation.length
        this.departmentForEdit = {
            departmentName: '',
            departmentCode: '',
            courseYearDetails: [],
        }
        this.departmentDetailsRepositoryInstance = new departmentDetailsRepository(this)
        this.instituteRepositoryInstance = new InstituteRepository(this)

        this.instituteCards = instituteCardsInfo
        // this.$store.commit('liveData/set_selectedActivityName', 'Institute Management')
        this.selectedInstitute = this.$store.getters[
            'instituteData/get_selectedInstitute'
        ]
        const isSubAdmin = this.$store.getters['liveData/get_isSubAdmin']
        this.isSubAdmin = isSubAdmin?.isSubAdmin ?? false
        if (this.isSubAdmin === true) {
            const module = this.$store.getters['liveData/get_allowedModule']
            this.accessibleModule = module
            this.accessibleModule[1].subModule.map((data, index) => {
                if (data.isAccessForSub === true) {
                    this.accessIndex.push(index)
                }
            })
        } else {
            if (this.isSubAdmin === false) {
                this.instituteCards.map((data, index) => {
                    this.accessIndex.push(index)
                })
            }
        }
        await this.getDepartmentOfInstitute()
        if(this.prop_tab){
            this.structureTabs = this.prop_tab
        }
    },

    methods: {
        totalChanged(item) {
            // console.log('item', item);
            item.nonTeachingHours = Number(item.nonTeachingHours)
            item.teachingHours = Number(item.teachingHours)
            item.otherHours = Number(item.otherHours)
            item.total =  item.nonTeachingHours + item.teachingHours + item.otherHours
        },
        async saveTotalHrs() {
            try {
                let arr = []
                this.allDepartmentDetails.map((dept) => {
                    let hrsArray = []
                    if (this.totalHrsTableData.length > 0) {
                        this.totalHrsTableData.map((table) => {
                            if (table.departmentName === dept.departmentName) {
                                hrsArray.push(table)
                            }
                        })
                    }
                    let obj = {
                        instituteId: dept.instituteId,
                        departmentDetailsId: dept.departmentDetailsId,
                        designationHours: hrsArray
                    }
                    arr.push(obj)
                })
                // console.log('arr', arr);
                await this.departmentDetailsRepositoryInstance.updateDesignationHourForDepartmentInBulk(arr)
                this.getDepartmentOfInstitute()
            } catch (err) {
                console.error('err', err);
            }
        },
        addProperty(name, item) {
            item[name] = 0
            let data = {}
            if (this.allDepartmentDetails.length > 0) {
                this.allDepartmentDetails.map((dept) => {
                    if (dept.departmentName === this.selectedDeptForAddHr) {
                        data = dept
                    }
                })
            }
            if (data.loadDistributionDetails && data.loadDistributionDetails.length > 0) {
                data.loadDistributionDetails.map((load, k) => {
                    let [type, designation, activity] = name.split('_')
                    if (load.type === type && designation === load.designationName && activity === load.activityName) {
                        item[name] = load.activityHours
                        if (!item['allActivity'].includes(name)) {
                            item['allActivity'].push(name)
                        }
                    }
                })
            }
        },
        getModelValue() {
            return 'abc'
        },
        async addMultipleDeptActivity() {
            this.copyMultipleDialog = false
            let data = {}
            if (this.allDepartmentDetails.length > 0) {

                this.allDepartmentDetails.map((dept) => {
                    if (dept.departmentName === this.selectedDeptForActivity) {
                        data = dept
                    }
                })
            }
            if (this.selectedCopyToDept.length > 0) {
                let value = {}
                for (let i = 0; i < this.selectedCopyToDept.length; i++) {
                    this.allDepartmentDetails.map((dept) => {
                        if (dept.departmentName === this.selectedCopyToDept[i]) {
                            value = dept
                        }
                    })
                    try {
                        await this.departmentDetailsRepositoryInstance.updateDepartmentDocument({
                            instituteId: this.selectedInstitute,
                            departmentDetailsId: value.departmentDetailsId,
                            departmentName: value.departmentName,
                            loadDistributionDetails: data.loadDistributionDetails
                        })
                        this.getDepartmentOfInstitute()
                    } catch (err) {
                        console.error('err', err);
                    }
                }
            }
            this.loadFattenData = []
        },
        copyFromChanged() {
            this.copyToDeptName = []
            this.deptNameArr.map((info) => {
                if (!this.selectedDeptForActivity.includes(info)) {
                    this.copyToDeptName.push(info)
                }
            })
        },
        async addAllHours() {
            let arr = [...this.deptWiseTeachingLoadHr, ...this.deptWiseNonTeachingLoadHr, ...this.deptWiseOtherLoadHr]
            let finalArr = []
            let arrForAllData = []
            let data = {}
            let deptId = ''
            if (this.allDepartmentDetails.length > 0) {

                this.allDepartmentDetails.map((dept) => {
                    if (dept.departmentName === this.selectedDeptForAddHr) {
                        deptId = dept.departmentDetailsId
                        data = dept
                    }
                })
                if (arr.length > 0) {

                    arr.map((info, i) => {
                        let keys = Object.keys(info)
                        if (keys.length > 0) {
                            keys.map((key) => {
                                if (key !== 'designationName' && key !== 'total' && 'type' !== key && key !== 'allActivity') {
                                    if (data.loadDistributionDetails && data.loadDistributionDetails.length > 0) {
                                        data.loadDistributionDetails.map((load, k) => {
                                            let [type, designation, activity] = key.split('_')
                                            if (load.type === type && designation === load.designationName && activity === load.activityName) {
                                                load.activityHours = Number(info[key])
                                            }
                                        })
                                    }
                                }

                            })
                        }



                    })
                }
                finalArr = data.loadDistributionDetails
            }
            try {
                await this.departmentDetailsRepositoryInstance.updateDepartmentDocument({
                    instituteId: this.selectedInstitute,
                    departmentDetailsId: deptId,
                    departmentName: this.selectedDeptForAddHr,
                    loadDistributionDetails: finalArr
                })
                this.getDepartmentOfInstitute()
            } catch (err) {
                console.error('err', err);
            }
        },
        valueChangedForActivity(value, item) {

            this.calculateTotalHr(item)

        },
        calculateTotalHr(item) {
            item.total = 0
            let keys = Object.keys(item)
            if (keys.length > 0) {
                keys.map((key) => {
                    if (key !== 'designationName' && key !== 'total' && 'type' !== key && key !== 'allActivity') {
                        item.total = item.total + Number(item[key])
                    }

                })
            }

        },
        selectedDeptForHrChanged() {
            this.allDataOfDesignation = []
            this.allDesignationDataForDept = []
            this.deptWiseOtherLoadHr = []
            this.deptWiseNonTeachingLoadHr = []
            this.deptWiseTeachingLoadHr = []
            let findOutActivityForTypeAndDesignation = []
            this.headersForTeaching = [{
                text: 'Designation',
                value: 'designationName',
                width: '150px',

            },
            {
                text: 'Total Hours',
                value: 'total',
                width: '150px'

            }]
            this.headersForNonTeaching = [
                {
                    text: 'Designation',
                    value: 'designationName',
                    width: '150px'

                },
                {
                    text: 'Total Hours',
                    value: 'total',
                    width: '150px'

                },
            ]
            this.headersForOther = [
                {
                    text: 'Designation',
                    value: 'designationName',
                    width: '150px'

                },
                {
                    text: 'Total Hours',
                    value: 'total',
                    width: '150px'

                },
            ]
            this.allDepartmentDetails.map((dept) => {
                if (dept.departmentName === this.selectedDeptForAddHr) {
                    this.typeArr.map((type) => {

                        if (dept.loadDistributionDetails && dept.loadDistributionDetails.length > 0) {
                            dept.loadDistributionDetails.map((load, k) => {
                                if (load.type === 'TeachingLoad') {
                                    let index = this.headersForTeaching.findIndex(element => element.value === load.activityName);
                                    if (index === -1) {
                                        this.headersForTeaching.push({
                                            text: load.activityName,
                                            value: load.activityName,
                                            width: '150px',

                                        })
                                    }
                                }
                                if (load.type === 'NonTeachingLoad') {
                                    let index = this.headersForNonTeaching.findIndex(element => element.value === load.activityName);
                                    if (index === -1) {
                                        this.headersForNonTeaching.push({
                                            text: load.activityName,
                                            value: load.activityName,
                                            width: '150px',

                                        })
                                    }
                                }
                                if (load.type === 'OtherLoad') {
                                    let index = this.headersForOther.findIndex(element => element.value === load.activityName);
                                    if (index === -1) {
                                        this.headersForOther.push({
                                            text: load.activityName,
                                            value: load.activityName,
                                            width: '150px',

                                        })
                                    }
                                }

                            })
                        }
                        if (this.instituteDetails.designationDetails.length > 0) {
                            this.instituteDetails.designationDetails.map((des, i) => {
                                let totalValue = 0
                                if (dept.designationHours && dept.designationHours.length > 0) {
                                    dept.designationHours.map((x) => {
                                        if (x.name === des.name) {
                                            totalValue = x.teachingHours ? Number(x.teachingHours) : 0
                                            totalValue = totalValue + (x.nonTeachingHours ? Number(x.nonTeachingHours) : 0)
                                            totalValue = totalValue + (x.otherHours ? Number(x.otherHours) : 0)
                                        }
                                    })
                                }
                                let obj = {
                                    designationName: des.name,
                                    total: totalValue,
                                    type: type,
                                    allActivity: [],
                                }
                                if (dept.loadDistributionDetails && dept.loadDistributionDetails.length > 0) {
                                    dept.loadDistributionDetails.map((load, k) => {

                                        if (load.type === type && des.name === load.designationName) {
                                            let value = `${load.type}_${load.designationName}_${load.activityName}`
                                            // obj.total = 0
                                            obj[value] = load.activityHours
                                        }
                                    })
                                }
                                if (type === 'TeachingLoad') {
                                    this.deptWiseTeachingLoadHr.push(obj)
                                }
                                if (type === 'NonTeachingLoad') {
                                    this.deptWiseNonTeachingLoadHr.push(obj)
                                }
                                if (type === 'OtherLoad') {
                                    this.deptWiseOtherLoadHr.push(obj)

                                }
                            })

                        }
                    })


                }
            })

            this.allDataOfDesignation = [...this.deptWiseTeachingLoadHr, ...this.deptWiseNonTeachingLoadHr, ...this.deptWiseOtherLoadHr]

        },
        checkIsPresent(item, activity) {
            let flag = false
            this.allDepartmentDetails.map((dept) => {
                if (dept.departmentName === this.selectedDeptForAddHr) {
                    if (dept.loadDistributionDetails && dept.loadDistributionDetails.length > 0) {
                        dept.loadDistributionDetails.map((load, k) => {
                            if (load.designationName === item.designationName && load.type === item.type && activity === load.activityName) {
                                flag = true
                            }
                        })
                    }
                }
            })
            return flag
        },
        editMultipleDialogOpen() {
            this.editMultipleLoadActivityDialog = true
        },
        async saveDesignationWiseActivity() {
            let deptId = ''
            this.editLoadActivityDialog = false
            this.allDepartmentDetails.map((dept) => {
                if (dept.departmentName === this.editedDeptLoadActivity) {
                    deptId = dept.departmentDetailsId
                }
            })
            let arrOfObj = []
            this.editActivityArr.map((load) => {
                // let arr = [...load.teachingLoad, ...load.nonTeachingLoad, ...load.otherLoad]
                if (load.teachingLoad.length > 0) {
                    load.teachingLoad.map((data) => {
                        let obj = {
                            type: 'TeachingLoad',
                            designationName: load.designation,
                            activityName: data,
                            activityHours: 0,
                        }
                        arrOfObj.push(obj)
                    })
                }
                if (load.nonTeachingLoad.length > 0) {
                    load.nonTeachingLoad.map((data) => {
                        let obj = {
                            type: 'NonTeachingLoad',
                            designationName: load.designation,
                            activityName: data,
                            activityHours: 0,
                        }
                        arrOfObj.push(obj)
                    })
                }
                if (load.otherLoad.length > 0) {
                    load.otherLoad.map((data) => {
                        let obj = {
                            type: 'OtherLoad',
                            designationName: load.designation,
                            activityName: data,
                            activityHours: 0,
                        }
                        arrOfObj.push(obj)
                    })
                }
            })
            try {
                await this.departmentDetailsRepositoryInstance.updateDepartmentDocument({
                    instituteId: this.selectedInstitute,
                    departmentDetailsId: deptId,
                    departmentName: this.editedDeptLoadActivity,
                    loadDistributionDetails: arrOfObj
                })
                this.getDepartmentOfInstitute()
            } catch (err) {
                console.error('err', err);
            }
        },
        activityChanged(i) {
            let newFilteredList = []
            let data = this.editActivityArr[i]
            let arr = [...data.teachingLoad, ...data.nonTeachingLoad, ...data.otherLoad]
            this.finalActivityList.map((list) => {
                if (!arr.includes(list.name)) {
                    newFilteredList.push(list.name)
                }
            })
            data.teachingLoadList = [...data.teachingLoad, ...newFilteredList]
            data.nonteachingLoadList = [...data.nonTeachingLoad, ...newFilteredList,]
            data.otherLoadList = [...data.otherLoad, ...newFilteredList]
            this.$set(this.editActivityArr, i, data);

        },
        loadDetailsSync() {
            let arrForLoadDetails = []
            if (this.deptNameArr.length > 0) {

                this.deptNameArr.map((name) => {
                    if (this.designationInformation.length > 0) {
                        this.designationInformation.map((designation) => {
                            let obj = {
                                departmentName: name,
                                designation: designation.name,
                                teachingLoad: [],
                                nonTeachingLoad: [],
                                otherLoad: [],
                                totalTeachingLoadHr: 0,
                                totalNonTeachingLoadHr: 0,
                                totalOtherLoadHr: 0,
                            }
                            arrForLoadDetails.push(obj)
                        })
                    }
                })
            }
            // loadDistributionDetails
            if (arrForLoadDetails.length > 0) {
                arrForLoadDetails.map((data) => {
                    if (this.allDepartmentDetails.length > 0) {
                        this.allDepartmentDetails.map((dept) => {
                            if (dept.departmentName === data.departmentName) {

                                if (dept.loadDistributionDetails && dept.loadDistributionDetails.length > 0) {

                                    dept.loadDistributionDetails.map((load) => {

                                        if (data.designation === load.designationName) {
                                            if (load.type === 'TeachingLoad') {
                                                data.totalTeachingLoadHr = data.totalTeachingLoadHr + Number(load.activityHours)
                                                data.teachingLoad.push(load.activityName)
                                            }
                                            if (load.type === 'NonTeachingLoad') {
                                                data.totalNonTeachingLoadHr = data.totalNonTeachingLoadHr + Number(load.activityHours)
                                                data.nonTeachingLoad.push(load.activityName)
                                            }
                                            if (load.type === 'OtherLoad') {
                                                data.totalOtherLoadHr = data.totalOtherLoadHr + Number(load.activityHours)
                                                data.otherLoad.push(load.activityName)

                                            }
                                        }
                                    })
                                }
                            }
                        })
                    }
                })
            }
            this.loadFattenData = []
            this.loadFattenData = arrForLoadDetails

        },
        removeElementFormArray(i) {
            this.finalActivityList.splice(i, 1)
        },
        createDepartmentNameChange() {
            this.errorMessages = ''
            if (this.deptNameArr.includes(this.departmentName)) {
                this.errorMessages = 'Department name already exists'
            }
        },

        designationDataTable() {
            // Flatten the nested data structure
            this.flattenedData = [];

            this.designationDataWithHours2.forEach((department) => {
                // flattened.push({ isGroup: true, groupName: department.name });

                department.designationDetails.forEach((designation) => {
                    let obj = {
                        departmentName: department.name,
                        designationType: designation.name,
                        teachingHours: designation.teachingHours === '' ? 0 : designation.teachingHours,
                        mentoringHours: designation.mentoringHours === '' ? 0 : designation.mentoringHours,
                        administrativeWorkHours: designation.administrativeWorkHours === '' ? 0 : designation.administrativeWorkHours,
                        otherHours: designation.otherHours === '' ? 0 : designation.otherHours,

                    }
                    obj.totalHours = Number(obj.teachingHours) + Number(obj.mentoringHours) + Number(obj.administrativeWorkHours) + Number(obj.otherHours)
                    this.flattenedData.push(obj);
                });
            });

        },
        departDataTable() {
            this.flattenedData2 = [];
            this.allDepartmentDetails.forEach((dept) => {

                dept.courseYearDetails.forEach((cy) => {
                    let obj = {
                        departmentName: dept.departmentName,
                        departmentCode: dept.departmentCode,
                        courseYearName: cy.courseYearName,
                        credits: cy.credits,
                        totalTeachingWeeks: cy.totalTeachingWeeks,
                        totalNonTeachingWeeks: cy.totalNonTeachingWeeks,
                        otherWeeks: cy.otherWeeks,
                        divisionList: '',
                    }
                    if (cy.divisionList.length > 0) {
                        for (let i = 0; i < cy.divisionList.length; i++) {
                            const info = cy.divisionList[i];
                            if (info.name && info.name !== null) {

                                if (i !== 0) {
                                    obj.divisionList = obj.divisionList + ' , ' + info.name;
                                } else {
                                    obj.divisionList = info.name;
                                }
                            }
                        }
                    }
                    this.flattenedData2.push(obj);
                });
            });

        },
        continueForUpdateDepartmentDetails() {
            this.isContinue = true
            this.dataRemoveWarning = false
            this.courseYearCountChangeForEdit()
        },
        removeChangesForCourseYearCount() {
            // this.isContinue = true
            // this.courseYearCountChangeForEdit()
            this.editDeptCourseDataLength = this.departmentForEdit.courseYearDetails.length
            this.dataRemoveWarning = false

        },
        downloadOneDesignationData(department) {
            let arr = []
            if (this.designationDataWithHours.length > 0) {

                this.designationDataWithHours.map((dept) => {
                    if (dept.name === department) {

                        if (dept.designationDetails.length > 0) {

                            dept.designationDetails.map((info) => {
                                let obj = {
                                    Department: dept.name,
                                    DesignationType: '',
                                    TeachingHours: '',
                                    MentoringHours: '',
                                    AdministrativeWorkHours: '',
                                    OtherHours: '',
                                    TotalHours: ''
                                }
                                obj.DesignationType = info.name
                                obj.TeachingHours = String(info.teachingHours)
                                obj.MentoringHours = String(info.mentoringHours)
                                obj.AdministrativeWorkHours = String(info.administrativeWorkHours)
                                obj.OtherHours = String(info.otherHours)
                                obj.TotalHours = String(info.total)
                                arr.push(obj)
                            })
                        }
                    }


                })
            }

            createExcelThroughJson(arr, 'Department wise Designation hours')
        },
        handleGenerateExcel() {
            let arr = []
            if (this.designationDataWithHours.length > 0) {

                this.designationDataWithHours.map((dept) => {

                    if (dept.designationDetails.length > 0) {

                        dept.designationDetails.map((info) => {
                            let obj = {
                                Department: dept.name,
                                DesignationType: '',
                                TeachingHours: '',
                                MentoringHours: '',
                                AdministrativeWorkHours: '',
                                OtherHours: '',
                                TotalHours: ''
                            }
                            obj.DesignationType = info.name
                            obj.TeachingHours = String(info.teachingHours)
                            obj.MentoringHours = String(info.mentoringHours)
                            obj.AdministrativeWorkHours = String(info.administrativeWorkHours)
                            obj.OtherHours = String(info.otherHours)
                            obj.TotalHours = String(info.total)
                            arr.push(obj)
                        })
                    }

                })
            }

            createExcelThroughJson(arr, 'Department wise Designation hours')
        },
        searchDepartmentForDesignation(i) {
            if (i !== '') {
                const searchTerm = i;
                const regex = new RegExp(searchTerm, "i"); // "i" for case-insensitive search
                let newArrAfterSearch = []
                this.designationDataWithHours2.map((dept) => {
                    if (regex.test(dept.name)) {
                        newArrAfterSearch.push(dept)
                    }
                })
                this.designationDataWithHours = []

                this.designationDataWithHours = newArrAfterSearch

            } else {
                this.designationDataWithHours = this.designationDataWithHours2

            }
            // return this.labels.filter(label => regex.test(label.name));
        },
        async createDepartmentDesignationData() {
            let arr = []
            this.departmentValueForCreate.map((info) => {

                if (this.allDepartmentDetails.length > 0) {
                    this.allDepartmentDetails.map((data) => {
                        if (data.departmentName === info) {
                            arr.push({
                                instituteId: data.instituteId,
                                departmentDetailsId: data.departmentDetailsId,
                                designationHours: this.createDeptDesignation.designationDetails,
                            })
                        }
                    })
                }
            })

            try {
                await this.departmentDetailsRepositoryInstance.updateDesignationHourForDepartmentInBulk(arr)
                this.getDepartmentOfInstitute()
                showStatus('Department designation edited successfully', 2000, 'success', this)
                this.designationDataTable()

            } catch (err) {
                console.error('err', err);
                showStatus('Something went wrong', 2000, 'error', this)
            }
            this.designationCreateDialog = false

        },
        async editDepartmentDesignationData() {
            let arr = []
            if (this.departmentValueForEdit.length > 0) {
                this.departmentValueForEdit.map((info) => {
                    if (this.allDepartmentDetails.length > 0) {
                        this.allDepartmentDetails.map((data) => {
                            if (data.departmentName === info) {
                                arr.push({
                                    instituteId: data.instituteId,
                                    departmentDetailsId: data.departmentDetailsId,
                                    designationHours: this.editDesignationDept.designationDetails,
                                })
                            }
                        })
                    }
                })
            }
            try {
                await this.departmentDetailsRepositoryInstance.updateDesignationHourForDepartmentInBulk(arr)
                await this.getDepartmentOfInstitute()
                showStatus('Department designation edited successfully', 2000, 'success', this)
                this.designationDataTable()

            } catch (err) {
                console.error('err', err);
                showStatus('Something went wrong', 2000, 'error', this)
            }
            this.designationEditDialog = false

        },
        closeDesignationEditDialog() {
            this.designationEditDialog = false
            this.editDesignationDept = this.editDesignationDept2
            this.syncDesignationData()
            this.designationDataTable()
        },
        editDepartmentDisDialogOpen(dept) {
            // this.syncDesignationData()
            this.editDesignationDept = {}
            let value = {}
            if (this.designationDataWithHours2.length > 0) {
                this.designationDataWithHours2.map((info) => {
                    if (info.name === dept) {
                        value = info
                    }
                })
            }


            this.designationEditDialog = true

            this.editDesignationDept = value
            this.editDesignationDept2 = value
            this.departmentValueForEdit = []
            this.departmentValueForEdit.push(value.name)

        },
        editLoadDialogOpen(dept) {
            this.editedDeptLoadActivity = dept
            this.editLoadActivityDialog = true
            this.editActivityArr = []
            this.loadFattenData.map((load) => {
                if (load.departmentName === dept) {
                    load.teachingLoadList = []
                    load.nonteachingLoadList = []
                    load.otherLoadList = []
                    let newFilteredList = []
                    let arr = [...load.teachingLoad, ...load.nonTeachingLoad, ...load.otherLoad]
                    this.finalActivityList.map((list) => {
                        if (!arr.includes(list.name)) {
                            newFilteredList.push(list.name)
                        }
                    })
                    load.teachingLoadList = [...load.teachingLoad, ...newFilteredList]
                    load.nonteachingLoadList = [...load.nonTeachingLoad, ...newFilteredList]
                    load.otherLoadList = [...load.otherLoad, ...newFilteredList]

                    this.editActivityArr.push(load)
                }
            })
        },

        async deleteDepartmentDisDialogOpen(dept) {
            let value = {}
            if (this.designationDataWithHours.length > 0) {
                this.designationDataWithHours.map((info) => {
                    if (info.name === dept) {
                        value = info
                    }
                })
            }
            this.designationDeleteDialog = true
            this.designationReset = value

        },
        async designationResetValue() {
            this.designationDeleteDialog = false
            this.designationReset.designationDetails.map((info) => {
                info.teachingHours = 0
                info.mentoringHours = 0
                info.administrativeWorkHours = 0
                info.otherHours = 0

            })
            let arr = []
            arr[0] = this.designationReset
            try {
                await this.departmentDetailsRepositoryInstance.updateDesignationHourForDepartmentInBulk(arr)
                await this.getDepartmentOfInstitute()
                this.designationDataTable()
                showStatus('Designation value set to 0', 2000, 'success', this)
            } catch (err) {
                console.error('err', err);
                showStatus('Something went wrong', 2000, 'error', this)
            }
            this.designationEditDialog = false
        },

        departmentChangeForDEsignationEdit() {

        },
        addNewDataToDesignationHours() {
            this.departmentValueForCreate = ''
            this.createDeptDesignation = {
                name: '',
                designationDetails: []
            }
            if (this.instituteDetails.designationDetails.length > 0) {

                this.instituteDetails.designationDetails.map((data) => {
                    let objToPush = {
                        name: data.name,
                        teachingHours: 0,
                        mentoringHours: 0,
                        administrativeWorkHours: 0,
                        otherHours: 0,
                        total: 0
                    }
                    this.createDeptDesignation.designationDetails.push(objToPush)
                })
            }
            this.designationCreateDialog = true

        },
        downloadAllDepartmentDesignationData() {

        },
        checkDesignationAndDiscFilled() {
            this.designationAndDiscFlag = false
            let count = 0
            if (this.designationInformation.length > 0) {
                this.designationInformation.map((info) => {
                    if (info.name === '') {

                    } else {
                        count++;
                    }
                })
            }
            if (count === this.designationInformation.length) {
                this.designationAndDiscFlag = true
            }
        },

        async saveInstituteDesignationData() {
            try {
                await this.instituteRepositoryInstance.updateInstituteDetails({
                    instituteId: this.selectedInstitute,
                    designationDetails: this.designationInformation
                })
                showStatus('Designation updated successfully', 2000, 'success', this)

                await this.getInstituteInfo()
            } catch (err) {
                console.error('err', err);
            }
        },
        async saveDesignationData() {
            try {

            } catch (err) {
                console.error('err', err);
            }
        },
        deptNameChangeForDesignation() {

        },
        syncDesignationData() {
            this.final
            let arr = []
            this.designationDataWithHours = []
            this.designationDataWithHours2 = []


            if (this.allDepartmentDetails.length > 0) {
                this.allDepartmentDetails.map((dept) => {
                    let obj = {
                        name: dept.departmentName,
                        instituteId: dept.instituteId,
                        departmentDetailsId: dept.departmentDetailsId,
                        designationDetails: []
                    }
                    if (this.instituteDetails.designationDetails.length > 0) {

                        this.instituteDetails.designationDetails.map((data) => {
                            let objToPush = {
                                name: data.name,
                                teachingHours: 0,
                                mentoringHours: 0,
                                administrativeWorkHours: 0,
                                otherHours: 0,
                                total: 0
                            }



                            if (dept.designationHours && dept.designationHours.length > 0) {
                                dept.designationHours.map((desig) => {
                                    if (desig.name === data.name) {
                                        objToPush.teachingHours = desig.teachingHours ? desig.teachingHours : 0
                                        objToPush.mentoringHours = desig.mentoringHours ? desig.mentoringHours : 0
                                        objToPush.administrativeWorkHours = desig.administrativeWorkHours ? desig.administrativeWorkHours : 0
                                        objToPush.otherHours = desig.otherHours ? desig.otherHours : 0
                                    }
                                    objToPush.total = Number(objToPush.teachingHours) + Number(objToPush.mentoringHours) + Number(objToPush.administrativeWorkHours) + Number(objToPush.otherHours)
                                })

                            }
                            obj.designationDetails.push(objToPush)
                        })
                    }

                    this.designationDataWithHours.push(obj)
                })
            }
            this.designationDataWithHours2 = this.designationDataWithHours

        },
        async subTabChange() {
            if (this.structureTabs === 0) {
                this.syncDesignationData()
                this.designationDataTable()
            }
            this.selectedDeptForAddHr = ''
        },
        noOfDesignationsChanged() {
            if (this.noOfDesignations > 0) {
                if (this.noOfDesignations > this.designationInformation.length) {
                    let value = this.noOfDesignations - this.designationInformation.length
                    for (let i = 0; i < value; i++) {
                        this.designationInformation.push({
                            name: '',
                            description: ''
                        })
                    }
                } else {
                    let arr = []
                    for (let i = 0; i < this.noOfDesignations; i++) {
                        arr.push(this.designationInformation[i])
                    }
                    this.designationInformation = arr
                }

            }
            this.checkDesignationAndDiscFilled()
        },
        async deleteDepartment() {
            this.departmentDeleteDialog = false
            try {
                this.loaderForDeptStructure = true

                await this.departmentDetailsRepositoryInstance.deleteDepartmentDetails(this.departmentForDelete)
                await this.getDepartmentOfInstitute()
                showStatus('Department delete successfully', 2000, 'success', this)
                this.loaderForDeptStructure = false

            } catch (err) {
                console.error('err', err);
                this.loaderForDeptStructure = false
                showStatus('Something went wrong', 2000, 'error', this)


            }

        },

        divisionCountChangeForEdit(cy, i) {
            cy.divisionData = [];
            let count = Number(cy.divisionCount)
            if (count !== 0) {
                if (count >= cy?.divisionData?.length || 0) {
                    for (let index = cy?.divisionData?.length; index < count; index++) {
                        cy.divisionData.push({
                            name: ''
                        })
                    }
                    this.checkAllDataIsFilledForUpdate()
                    this.$set(this.departmentForEdit.courseYearDetails, i, cy)
                } else {
                    cy.divisionData = cy?.divisionData?.slice(0, count)
                    this.checkAllDataIsFilledForUpdate()
                    this.$set(this.departmentForEdit.courseYearDetails, i, cy)
                }
            }
        },
        courseYearCountChangeForEdit() {
            this.dummyEditDeptCourseDataLength = this.editDeptCourseDataLength
            if (this.editDeptCourseDataLength !== 0) {
                if (this.departmentForEdit.courseYearDetails.length > this.editDeptCourseDataLength) {
                    if (this.isContinue) {

                        let arr = []
                        for (let i = 0; i < this.editDeptCourseDataLength; i++) {
                            arr.push(this.departmentForEdit.courseYearDetails[i])
                        }
                        this.departmentForEdit.courseYearDetails = arr
                    }
                } else {
                    let value = this.editDeptCourseDataLength - this.departmentForEdit.courseYearDetails.length
                    for (let i = 0; i < value; i++) {
                        this.departmentForEdit.courseYearDetails.push({
                            courseYearName: '',
                            credits: 0,
                            totalTeachingWeeks: 0,
                            totalNonTeachingWeeks: 0,
                            otherWeeks: 0,
                            divisionCount: 0,
                            divisionList: []
                        })
                    }
                }
            }
            if (this.departmentForEdit.courseYearDetails.length > this.editDeptCourseDataLength) {
                this.dataRemoveWarning = true

            }
        },
        async editDepartment() {
            this.loaderForDeptStructure = true
            try {
                if (this.departmentForEdit.courseYearDetails.length > 0) {
                    this.departmentForEdit.courseYearDetails.map((info) => {
                        info.divisionList = info.divisionData
                    })
                }

                await this.departmentDetailsRepositoryInstance.updateDepartmentDocument(this.departmentForEdit)
                showStatus('Department edited successfully', 2000, 'success', this)

                this.getDepartmentOfInstitute()
                this.loaderForDeptStructure = false

            } catch (err) {
                console.error('err', err);
                this.loaderForDeptStructure = false
                showStatus('Something went wrong', 2000, 'error', this)


            }
            this.departmentEditDialog = false
        },
        checkAllDataIsFilledForCreate() {
            this.isAllDataFilled = false
            let count = 0
            if (this.dummyArrForCourseYearCount.length > 0) {
                this.dummyArrForCourseYearCount.map((info) => {
                    let countDiv = 0

                    if (info.name !== '') {
                        if (info.divisionDetails.length > 0) {

                            info.divisionDetails.map((div) => {
                                if (div.name !== '') {
                                    countDiv++
                                }
                            })
                        }
                        if (countDiv === Number(info.divisionCountValue)) {
                            count++;
                        }
                    }
                })
            }
            if (count === this.dummyArrForCourseYearCount.length) {
                this.isAllDataFilled = true
            }


        },
        checkAllDataIsFilledForUpdate() {
            this.isAllDataFilledForUpdate = false
            let count = 0
            if (this.departmentForEdit.courseYearDetails.length > 0) {
                this.departmentForEdit.courseYearDetails.map((info) => {
                    let countDiv = 0

                    if (info.courseYearName !== '') {
                        if (info.divisionData.length > 0) {

                            info.divisionData.map((div) => {
                                if (div.name !== '') {
                                    countDiv++
                                }
                            })
                        }
                        if (countDiv === Number(info.divisionCount)) {
                            count++;
                        }
                    }
                })
            }
            if (count === this.departmentForEdit.courseYearDetails.length) {
                this.isAllDataFilledForUpdate = true
            }


        },
        editDepartmentDialogOpen(dept, i) {
            let element = {}
            if (this.allDepartmentDetails.length > 0) {
                this.allDepartmentDetails.map((info) => {
                    if (info.departmentName === dept) {
                        element = info
                    }
                })
            }
            this.departmentForEdit = element
            this.courseYearDetailsForEdit = element.courseYearDetails
            this.editDeptCourseDataLength = element.courseYearDetails.length
            if (element.courseYearDetails.length > 0) {
                element.courseYearDetails.map((info) => {
                    info.divisionCount = Number(info.divisionList.length)
                    info.divisionData = []
                    if (info.divisionList.length > 0) {
                        info.divisionList.map((div) => {

                            info.divisionData.push({
                                name: div.name
                            })
                        })
                    }
                })

            }
            this.departmentEditDialog = true
            this.isContinue = false

            this.checkAllDataIsFilledForUpdate()
        },
        deleteDepartmentDialogOpen(dept) {
            let element = {}
            if (this.allDepartmentDetails.length > 0) {
                this.allDepartmentDetails.map((info) => {
                    if (info.departmentName === dept) {
                        element = info
                    }
                })
            }
            this.departmentForDelete = element
            this.departmentDeleteDialog = true

        },
        async createNewDepartment() {
            this.addDepartmentStructure = false
            this.loaderForDeptStructure = true

            try {
                let mainObj = {
                    instituteId: this.selectedInstitute,
                    departmentName: this.departmentName,
                    departmentCode: this.departmentCode,
                    courseYearDetails: [],
                    credit: 0,
                    totalTeachingHourForCredit: 0,
                    designationHours: []

                }
                let cyDetailsArr = []
                this.dummyArrForCourseYearCount.map((info) => {
                    let courseYearObj = {
                        courseYearName: info.name,
                        credits: info.credit,
                        totalTeachingWeeks: info.TotalTeachingWeeks,
                        totalNonTeachingWeeks: info.TotalNonTeachingWeeks,
                        otherWeeks: info.otherWeeks,
                        divisionList: []
                    }
                    let divNameArr = []
                    if (info.divisionDetails.length > 0) {
                        info.divisionDetails.map((div) => {
                            divNameArr.push({ name: div.name })
                        })
                    }
                    courseYearObj.divisionList = divNameArr
                    cyDetailsArr.push(courseYearObj)

                })
                mainObj.courseYearDetails = cyDetailsArr
                await this.departmentDetailsRepositoryInstance.createDepartmentDetails(mainObj)
                await this.getDepartmentOfInstitute()
                showStatus('Department created successfully', 2000, 'success', this)
                this.loaderForDeptStructure = false

            } catch (err) {
                console.error('err', err);
                this.loaderForDeptStructure = false
                showStatus('Something went wrong', 2000, 'error', this)


            }
        },
        divisionCountChange(courseYear, i,) {
            let arr = []
            let length = Number(courseYear.divisionCountValue)
            if (courseYear.divisionCountValue >= courseYear?.divisionDetails?.length || 0) {
                for (let i = courseYear?.divisionDetails?.length || 0; i < length; i++) {
                    arr.push({
                        name: ''
                    })
                }
                courseYear.divisionDetails = courseYear.divisionDetails.concat(arr)
                this.$set(this.dummyArrForCourseYearCount, i, courseYear);
            } else {
                courseYear.divisionDetails = courseYear.divisionDetails.slice(0, length)
                this.$set(this.dummyArrForCourseYearCount, i, courseYear);
            }
        },
        courseYearCountChange() {
            this.dummyArrForCourseYearCount = []
            if (this.courseYearCountForNewDept > 0) {
                // this.dummyArrForCourseYearCount.length = this.courseYearCountForNewDept
                for (let i = 0; i < this.courseYearCountForNewDept; i++) {
                    let obj = {
                        divisionCountValue: 0,
                        name: '',
                        credit: 0,
                        TotalTeachingWeeks: 0,
                        TotalNonTeachingWeeks: 0,
                        otherWeeks: 0,
                        divisionDetails: []
                    }
                    this.dummyArrForCourseYearCount.push(obj)
                }
            }
        },
        async getInstituteInfo() {
            // console.log('function called')
            this.totalHrsTableData = []
            const objToPush = {
                instituteId: this.selectedInstitute
            }
            this.instituteDetails = await this.instituteRepositoryInstance.getInstituteInformation(
                objToPush
            )
            this.finalActivityList = []
            if (this.instituteDetails.instituteActivityList) {
                if (this.instituteDetails.instituteActivityList.length === 0) {
                    this.activityNames.map((info) => {
                        let obj = {
                            name: info
                        }
                        this.finalActivityList.push(obj)
                    })
                } else {
                    this.instituteDetails.instituteActivityList.map((info) => {
                        let obj = {
                            name: info
                        }
                        this.finalActivityList.push(obj)
                    })
                }

            } else {
                this.activityNames.map((info) => {
                    let obj = {
                        name: info
                    }
                    this.finalActivityList.push(obj)
                })

            }

            if (this.instituteDetails.designationDetails && this.instituteDetails.designationDetails.length > 0) {
                this.designationInformation = this.instituteDetails.designationDetails
                this.noOfDesignations = this.designationInformation.length
            }
            this.totalHrsTableData = []
            if (this.allDepartmentDetails.length > 0) {
                this.allDepartmentDetails.map((info) => {
                    if (this.instituteDetails.designationDetails && this.instituteDetails.designationDetails.length > 0) {
                        this.instituteDetails.designationDetails.map((designation) => {
                            let obj = {
                                departmentName: info.departmentName,
                                name: designation.name,
                                teachingHours: 0,
                                nonTeachingHours: 0,
                                otherHours: 0,
                                total: 0,
                            }
                            this.totalHrsTableData.push(obj)
                        })
                    }
                //    console.log(' this.totalHrsTableData',  this.totalHrsTableData);
                    if (info.designationHours && info.designationHours.length > 0) {
                        info.designationHours.map((hrs) => {
                            // console.log('hrs', hrs);
                            if (this.totalHrsTableData.length > 0) {
                                this.totalHrsTableData.map((value) => {

                                    if (value.name === hrs.name && value.departmentName === info.departmentName) {
                                        // console.log('matched')
                                        value.teachingHours = hrs.teachingHours
                                        value.nonTeachingHours = hrs.nonTeachingHours
                                        value.otherHours = hrs.otherHours
                                        value.total = Number(hrs.teachingHours) + Number(hrs.nonTeachingHours) + Number(hrs.otherHours)
                                        // console.log('value.total', value.total);
                                    }
                                })
                            }
                        })
                    }
                })


            }
            this.syncDesignationData()
        },
        addListItem() {
            this.finalActivityList.push({
                name: ''
            })
        },
        async addActivityListToInstitute() {
            this.activityListDialog = false
            let list = this.finalActivityList.map((info) => {
                return info.name
            })
            // console.log('list', list);
            try {
                await this.instituteRepositoryInstance.updateInstituteDetails({
                    instituteId: this.selectedInstitute,
                    instituteActivityList: list
                })
                this.getInstituteInfo()
                showStatus('Activity list updated successfully', 2000, 'success', this)

                await this.getInstituteInfo()
            } catch (err) {
                console.error('err', err);
            }

        },
        async getDepartmentOfInstitute() {
            this.loadFattenData = []
            this.allDepartmentDetails = []
            this.deptNameArr = []

            try {
                this.allDepartmentDetails = await this.departmentDetailsRepositoryInstance.getDepartmentDetailsForInstitute({
                    instituteId: this.selectedInstitute
                })
                if (this.allDepartmentDetails.length > 0) {
                    this.allDepartmentDetails.map((info) => {
                        this.deptNameArr.push(info.departmentName)
                    })
                }
                await this.getInstituteInfo()
                this.loadDetailsSync()
                this.departDataTable()


            } catch (err) {
                console.error('err', err);
            }
        },
        addDepartmentDialogOpen() {
            this.addDepartmentStructure = true
            this.departmentName = ''
            this.departmentCode = ''
            this.courseYearCountForNewDept = 0
            this.dummyArrForCourseYearCount = []

        },
        handleFunctionCall(functionName) {
            this[functionName]()
        },
        gotoInstituteBasicDetails() {
            this.$router.push('/activityInstitutesBasicDetails')
        },
        gotoReferenceAcademicDetails() {
            this.$router.push('/activityReferenceAcademicDetails')
        },
        gotoReferenceInfrastructure() {
            this.$router.push('/activityReferenceInfrastructure')
        },
        gotoReferenceShiftDetails() {
            this.$router.push('/activityReferenceShiftDetails')
        },
        gotoAddSubjectInstituteLevel() {
            this.$router.push('/activityAddSubjectInstituteLevel')
        },
        gotoExamSettings() {
            this.$router.push('/activityExamSettings')
        },
        gotoAdditionalFacility() {
            this.$router.push('/activityAdditionalFacility')
        },
        gotoActivityControlAccess() {
            this.$router.push('/activityControlAccess')
        }
    }
}
</script>
<style scoped src="./activityReferenceAcademicDetails.css"></style>
