import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Session'

class Session {
  constructor (context) {
    this.context = context
  }

  async createSessions (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createSessions, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getSessions (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log("here do what")
    const response = await networkManager.getRequest(ApiNames.getSessions, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async deleteSession (data) {
    console.log('datadata', data);
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.deleteSession, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  // async getExam (data) {
  //   this.context.$store.commit('liveData/set_loading', true)
  //   const response = await networkManager.getRequest(ApiNames.getExam, data)
  //   this.context.$store.commit('liveData/set_loading', false)
  //   return response
  // }

  // async deleteExam (data) {
  //   this.context.$store.commit('liveData/set_loading', true)
  //   const response = await networkManager.deleteRequest(ApiNames.deleteExam, data)
  //   this.context.$store.commit('liveData/set_loading', false)
  //   return response.status
  // }
}

export default Session