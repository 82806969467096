/* eslint-disable */
import * as XLSX from "xlsx/xlsx.mjs";
import * as ExcelJS from "exceljs";
import stringOps from "../Services/Utils/stringOps";
import { emailRegex, dateOfBirthRegex } from "../Constants/Utils/Statics";

export const isStringEmptyOrNull = (str) => {
  try {
    if (str === null || str === undefined) return true;
    return str?.trim() === "";
  } catch (error) {
    return false;
  }
};

export const isValidDate = (dateStr) => {
  if (dateStr === undefined || dateStr === null) return false;
  try {
    const date = new Date(dateStr);
    return date.getTime() === date.getTime();
  } catch (error) {
    return false;
  }
};

function numberToAlphabetic(num) {
  let result = "";
  while (num >= 0) {
    result = String.fromCharCode(65 + (num % 26)) + result;
    num = Math.floor(num / 26) - 1;
  }
  return result;
}
export function createMultiSheetStyledExcel(sheets, fileName) {
  // Create a new workbook
  const workbook = new ExcelJS.Workbook();

  // Iterate over each sheet
  sheets.forEach((sheet) => {
    // Add a new sheet to the workbook
    const worksheet = workbook.addWorksheet(sheet.name || "Sheet");

    // Track column lengths for dynamic width
    const columnLengths = {};

    // Populate worksheet cells with data and apply styles
    sheet.data.forEach((item) => {
      // Get the cell using 1-based indexing
      const cell = worksheet.getCell(item.row, item.column);

      // Set the cell value
      cell.value = item.value;

      // Track the maximum length for the column
      const cellValue = item.value ? item.value.toString() : "";
      columnLengths[item.column] = Math.max(
        columnLengths[item.column] || 0,
        cellValue.length
      );

      // Apply background color if specified
      if (item.backgroundColor) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: item.backgroundColor }, // Use ARGB format, e.g., 'FFFF00' for yellow
        };
      }

      // Apply font weight (bold) if specified
      if (item.bold !== undefined) {
        cell.font = { bold: item.bold };
      }

      // Apply alignment if specified
      if (item.alignment) {
        cell.alignment = { horizontal: item.alignment };
      }

      // Apply border if specified
      if (item.border === true) {
        cell.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
      }
    });

    // Set column widths dynamically based on tracked lengths
    const columns = [];
    for (let col = 1; col <= Object.keys(columnLengths).length; col++) {
      columns.push({ width: columnLengths[col] ? columnLengths[col] + 2 : 10 }); // Add padding
    }
    worksheet.columns = columns;
  });

  // Generate the Excel file as a Blob and trigger download
  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a temporary link element for downloading
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.href = url;
      link.download = fileName || "MultiSheet_Data.xlsx";
      document.body.appendChild(link);
      link.click();

      // Cleanup: Remove the link element and revoke the Blob URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.error("Error generating file:", err);
    });
}
export function createExcel(tableId) {
  // Acquire Data (reference to the HTML table)
  var table_elt = document.getElementById(tableId);
  // Extract Data (create a workbook object from the table)

  var workbook = XLSX.utils.table_to_book(table_elt);
  // Process Data (add a new row)
  var ws = workbook.Sheets["Sheet1"];
  XLSX.utils.sheet_add_aoa(ws, [["Created Date of Excel :- " + new Date()]], {
    origin: -1,
  });
  // Package and Release Data (`writeFile` tries to write and save an XLSB file)
  XLSX.writeFile(workbook, "table_Data.xlsx");
}

// export function createExcelThroughJson(data, fileName, title, desc) {
//   // Create a new workbook
//   const wb = XLSX.utils.book_new()

//   // Add a new worksheet to the workbook
//   const ws = XLSX.utils.json_to_sheet(data)

//   // Add the worksheet to the workbook
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

//   // Generate the Excel file and download it
//   if(title){
//     XLSX.utils.sheet_add_aoa(ws, [[title]], {
//       origin: "A1",
//     })
//   }
//   if(desc){
//     XLSX.utils.sheet_add_aoa(ws, [[desc]], {
//       origin: "A2",
//     })
//   }
//   if (!fileName) {
//     XLSX.utils.sheet_add_aoa(ws, [['Created Date of Excel :- ' + new Date()]], {
//       origin: -1,
//     })
//     XLSX.writeFile(wb, 'table_Data.xlsx')
//   } else {
//     XLSX.writeFile(wb, `${fileName}.xlsx`)
//   }

//   // // Extract Data (create a workbook object from the table)
//   // var workbook = XLSX.utils.table_to_book(table_elt);
//   // // Process Data (add a new row)
//   // var ws = workbook.Sheets["Sheet1"];
//   // XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

//   // // Package and Release Data (`writeFile` tries to write and save an XLSB file)
//   // XLSX.writeFile(workbook, "table_Data.xlsx");
// }
// export function createExcelThroughJson(data, fileName, title, desc) {
//   // Create a new workbook
//   const wb = XLSX.utils.book_new();

//   // Add a new worksheet to the workbook
//   const ws = XLSX.utils.json_to_sheet(data);

//   // Add the worksheet to the workbook
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

//   if (title) {
//     XLSX.utils.sheet_add_aoa(ws, [[title]], {
//       origin: "A1",
//     });
//   }

//   if (desc) {
//     // Insert an empty row at the beginning
//     XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A2" });

//     // Add the description in A2
//     XLSX.utils.sheet_add_aoa(ws, [[desc]], {
//       origin: "A2",
//     });

//     // Iterate through rows and shift them down
//     for (let i = XLSX.utils.decode_range(ws['!ref']).e.r; i >= 2; i--) {
//       const from = XLSX.utils.encode_cell({ r: i, c: 0 });
//       const to = XLSX.utils.encode_cell({ r: i + 1, c: 0 });
//       ws[to] = ws[from];
//     }
//   }

//   // Generate the Excel file and download it
//   if (!fileName) {
//     XLSX.utils.sheet_add_aoa(ws, [['Created Date of Excel :- ' + new Date()]], {
//       origin: -1,
//     });
//     XLSX.writeFile(wb, 'table_Data.xlsx');
//   } else {
//     XLSX.writeFile(wb, `${fileName}.xlsx`);
//   }
// }
export async function generateJsonFromExcelWithoutSchema(
  file,
  format = "rowWise"
) {
  try {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(await file.arrayBuffer());

    const data = {};

    workbook.worksheets.forEach((worksheet) => {
      const sheetData = [];

      if (format === "rowWise") {
        // Row-wise structure: array of arrays, each sub-array is a row
        worksheet.eachRow((row, rowIndex) => {
          const rowData = [];
          row.eachCell((cell, colIndex) => {
            rowData.push({
              value: cell.value,
              row: rowIndex,
              column: colIndex,
            });
          });
          sheetData.push(rowData);
        });
      } else if (format === "colWise") {
        // Column-wise structure: array of arrays, each sub-array is a column
        const headers = worksheet.getRow(1).values ?? [];
        headers.forEach((_, colIndex) => {
          const colData = [];
          worksheet.eachRow((row, rowIndex) => {
            colData.push({
              value: row.getCell(colIndex + 1).value,
              row: rowIndex,
              column: colIndex + 1,
            });
          });
          sheetData.push(colData);
        });
      } else if (format === "flat") {
        // Flat structure: single array of objects with row, column, and value
        worksheet.eachRow((row, rowIndex) => {
          row.eachCell((cell, colIndex) => {
            sheetData.push({
              value: cell.value,
              row: rowIndex,
              column: colIndex,
            });
          });
        });
      }

      data[worksheet.name] = sheetData;
    });

    return data;
  } catch (error) {
    console.error(error);
  }

  return { errors: [], data: {} };
}
export function createExcelThroughJson(data, fileName, title, desc) {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Create a new worksheet with a header row
  const header = [];
  const ws = XLSX.utils.aoa_to_sheet([header]);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Add title to A1 if provided
  let count = 0;
  if (title) {
    count++;
    XLSX.utils.sheet_add_aoa(ws, [[title]], { origin: "A1" });
  }

  // Add description to A2 if provided
  if (desc) {
    count++;
    XLSX.utils.sheet_add_aoa(ws, [[desc]], { origin: "A2" });
  }

  // Append the data starting from A3
  if (count === 0) {
    XLSX.utils.sheet_add_json(ws, data, { origin: "A1" });
  }
  if (count === 1) {
    XLSX.utils.sheet_add_json(ws, data, { origin: "A2" });
  }
  if (count === 2) {
    XLSX.utils.sheet_add_json(ws, data, { origin: "A3" });
  }

  // Generate the Excel file and download it
  if (!fileName) {
    XLSX.utils.sheet_add_aoa(ws, [["Created Date of Excel :- " + new Date()]], {
      origin: -1,
    });
    XLSX.writeFile(wb, "table_Data.xlsx");
  } else {
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}

// export function createExcelThroughJson(data, fileName, title, desc) {
//   // Create a new workbook
//   const wb = XLSX.utils.book_new();

//   // Add a new worksheet to the workbook
//   const ws = XLSX.utils.json_to_sheet(data);

//   // **Shift headers to row 3**
//   ws.splice(0, 2); // Remove the first two rows (header row and empty row)
//   ws.unshift(['']); // Add an empty row at the beginning
//   ws.unshift(['']); // Add another empty row at the beginning

//   // Add the worksheet to the workbook
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

//   // Generate the Excel file and download it
//   if (title) {
//     XLSX.utils.sheet_add_aoa(ws, [[title]], {
//       origin: "A1",
//     });
//   }
//   if (desc) {
//     XLSX.utils.sheet_add_aoa(ws, [[desc]], {
//       origin: "A2",
//     });
//   }
//   if (!fileName) {
//     XLSX.utils.sheet_add_aoa(ws, [['Created Date of Excel :- ' + new Date()]], {
//       origin: -1,
//     });
//     XLSX.writeFile(wb, 'table_Data.xlsx');
//   } else {
//     XLSX.writeFile(wb, `${fileName}.xlsx`);
//   }
// }

function tableToJson(table) {
  var data = [];
  for (var i = 1; i < table.rows.length; i++) {
    var tableRow = table.rows[i];
    var rowData = [];
    for (var j = 0; j < tableRow.cells.length; j++) {
      rowData.push(tableRow.cells[j].innerHTML);
    }
    data.push(rowData);
  }
  return data;
}

export async function generateFromJson(schema) {
  const workbook = new ExcelJS.Workbook();
  Object.keys(schema).forEach((sheetName, i) => {
    workbook.addWorksheet(sheetName);
    const worksheet = workbook.getWorksheet(sheetName);
    worksheet.properties.defaultColWidth = 35;
    worksheet.getRow(1).height = 30;

    let currentValidationColumn = "A";

    const addListValidationColumn = (listArr) => {
      const testValidationWorksheet = workbook.getWorksheet("LOOKUPS");
      if (
        testValidationWorksheet === null ||
        testValidationWorksheet === undefined
      )
        workbook.addWorksheet("LOOKUPS");
      const validationWorksheet = workbook.getWorksheet("LOOKUPS");
      validationWorksheet.properties.defaultColWidth = 35;
      for (let i = 0; i < listArr.length; i++) {
        const cell = validationWorksheet.getCell(
          `${currentValidationColumn}${i + 1}`
        );
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
        cell.value = listArr[i];
        cell.protection = {
          locked: true,
        };
      }
      const formulae = [
        `LOOKUPS!$${currentValidationColumn}$1:$${currentValidationColumn}$${listArr.length}`,
      ];
      String.fromCharCode(currentValidationColumn.charCodeAt(0) + 1);
      return formulae;
    };

    const getFormulae = (listArr) => {
      const formulaeString = `"${
        Array.isArray(listArr) ? listArr.join(",") : ""
      }"`;
      if (formulaeString.length <= 255) return formulaeString;
      return addListValidationColumn(listArr);
    };

    Object.keys(schema[sheetName]).forEach((head, i) => {
      // 65 = A Alphabet
      const key = String.fromCharCode(65 + i);
      const cell = worksheet.getCell(`${key}1`);
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.value = head;
      cell.protection = {
        locked: true,
      };

      if (schema[sheetName][head].validation !== undefined) {
        const { type, values, required } = schema[sheetName][head].validation;

        if (type !== undefined) {
          let formulae = "";
          const allowBlank = required !== undefined ? required : false;

          if (required) {
            cell.font = {
              name: "Calibri",
              color: { argb: "ffff6347" },
              bold: true,
            };
          }

          let obj = {};

          if (type === "list") {
            //formulae = `"${Array.isArray(values) ? values.join(',') : ''}"`
            formulae = getFormulae(values);
          }
          if (type === "textLength") {
            formulae = 0;
            obj["operator"] = "greaterThan";
          }
          if (type === "decimal") {
            formulae = 0;
            obj["operator"] = "greaterThanOrEqual";
          }
          if (type === "whole") {
            formulae = 0;
            obj["operator"] = "greaterThanOrEqual";
          }

          worksheet.dataValidations.add(`${key}2:${key}9999`, {
            ...obj,
            type: type,
            allowBlank,
            formulae: [formulae],
            showErrorMessage: true,
          });
        }
      }
    });
  });
  const testValidationWorksheet = workbook.getWorksheet("LOOKUPS");
  if (
    !(testValidationWorksheet === null || testValidationWorksheet === undefined)
  ) {
    //testValidationWorksheet.protect('studium-excel')
    testValidationWorksheet.state = "veryHidden";
  }

  const buffer = Buffer.from(await workbook.xlsx.writeBuffer());
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  var csvURL = window.URL.createObjectURL(blob);
  return csvURL;
  // const tempLink = document.createElement('a')
  // tempLink.href = csvURL
  // tempLink.setAttribute('download', 'filename.xlsx')
  // tempLink.click()
}

export async function generateFromJsonForDropdownAndPreFieldData(
  schema,
  students
) {
  const workbook = new ExcelJS.Workbook();

  Object.keys(schema).forEach((sheetName) => {
    const worksheet = workbook.addWorksheet(sheetName);
    worksheet.properties.defaultColWidth = 35;
    worksheet.getRow(1).height = 30;

    let currentValidationColumn = "A";

    const addListValidationColumn = (listArr) => {
      let validationWorksheet = workbook.getWorksheet("LOOKUPS");
      if (!validationWorksheet) {
        validationWorksheet = workbook.addWorksheet("LOOKUPS");
      }
      validationWorksheet.properties.defaultColWidth = 35;
      for (let i = 0; i < listArr.length; i++) {
        const cell = validationWorksheet.getCell(
          `${currentValidationColumn}${i + 1}`
        );
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
        cell.value = listArr[i];
        cell.protection = {
          locked: true,
        };
      }
      const formulae = [
        `LOOKUPS!$${currentValidationColumn}$1:$${currentValidationColumn}$${listArr.length}`,
      ];
      currentValidationColumn = String.fromCharCode(
        currentValidationColumn.charCodeAt(0) + 1
      );
      return formulae;
    };

    const getFormulae = (listArr) => {
      const formulaeString = `"${
        Array.isArray(listArr) ? listArr.join(",") : ""
      }"`;
      if (formulaeString.length <= 255) return formulaeString;
      return addListValidationColumn(listArr);
    };

    Object.keys(schema[sheetName]).forEach((head, i) => {
      const key = String.fromCharCode(65 + i);
      const cell = worksheet.getCell(`${key}1`);
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.value = head;
      cell.protection = {
        locked: true,
      };

      if (schema[sheetName][head].validation !== undefined) {
        const { type, values, required } = schema[sheetName][head].validation;

        if (type !== undefined) {
          let formulae = "";
          const allowBlank = required !== undefined ? required : false;

          if (required) {
            cell.font = {
              name: "Calibri",
              color: { argb: "ffff6347" },
              bold: true,
            };
          }

          let obj = {};

          if (type === "list") {
            formulae = getFormulae(values);
          }
          if (type === "textLength") {
            formulae = 0;
            obj["operator"] = "greaterThan";
          }
          if (type === "decimal") {
            formulae = 0;
            obj["operator"] = "greaterThanOrEqual";
          }
          if (type === "whole") {
            formulae = 0;
            obj["operator"] = "greaterThanOrEqual";
          }

          worksheet.dataValidations.add(`${key}2:${key}9999`, {
            ...obj,
            type: type,
            allowBlank,
            formulae: [formulae],
            showErrorMessage: true,
          });
        }
      }
    });

    // Add student data to the worksheet
    students.forEach((student, rowIndex) => {
      Object.keys(schema[sheetName]).forEach((head, colIndex) => {
        const key = String.fromCharCode(65 + colIndex);
        const cell = worksheet.getCell(`${key}${rowIndex + 2}`);
        cell.value = student[schema[sheetName][head].variableName] || "";
      });
    });
  });

  const testValidationWorksheet = workbook.getWorksheet("LOOKUPS");
  if (testValidationWorksheet) {
    testValidationWorksheet.state = "veryHidden";
  }

  const buffer = Buffer.from(await workbook.xlsx.writeBuffer());
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const csvURL = window.URL.createObjectURL(blob);
  return csvURL;
}

export async function generateFromJsonWithSideHeading(schema) {
  const workbook = new ExcelJS.Workbook();

  Object.keys(schema).forEach((sheetName) => {
    workbook.addWorksheet(sheetName);
    const worksheet = workbook.getWorksheet(sheetName);
    worksheet.properties.defaultColWidth = 35;
    worksheet.getRow(1).height = 30;

    if (schema[sheetName].sideHeadings) {
      schema[sheetName].sideHeadings.forEach((sideHeading, i) => {
        const sideCell = worksheet.getCell(`A${i + 2}`);
        sideCell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
        sideCell.value = sideHeading;
        sideCell.protection = {
          locked: true,
        };
      });
    }

    Object.keys(schema[sheetName].topHeadings).forEach((head, i) => {
      const key = String.fromCharCode(65 + i);
      const cell = worksheet.getCell(`${key}1`);
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.value = head;
      cell.protection = {
        locked: true,
      };
    });
  });

  const buffer = Buffer.from(await workbook.xlsx.writeBuffer());
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  var csvURL = window.URL.createObjectURL(blob);
  return csvURL;
}

export async function generateJsonFromExcel(
  file,
  schema,
  isSideHeaded = false
) {
  try {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(await file.arrayBuffer());

    const data = {};

    workbook.worksheets.forEach((wk) => {
      data[wk.name] = [];
      const headers = wk.getRow(1).values ?? [];
      const headerLen = headers.length;

      if (headerLen > 0) {
        wk.eachRow((row, i) => {
          let check = 0;

          row.values.forEach((x) => {
            if (x === "") {
              check++;
            }
          });
          // if (check === 0) {
          if (i !== 1) {
            const lol = {};
            const values = [...row.values];
            const len = values.length;
            headers.map((v, j) => {
              lol[v] = j > len ? undefined : values[j];
            });
            data[wk.name].push(
              typeof lol === "CellHyperlinkValue" ? lol.text : lol
            );
          }
          // }
        });
      }
    });
    return isSideHeaded
      ? transformer2(data, schema)
      : transformer(data, schema);
  } catch (error) {
    console.error(error);
  }
  return { errors: [], data: {} };
}

const transformer = (data, schema) => {
  const errors = [];
  const finalObj = {};

  try {
    const mapping = getMapping(schema);

    Object.keys(data).forEach((workSheet) => {
      finalObj[workSheet] = [];
      Array.isArray(data[workSheet]) &&
        data[workSheet].forEach((row, i) => {
          let obj = {};
          Object.keys(row).forEach((key) => {
            if (key !== "undefined") {
              const { validation = {} } = schema[workSheet][key] || {
                validation: {},
              };
              if (validation) {
                const {
                  type,
                  values,
                  required,
                  pattern,
                  toLowerCase,
                  capitalize,
                } = validation;

                if (capitalize && toLowerCase) {
                  // throw new Error()
                  console.error(
                    "'capitalize' and 'toLowerCase' cannot be applied on the same field."
                  );
                  return;
                }
                if (required === true) {
                  if (type === "textLength" && isStringEmptyOrNull(row[key])) {
                    errors.push(
                      `Missing '${key}' on Line No ${
                        i + 2
                      } in sheet '${workSheet}'`
                    );
                  }

                  if (type === "list" && Array.isArray(values)) {
                    if (isStringEmptyOrNull(row[key])) {
                      errors.push(
                        `Missing '${key}' on Line No ${
                          i + 2
                        } in sheet '${workSheet}'`
                      );
                    } else {
                      if (!values.includes(row[key])) {
                        errors.push(
                          `Please Select Valid Value from Dropdown '${key}' on Line No ${
                            i + 2
                          } in sheet '${workSheet}'`
                        );
                      }
                    }
                  }

                  if (type === "date" && !isValidDate(row[key])) {
                    errors.push(
                      `Please Enter Valid '${key}' on Line No ${
                        i + 2
                      } in sheet '${workSheet}'`
                    );
                  }

                  if (type === "multiple") {
                    if (Array.isArray(row[key]) && row[key].length === 0) {
                      errors.push(
                        `Please Enter Valid '${key}' on Line No ${
                          i + 2
                        } in sheet '${workSheet}'`
                      );
                    }
                  }
                }
                if (type === "lettersOnly") {
                  if (isStringEmptyOrNull(row[key]) && required) {
                    errors.push(
                      `Missing '${key}' on Line No ${
                        i + 2
                      } in sheet '${workSheet}'`
                    );
                  } else if (!isStringEmptyOrNull(row[key])) {
                    let lettersPattern = /^[A-Za-z]+$/;
                    let text = row[key] != null ? String(row[key]).trim() : ""; // Ensure row[key] is a string
                    if (row[key]?.text) {
                      text = String(row[key].text).trim(); // Ensure row[key].text is a string
                    }

                    // Check if the value is a number or does not match the letters pattern
                    if (!isNaN(text) || !lettersPattern.test(text)) {
                      errors.push(
                        `Please Enter Valid '${key}' on Line No ${
                          i + 2
                        } in sheet '${workSheet}'`
                      );
                    }
                  }
                }
                if (type === "lettersOnlyWithSpace") {
                  if (isStringEmptyOrNull(row[key]) && required) {
                    errors.push(
                      `Missing '${key}' on Line No ${
                        i + 2
                      } in sheet '${workSheet}'`
                    );
                  } else if (!isStringEmptyOrNull(row[key])) {
                    let lettersPattern = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;
                    let text = row[key] != null ? String(row[key]).trim() : ""; // Ensure row[key] is a string
                    if (row[key]?.text) {
                      text = String(row[key].text).trim(); // Ensure row[key].text is a string
                    }

                    // Check if the value is a number or does not match the letters pattern
                    if (!isNaN(text) || !lettersPattern.test(text)) {
                      errors.push(
                        `Please Enter Valid '${key}' on Line No ${
                          i + 2
                        } in sheet '${workSheet}'`
                      );
                    }
                  }
                }
                if (type === "email") {
                  if (isStringEmptyOrNull(row[key]) && required) {
                    errors.push(
                      `Missing '${key}' on Line No ${
                        i + 2
                      } in sheet '${workSheet}'`
                    );
                  } else {
                    if (!isStringEmptyOrNull(row[key])) {
                      let emailToCheck = row[key];
                      if (row[key].text) {
                        emailToCheck = row[key].text;
                      }
                      let emailPattern = emailRegex;
                      let result = emailPattern.test(emailToCheck);

                      if (!result) {
                        errors.push(
                          `Please Enter Valid '${key}' on Line No ${
                            i + 2
                          } in sheet '${workSheet}'`
                        );
                      }
                    }
                  }
                }

                if (type === "date" && isValidDate(row[key])) {
                  row[key] = new Date(row[key]);
                }

                if (type === "regex") {
                  let result = pattern.test(row[key]);
                  if (!result) {
                    errors.push(
                      `Please Enter Valid '${key}' on Line No ${
                        i + 2
                      } in sheet '${workSheet}'`
                    );
                  }
                }

                if (type === "phone") {
                  if (isStringEmptyOrNull(row[key]) && required) {
                    errors.push(
                      `Missing '${key}' on Line No ${
                        i + 2
                      } in sheet '${workSheet}'`
                    );
                  } else if (!isStringEmptyOrNull(row[key])) {
                    let phonePattern = /^[0-9]+$/;
                    let result = phonePattern.test(row[key]);
                    if (result) {
                      if (
                        row[key].toString().length < 10 ||
                        row[key].toString().length > 10
                      ) {
                        errors.push(
                          `'${key}' should be 10 digit on Line No ${
                            i + 2
                          } in sheet '${workSheet}'`
                        );
                      }
                    } else {
                      errors.push(
                        `Please Enter Valid '${key}' on Line No ${
                          i + 2
                        } in sheet '${workSheet}'`
                      );
                    }
                  }
                }
                if (type === "dateOfBirth") {
                  let istRegex = dateOfBirthRegex;
                  if (!istRegex.test(row[key])) {
                    if (isStringEmptyOrNull(row[key]) && required) {
                      errors.push(
                        `Missing '${key}' on Line No ${
                          i + 2
                        } in sheet '${workSheet}'`
                      );
                      return;
                    }

                    // Replace slashes with dashes
                    row[key] = row[key].replace(/\//g, "-");

                    let datePattern =
                      /^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[0-2])-(19|20)\d{2}$/;
                    if (!datePattern.test(row[key])) {
                      errors.push(
                        `Please Enter Valid '${key}' on Line No ${
                          i + 2
                        } in sheet '${workSheet}'. ${key} should be in 'DD-MM-YYYY' format`
                      );
                      return;
                    }

                    // Validate date object and ensure it's not in the future
                    let [day, month, year] = row[key].split("-").map(Number);
                    let dateObject = new Date(year, month - 1, day);
                    let currentDate = new Date();

                    if (
                      dateObject.getDate() !== day ||
                      dateObject.getMonth() !== month - 1 ||
                      dateObject.getFullYear() !== year
                    ) {
                      errors.push(
                        `Invalid '${key}' on Line No ${
                          i + 2
                        } in sheet '${workSheet}'. Please enter a real date`
                      );
                      return;
                    }

                    if (dateObject > currentDate) {
                      errors.push(
                        `'${key}' on Line No ${
                          i + 2
                        } in sheet '${workSheet}' cannot be in the future`
                      );
                      return;
                    }

                    // If valid, store the date object
                    row[key] = dateObject;
                  }
                }
                if (type === "multiple") {
                  row[key] = isStringEmptyOrNull(row[key])
                    ? []
                    : row[key].split(",");
                }
                if (capitalize) {
                  if (type === "lettersWithSpaceOnly") {
                    if (!isStringEmptyOrNull(row[key])) {
                      row[key] = stringOps.capitalizeWords(row[key]);
                    }
                  } else {
                    if (!isStringEmptyOrNull(row[key])) {
                      row[key] = stringOps.capitalize(row[key]);
                    }
                  }
                }

                if (toLowerCase && row[key]) {
                  let temp = "";
                  if (row[key].text) {
                    temp = row[key].text.toLowerCase();
                  } else {
                    temp = row[key].toLowerCase();
                  }
                  row[key] = temp;
                }
              }

              const newKey =
                mapping[workSheet] && mapping[workSheet][key]
                  ? mapping[workSheet][key]
                  : key;

              obj[newKey] = row[key];
            }
          });

          finalObj[workSheet].push(obj);
        });
    });
    return { errors, data: finalObj };
  } catch (error) {
    console.error(error);
  }
  return { errors, data: finalObj };
};
const transformer2 = (data, schema) => {
  const finalObj = {};

  try {
    const mapping = getMapping(schema);
    Object.keys(data).forEach((workSheet) => {
      finalObj[workSheet] = [];
      Array.isArray(data[workSheet]) &&
        data[workSheet].forEach((row, i) => {
          let obj = {};
          Object.keys(row).forEach((key) => {
            if (key !== "undefined") {
              const newKey =
                mapping[workSheet] && mapping[workSheet][key]
                  ? mapping[workSheet][key]
                  : key;

              obj[newKey] = row[key];
            }
          });

          finalObj[workSheet].push(obj);
        });
    });
    return { data: finalObj };
  } catch (error) {
    console.error(error);
  }
  return { data: finalObj };
};

const getMapping = (schema) => {
  const mapping = {};
  try {
    Object.keys(schema).forEach((worksheet) => {
      mapping[worksheet] = {};
      Object.keys(schema[worksheet]).forEach((field) => {
        mapping[worksheet][field] =
          schema[worksheet][field]?.variableName ?? field;
      });
    });
  } catch (error) {
    console.error(error);
  }
  return mapping;
};
export function createExcelThroughJsonForMultipleSheets(data, fileName) {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Add a new worksheet to the workbook)
  let ws = null;
  for (let i = 0; i < data.length; i++) {
    ws = XLSX.utils.json_to_sheet(data[i][Object.keys(data[i])[0]]);
    XLSX.utils.book_append_sheet(wb, ws, Object.keys(data[i])[0]);
  }

  // Add the worksheet to the workbook

  // Generate the Excel file and download it
  if (!fileName) {
    XLSX.utils.sheet_add_aoa(ws, [["Created Date of Excel :- " + new Date()]], {
      origin: -1,
    });
    XLSX.writeFile(wb, "table_Data.xlsx");
  } else {
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  // // Extract Data (create a workbook object from the table)
  // var workbook = XLSX.utils.table_to_book(table_elt);
  // // Process Data (add a new row)
  // var ws = workbook.Sheets["Sheet1"];
  // XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

  // // Package and Release Data (`writeFile` tries to write and save an XLSB file)
  // XLSX.writeFile(workbook, "table_Data.xlsx");
}

export const createExcelThroughJsonAndSchema = (
  data,
  schema,
  fileName,
  excelHeadings = null
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  let startRow = excelHeadings ? 2 : 1;

  if (excelHeadings) {
    Object.entries(excelHeadings).forEach(([key, value]) => {
      worksheet.mergeCells(startRow, 1, startRow, 4);
      const cell = worksheet.getRow(startRow).getCell(2);
      cell.value = `${key} - ${value}`;
      cell.font = { bold: true, size: 14 };
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      startRow++;
    });
  }

  const headers = Object.keys(schema.Sheet1);
  const headerRow = worksheet.getRow(startRow);
  headerRow.values = headers;

  headerRow.eachCell((cell, colIndex) => {
    cell.font = { bold: true, size: 12 };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    const columnKey = headers[colIndex - 1];
    const columnSchema = schema.Sheet1[columnKey];

    if (columnSchema?.allignment) {
      cell.alignment = {
        horizontal: columnSchema.allignment.toLowerCase(),
        vertical: "middle",
      };
    }
  });

  startRow++;
  data.forEach((row) => {
    const dataRow = worksheet.getRow(startRow);
    dataRow.values = Object.values(row);

    dataRow.eachCell((cell, colIndex) => {
      const columnKey = headers[colIndex - 1];
      const columnSchema = schema.Sheet1[columnKey];

      if (columnSchema?.allignment) {
        cell.alignment = {
          horizontal: columnSchema.allignment.toLowerCase(),
          vertical: "middle",
        };
      }
    });

    startRow++;
  });

  Object.entries(schema.Sheet1).forEach(([columnName, columnSchema], i) => {
    if (columnSchema.validation?.type === "list") {
      const col = worksheet.getColumn(i + 1);
      col.eachCell((cell, rowNumber) => {
        if (rowNumber > Object.keys(excelHeadings || {}).length + 1) {
          cell.dataValidation = {
            type: "list",
            formulae: [`"${columnSchema.validation.values.join(",")}"`],
          };
        }
      });
    }
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
};
