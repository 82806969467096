<template>
  <div>
    <div
      v-if="allowSampleDownload"
      style="display: flex; justify-content: flex-end"
    >
      <span
        style="
          color: rgba(255, 79, 31, 1);
          text-decoration: underline;
          font-size: 14px;
          cursor: pointer;
        "
        @click="$emit('sample-download')"
        >{{ sampleDownloadButtonText }}</span
      >
    </div>
    <v-file-input
      :disabled="disabled"
      v-model="file"
      :placeholder="label"
      :accept="accept"
      :multiple="multiple"
      solo
      flat
      prepend-icon=""
      append-icon="mdi-paperclip"
      background-color="#E1E2F8"
      @change="handleFileChange"
      :hint="hint"
      persistent-hint
    ></v-file-input>
  </div>
</template>

<script>
export default {
  name: "FileUpload",
  props: {
    label: {
      type: String,
      default: "Choose a file",
    },
    sampleDownloadButtonText: {
      type: String,
      default: "Download Sample",
    },
    accept: {
      type: String,
      default: ".xlsx, .xls, .csv",
    },
    hint: {
      type: String,
      default:
        "Note: Use microsoft excel file only, other software files are not supported yet",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowSampleDownload: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    handleFileChange() {
      this.$emit("input", this.file);
      this.$emit("change", this.file); // Emit the "change" event explicitly
    },
    resetFile() {
      this.file = null; // Reset the file in the FileUpload component
    },
  },
};
</script>

<style scoped>
</style>
