import networkManager from '../NetworkManager/index'
import nbaNetworkManager from '../NetworkManager/nba'
import ApiNames from '../ApiNames/NBA'

class NBARepository {
  constructor (context) {
    this.context = context
  }

  async getNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.NBA || {}
  }

  async getDepartmentNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getDepartmentNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.NBA || {}
  }

  async updateNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log(response)
  }

  async updateDepartmentNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateDepartmentNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log(response)
  }

  //
  async getCos(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.getRequest(ApiNames.getCos, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getCOsForSubjects(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.postRequest(ApiNames.getCOsForSubjects, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getPos(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.getRequest(ApiNames.getPos, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getPsos(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.getRequest(ApiNames.getPsos, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getPoPsoByLmsInstituteId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.postRequest(ApiNames.getPoPsoByLmsInstituteId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  
  async deleteExamLms (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.postRequest(ApiNames.deleteExamLms, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getLevelsOfInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.getRequest(ApiNames.getLevelsOfInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getTargetsOfInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.getRequest(ApiNames.getTargetsOfInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getBatchesOfInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.postRequest(ApiNames.getBatchesOfInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.data
  }
}

export default NBARepository
